import { BrainSearchSidebarList_PaginationFragment$key } from "@/brain-search/internal/__generated__/BrainSearchSidebarList_PaginationFragment.graphql"
import { BrainSearchSidebarListPaginationQuery } from "@/brain-search/internal/__generated__/BrainSearchSidebarListPaginationQuery.graphql"
import { BrainSearchSidebarListQuery } from "@/brain-search/internal/__generated__/BrainSearchSidebarListQuery.graphql"
import { BrainSearchParams } from "@/brain-search/internal/BrainSearchLandingPage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoSideBarItem, DiscoSideBarItemSkeleton, DiscoText } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { useTheme } from "@material-ui/core"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import {
  useLazyLoadQuery,
  usePaginationFragment,
  useSubscribeToInvalidationState,
} from "react-relay"
import { generatePath } from "react-router-dom"
import { graphql } from "relay-runtime"

const BRAIN_SEARCHES_PER_PAGE = 40

function BrainSearchSidebarList() {
  const activeOrganization = useActiveOrganization()!
  const organizationMembershipId = activeOrganization.viewerMembership?.id
  const classes = useStyles()
  const { searchId } = useQueryParams<BrainSearchParams>()
  const theme = useTheme()

  const { membership } = useLazyLoadQuery<BrainSearchSidebarListQuery>(
    graphql`
      query BrainSearchSidebarListQuery($id: ID!, $first: Int, $after: String) {
        membership: node(id: $id) {
          ... on OrganizationMembership {
            ...BrainSearchSidebarList_PaginationFragment
              @arguments(first: $first, after: $after)
          }
        }
      }
    `,
    {
      id: organizationMembershipId || "",
      first: BRAIN_SEARCHES_PER_PAGE,
    },
    { fetchPolicy: "store-and-network" }
  )

  const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment<
    BrainSearchSidebarListPaginationQuery,
    BrainSearchSidebarList_PaginationFragment$key
  >(
    graphql`
      fragment BrainSearchSidebarList_PaginationFragment on OrganizationMembership
      @refetchable(queryName: "BrainSearchSidebarListPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id
        brainSearches(first: $first, after: $after)
          @connection(key: "BrainSearchSidebarList__brainSearches") {
          __id
          edges {
            node {
              id
              title
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    membership
  )

  useSubscribeToInvalidationState(
    data?.brainSearches?.__id ? [data?.brainSearches.__id] : [],
    refetchBrainSearches
  )

  const searches = Relay.connectionToArray(data?.brainSearches)
  if (!searches.length)
    return (
      <DiscoText
        variant={"body-sm"}
        color={theme.palette.type === "dark" ? "groovy.onDark.400" : "groovy.neutral.300"}
        marginLeft={1.5}
      >
        {"No conversations yet."}
      </DiscoText>
    )

  return (
    <>
      {searches.map((search) => (
        <DiscoSideBarItem
          key={search.id}
          name={search.title}
          indentNestedItems
          to={generatePath(ROUTE_NAMES.BRAIN.SEARCH, {
            searchId: search.id,
          })}
          selected={isSelected(search.id)}
        />
      ))}

      {hasNext && (
        <DiscoScrolledIntoView
          className={classes.item}
          onScrolledIntoView={() => loadNext(BRAIN_SEARCHES_PER_PAGE)}
          isLoading={isLoadingNext}
          skeleton={<DiscoSideBarItemSkeleton />}
        />
      )}
    </>
  )

  function refetchBrainSearches() {
    if (!organizationMembershipId) return
    refetch({
      first: BRAIN_SEARCHES_PER_PAGE,
    })
  }

  function isSelected(id: GlobalID) {
    // Check if on that path
    const path = generatePath(ROUTE_NAMES.BRAIN.SEARCH, { searchId: id })
    if (location.pathname === path) return true

    // Check if the searchId is in the URL params
    if (searchId === id) return true

    return false
  }
}

const useStyles = makeUseStyles({
  item: {
    width: "100%",
  },
})

function BrainSearchSidebarListSkeleton() {
  return <DiscoSideBarItemSkeleton />
}

export default Relay.withSkeleton({
  component: observer(BrainSearchSidebarList),
  skeleton: BrainSearchSidebarListSkeleton,
})
