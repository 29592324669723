import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import mergeClasses from "@assets/style/util/mergeClasses"
import { DiscoIcon, DiscoText, DiscoTextField } from "@disco-ui"
import {
  FilterSelectOptionsConfig,
  useFilterSelectOptions,
} from "@disco-ui/select/selectUtils"
import { PopperProps } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  AutocompleteProps,
} from "@material-ui/lab"
import useDebounce from "@utils/hook/useDebounce"
import React, { useCallback, useEffect, useMemo, useState } from "react"

export type DiscoMultiSelectOption = {
  value: string
  title: string
  searchable?: (string | null | undefined)[]
  disabled?: boolean | undefined
}

export type DiscoMultiSelectProps = {
  testid?: string
  placeholder: string
  values: string[]
  options: DiscoMultiSelectOption[]
  onChange: (values: string[], change: { value: string; selecting: boolean }) => void
  classes?: Partial<ClassNameMap<"tag" | "listbox" | "input" | "inputRoot">>
  disabled?: boolean
  renderOption?: (option: DiscoMultiSelectOption) => React.ReactNode
  renderTags?: (
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) => React.ReactNode
  freeSolo?: boolean
  filterOptions?: FilterSelectOptionsConfig
  disableCloseOnSelect?: boolean
  filterSelectedOptions?: boolean
  groupBy?: (option: DiscoMultiSelectOption) => string
  /** Additional keys that perform the "Enter" action */
  additionalSubmitKeys?: string[]
  autoFocusInput?: boolean
  isOpen?: boolean
  pagination?: {
    isLoading: boolean
    hasNext: boolean
    loadMore: Relay.UseRefetchablePaginationCallback
    refetch: Relay.UseRefetchablePaginationCallback
  }
  handleOpen?: () => void
  handleClose?: () => void
  limit?: number
  customInputButton?: React.ReactNode
  customPopper?: React.ComponentType<PopperProps>
  disableClearable?: boolean
}

type OnChangeCallback = AutocompleteProps<
  string | DiscoMultiSelectOption,
  true,
  boolean,
  false
>["onChange"]

function DiscoMultiSelect({
  placeholder,
  values,
  options,
  onChange,
  classes: propClasses,
  testid,
  renderOption,
  renderTags,
  freeSolo,
  pagination,
  filterOptions,
  additionalSubmitKeys = [],
  autoFocusInput,
  filterSelectedOptions = true,
  disableCloseOnSelect = false,
  isOpen,
  handleOpen,
  handleClose,
  limit,
  customInputButton,
  customPopper,
  disableClearable = true,
  ...rest
}: DiscoMultiSelectProps) {
  const classes = useStyles()
  const { optionGroup: optionGroupClass, ...autocompleteClasses } = classes
  const [newOptions, setNewOptions] = useState<DiscoMultiSelectOption[]>([])
  // Maintain a selected state so when / if the options change, the selected options are not lost
  const [selectedOptions, setSelectedOptions] = useState<DiscoMultiSelectOption[]>(
    values
      .map((v) => options.find((o) => o.value === v))
      .filter((v): v is DiscoMultiSelectOption => !!v)
  )
  const [search, setSearch] = useState("")

  const handleChange: OnChangeCallback = (_, __, reason, details) => {
    const { option } = details || {}
    if (!option && reason !== "clear") return

    let optionObj: DiscoMultiSelectOption | undefined

    if (typeof option === "string") {
      optionObj = { title: option, value: option }
    } else if (typeof option === "object") {
      optionObj = option
    }

    // Do nothing if disabled
    if (optionObj && optionObj.disabled) return
    handleReason(reason, optionObj)
  }

  const addToSelectedOptions = useCallback(
    (newlySelectedOption: DiscoMultiSelectOption) => {
      setSelectedOptions((prevOptions) => {
        return [
          ...(limit && prevOptions.length >= limit
            ? prevOptions.slice(1) // Replace first value if we have reached the limit for selected values
            : prevOptions),
          newlySelectedOption,
        ]
      })
    },
    [limit]
  )

  const handleAddToOnChange = useCallback(
    (option: DiscoMultiSelectOption) => {
      const newValues = [
        ...(limit && values.length >= limit
          ? values.slice(1) // Replace first value if we have reached the limit for selected values
          : values),

        option.value,
      ]
      onChange(newValues, { value: option.value, selecting: true })
    },
    [limit, onChange, values]
  )

  const allOptions = useMemo(() => [...options, ...newOptions], [options, newOptions])
  const debounchedRefetch = useDebounce(
    pagination?.refetch ? pagination.refetch : () => false,
    500,
    { resetOnUpdate: true }
  )

  // Sync values and options when one of them changes
  useEffect(() => {
    // If we are results are paginated, the selected option might not be in the list of
    // options anymore so skip this check
    if (pagination?.refetch) return

    const missingValue = values.find((v) => !allOptions.some((o) => o.value === v))
    // If a selected value doesn't exist in the possible options, unselect it
    // Trigger a onChange with the unselected value so we can update the parent
    if (missingValue) {
      onChange(
        values.filter((v) => v !== missingValue),
        {
          value: missingValue,
          selecting: false,
        }
      )
    }

    // If the selected options don't match the selected values, update the selected options
    // This happens when the parent updates the values so we will need to select/remove the new/old value
    const newOption = allOptions.find(
      (o) => values.includes(o.value) && !selectedOptions.some((s) => s.value === o.value)
    )
    if (newOption) {
      addToSelectedOptions(newOption)
    }
    const removedOption = selectedOptions.find((o) => !values.includes(o.value))
    if (removedOption) {
      setSelectedOptions((opts) => opts.filter((o) => o.value !== removedOption.value))
    }
  }, [
    values,
    allOptions,
    onChange,
    selectedOptions,
    addToSelectedOptions,
    pagination?.refetch,
  ])

  const filterSelectOptions = useFilterSelectOptions(
    // Disable the maxVisible and client-side filtering when infinite scrolling is enabled
    pagination
      ? { ...filterOptions, maxVisible: null, disableFiltering: true }
      : filterOptions
  )

  return (
    <Autocomplete
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      multiple
      freeSolo={freeSolo}
      classes={mergeClasses(autocompleteClasses, propClasses)}
      getOptionLabel={(option: DiscoMultiSelectOption) => option.title}
      renderOption={renderOption}
      renderTags={renderTags}
      options={allOptions}
      value={selectedOptions}
      inputValue={search}
      getOptionSelected={(option) => values.includes(option.value)}
      filterOptions={filterSelectOptions}
      filterSelectedOptions={filterSelectedOptions}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      PopperComponent={customPopper}
      renderInput={(params) =>
        customInputButton ? (
          <div ref={params.InputProps.ref}>
            <div {...params.inputProps} style={{ display: "flex", alignItems: "center" }}>
              {customInputButton}
            </div>
          </div>
        ) : (
          <DiscoTextField
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault()
              } else if (additionalSubmitKeys?.includes(e.key)) {
                e.preventDefault()
                const { value } = params.inputProps as { value: string }
                handleChange(e, [value], "create-option", { option: value })
              }
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocusInput}
            onChange={handleSearchChange}
            placeholder={selectedOptions.length > 0 ? undefined : placeholder}
            {...params}
          />
        )
      }
      onFocus={(e) => {
        e.stopPropagation() // Prevent parent from being focused when autocomplete input is clicked
      }}
      onKeyDown={(e) => {
        e.stopPropagation() // Prevent onKeyDown effects on parent elements parent from being triggered
      }}
      ListboxProps={{
        onScroll: handleInfiniteScroll,
      }}
      onChange={handleChange}
      data-testid={testid}
      renderGroup={(params) => (
        <div key={params.key} className={optionGroupClass}>
          <DiscoText
            variant={"body-xs-500-uppercase"}
            color={"text.secondary"}
            component={"div"}
            marginBottom={1.5}
          >
            {params.group}
          </DiscoText>
          {params.children}
        </div>
      )}
      popupIcon={<DiscoIcon testid={`${testid}.popup-icon`} icon={"arrow-down"} />}
      {...rest}
    />
  )

  function handleReason(
    reasons: AutocompleteChangeReason,
    option?: DiscoMultiSelectOption
  ) {
    switch (reasons) {
      case "select-option":
        if (!option) return
        addToSelectedOptions(option)
        syncSearch("")
        // Reset the search when selecting an option
        // If selecting an option that is not in the original options,
        // this is an suggestion option, add it to the new option
        if (!options.some((o) => o.value === option.value)) {
          setNewOptions((prevOptions) => [...prevOptions, option])
        }
        handleAddToOnChange(option)
        break
      case "create-option":
        if (!option) return
        addToSelectedOptions(option)
        syncSearch("")
        setNewOptions((prevOptions) => [
          ...prevOptions,
          { title: option.value, value: option.value, searchable: [option.value] },
        ])
        handleAddToOnChange(option)
        break
      case "remove-option":
        if (!option) return
        setSelectedOptions((prevOptions) => {
          return prevOptions.filter((o) => o.value !== option.value)
        })
        syncSearch("")
        setNewOptions(
          newOptions.filter((o) => {
            return o.value !== option.value
          })
        )
        onChange(
          values.filter((v) => v !== option.value),
          { value: option.value, selecting: false }
        )
        break
      case "clear":
        setSelectedOptions([])
        setNewOptions([])
        onChange([], { value: "", selecting: false })
        break
    }
  }

  /** Set the search input and refetch the options with the new search term */
  function syncSearch(s: string) {
    // Don't refetch if the search value hasn't changed
    if (s === search) return
    setSearch(s)

    if (pagination?.refetch) {
      debounchedRefetch({ search: s })
    }
  }

  function handleSearchChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    syncSearch(event.target.value)
  }

  function handleInfiniteScroll(event: React.SyntheticEvent) {
    // Ignore if there is no pagination metadata
    if (!pagination) return

    const listbox = event.currentTarget
    // When the user scrolls near the bottom of the listbox, load more options if there are more to load
    // Default to 200px from the bottom
    if (
      listbox.scrollTop + listbox.clientHeight + 200 >= listbox.scrollHeight &&
      pagination.hasNext &&
      !pagination.isLoading
    ) {
      pagination.loadMore({ search })
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    paddingTop: 0,
    borderRadius: theme.measure.borderRadius.big,
  },
  inputRoot: {
    ...theme.typography["body-sm"],
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[500]
        : theme.palette.groovy.neutral[100],
    // Override mui defaults with very high specificity
    "&[class*='MuiFilledInput-root']": {
      paddingTop: theme.spacing(0.5),
      paddingRight: 0,
      paddingLeft: theme.spacing(1.5),
      display: "flex",
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: "auto",
      gap: theme.spacing(0.75, 1),
    },
    "&.Mui-focused": {
      boxShadow:
        theme.palette.type === "dark"
          ? `0 0 0 1.5px ${theme.palette.primary.main}`
          : `0 0 0 1.5px ${theme.palette.primary.main}, 0 0 0 4.5px ${theme.palette.primary.light}`,
    },
    "&:hover:not(.Mui-focused)": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[400]
          : theme.palette.groovy.neutral[200],
      boxShadow: `0 0 0 1.5px ${theme.palette.groovy.grey[400]}`,
    },
    "& input[type=text], & input[type=search]": {
      minWidth: "100px",
      height: "38px",
      marginTop: -theme.spacing(1),
      ...theme.typography["body-sm"],
    },
  },
  input: {
    cursor: "pointer",
    padding: "2px 0 0 !important",
  },
  listbox: {
    padding: theme.spacing(2),
    maxHeight: "40vh",
    overflow: "auto",
    margin: 0,
  },
  paper: {
    marginTop: theme.spacing(2),
    borderRadius: theme.measure.borderRadius.big,
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  option: {
    padding: 0,
    borderRadius: theme.measure.borderRadius.medium,

    "&:hover": {
      cursor: "pointer",
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
    },
    '&[data-focus="true"]': {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[100],
    },
    "&:not(:first-child)": {
      marginTop: theme.spacing(0.5),
    },
  },
  endAdornment: {
    "& svg": {
      color: theme.palette.groovy.neutral[400],
    },
  },
  optionGroup: {
    marginBottom: theme.spacing(1.5),
  },
}))

export default DiscoMultiSelect
