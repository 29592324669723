import { GlobalID } from "@/relay/RelayTypes"
import { isE2ETest } from "@utils/e2e"
import { LocationDescriptorObject } from "history"
import { useCallback } from "react"
import { useLocation } from "react-router-dom"
import { LoginMethod } from "../../../authentication/login/util/loginTypes"
import ROUTE_NAMES, {
  ADMIN_ROUTE_PREFIX,
  BRAIN_ROUTE_PREFIX,
  PRODUCT_ROUTE_PREFIX,
} from "./routeNames"

export type LocationState =
  | {
      /** A location the user should be brought back to after logging in. */
      from?: LocationDescriptorObject
      /** Used instead of "from" if you need to set the subdomain to navigate to. */
      redirectUrl?: string
      /** Onboarding plan ID */
      plan?: string
      /** Registration discount code */
      discountCode?: string
      /** OrganizationPlanSelectionModal */
      organzationPlanSelectionModal?: "open"
      /** Onboarding Checklist create_course */
      coursesListCreateCourseModalState?: "open"
      /** Onboarding Checklist invite_admins */
      adminInvitesPageInviteAdminsModalState?: "open"
      /** Onboarding Checklist invite_members */
      adminInvitesPageInviteMembersModalState?: "open"
      /** Onboarding Checklist create_group */
      adminGroupsListPageCreateGroupModalState?: "open"
      /** Onboarding Checklist write_welcome_post */
      feedPostsWriteWelcomePostModalState?: "open"
      /** Onboarding Checklist create_automation */
      automationsReportPageCreateAutomationModalState?: "open"
      /** if set, open the feed app setup modal */
      communityHomePageFeedAppSetupModalState?: "open"
      /** a custom url to send the user to, upon checkout completion */
      checkoutBacklinkUrl?: string
      /** if set, open the SharePostModal for given postId */
      openSharePostModal?: GlobalID
      /** if set, open the ShareInstanceModal for given content */
      openShareInstanceModal?: GlobalID
      /** if set, open the ShareContentUsageModal for the given contentUsageId */
      shareContentUsageId?: GlobalID
      /** Experience registration if joining membership plan first */
      joinMembershipPlanFirst?: boolean
      /** Force ignore the requirement of a product being on a user's active plan in checkout */
      ignorePriceOnPlan?: boolean
    }
  | undefined

function generateRedirectStateFromLocation(
  location: LocationDescriptorObject
): LocationState {
  return {
    from: location,
    ...Object.assign({}, location.state || {}),
  }
}

function isBaseDomain(domain: string) {
  return domain.startsWith(BASE_DOMAIN_URL)
}

function isDiscoDomain(domain: string) {
  return /(.+\.disco\.co)|(^disco\.co)|(.+\.dancefloor\.dev)|(^dancefloor\.dev)|(.+\.devdisco\.co)|(^devdisco\.co)$/.test(
    domain
  )
}

function redirectToDomain(domain: string, pathName?: string, search?: string): boolean {
  let newHref = domain
  if (pathName) {
    newHref = `${newHref}${pathName}`
  }
  if (search) {
    newHref = `${newHref}${search}`
  }

  window.location.href = newHref
  return true
}

function switchToOrganizationDomain(
  nextOrganization: { slug: string; primaryDomain: string },
  currentOrganization?: { slug: string; primaryDomain: string } | null,
  pathName: string | undefined = ROUTE_NAMES.COMMUNITY.HOME.ROOT,
  search?: string
): boolean {
  // If we aren't switching anything, just return now
  if (currentOrganization && nextOrganization.slug === currentOrganization.slug) {
    return false
  }

  // Switch to the full domain as given by the backend
  return redirectToDomain(nextOrganization.primaryDomain, pathName, search)
}

function isPreviewPath(pathname: string) {
  return pathname.endsWith("/preview")
}

function isProductDetailRootPath(pathname?: string) {
  if (!pathname) return false

  const splitPaths = pathname.split("/")
  const maxIndexes = 4
  const minIndexes = 3

  if (
    splitPaths[1] !== "p" ||
    splitPaths.length > maxIndexes ||
    splitPaths.length < minIndexes
  ) {
    return false
  }

  return true
}

function getLoginMethodSearch(loginMethod: LoginMethod): string {
  return `?loginMethod=${loginMethod}`
}

function isBrainSearchPath(pathname: string) {
  return pathname.startsWith(BRAIN_ROUTE_PREFIX)
}

function isAdminConsolePath(pathname: string) {
  return pathname.startsWith(ADMIN_ROUTE_PREFIX)
}

function isAdminProductPath(pathname: string) {
  return /^\/p\/.*\/admin/.test(pathname)
}

function useStartViewAsMember(): () => void {
  return useCallback(() => {
    const url = new URL(window.location.href)

    // If currently on an admin route, open to community home instead
    if (isAdminConsolePath(url.pathname)) {
      url.pathname = ROUTE_NAMES.COMMUNITY.HOME.ROOT
    }

    url.searchParams.set("viewAs", "member")
    if (isE2ETest()) {
      window.location.href = url.toString()
    } else {
      window.open(url.toString(), "_blank")
    }
  }, [])
}

function useStopViewAsMember(): () => void {
  return useCallback(() => {
    // When we are in a window opened by entering view as mode, close the
    // window instead of refreshing the immediate page. In e2e tests or if we
    // don't have a window to take you back to, refresh the current window
    // without viewAs params set.
    const wasOpenedViewAsWindow = window.opener?.origin === window.origin
    if (wasOpenedViewAsWindow && !isE2ETest()) {
      window.close()
      return
    }

    // Reload the current page without viewAs params set.
    const url = new URL(window.location.href)
    url.searchParams.delete("viewAs")
    window.location.href = url.toString()
  }, [])
}

/* eslint-disable no-useless-escape */
const SLUG_PATTERN = `[a-z0-9-]+`
const DOMAIN_PATTERN = `(\.dev)?\.(demo|)disco\.co?`
function isOrganizationOrProductLandingPageUrl(
  url: string,
  opts?: Partial<{ orgSlug: string; productSlug: string }>
) {
  const orgSlug = opts?.orgSlug || SLUG_PATTERN
  const productSlug = opts?.productSlug || SLUG_PATTERN
  return new RegExp(`.*${orgSlug}${DOMAIN_PATTERN}(/p/${productSlug})?(/)?$`, "i").test(
    url
  )
}
function isOrganizationLandingPageUrl(url: string, opts?: Partial<{ orgSlug: string }>) {
  const orgSlug = opts?.orgSlug || SLUG_PATTERN
  const regex = new RegExp(`.*${orgSlug}${DOMAIN_PATTERN}(/)?$`, "i")
  return regex.test(url)
}

function isOrganizationLevelRoute(url: string, opts?: Partial<{ orgSlug: string }>) {
  const orgSlug = opts?.orgSlug || SLUG_PATTERN
  return new RegExp(`.*${orgSlug}${DOMAIN_PATTERN}(?!/p/)`, "i").test(url)
}

function isProductLevelRoute(
  url: string,
  opts?: Partial<{ orgSlug: string; productSlug: string }>
) {
  const orgSlug = opts?.orgSlug || SLUG_PATTERN
  const productSlug = opts?.productSlug || SLUG_PATTERN
  return new RegExp(`.*${orgSlug}${DOMAIN_PATTERN}/p/${productSlug}`, "i").test(url)
}

function useProductSlug(): string | null {
  const { pathname } = useLocation()
  const parts = pathname.split("/")
  const splitIdxStr = parts.findIndex((p) => p === "p")
  const splitIdx = splitIdxStr ? Number(splitIdxStr) : 1
  if (parts[splitIdx] === "p" && parts[splitIdx + 1]) {
    return parts[splitIdx + 1]
  }
  return null
}

function extractOccurrenceId(url: string) {
  const combinedPattern =
    /drawerOccurrenceId=([A-Za-z0-9+\/]+=*)|\/o\/([A-Za-z0-9+\/]+=*)$/
  const match = url.match(combinedPattern) // match[1] is for the drawerOccurrenceId pattern, match[2] is for the newer occurrence share link pattern
  if (match) {
    return match[1] || match[2]
  }
  return null
}

function extractContentUsageId(url: string) {
  const pattern = /u=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/
  const match = url.match(pattern)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

function extractProductSlug(url: string) {
  const pattern = /\/p\/([a-z0-9-]+)(\/|$)/
  const match = url.match(pattern)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

function isProductUrl(url: string) {
  const pattern = /\/p\/[a-z0-9-]+(\/dashboard)?\/?$/
  const match = url.match(pattern)
  return match !== null
}
function extractChatChannelId(url: string) {
  const pattern = /(?:channel|chat)\/([^\/]+)$/
  const match = url.match(pattern)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

function getProductIdBySlug(
  slug: string | void,
  products: {
    readonly id: string
    readonly name: string
    readonly slug: string
  }[]
) {
  const product = products.find((prod) => prod.slug === slug)
  return product ? product.id : null
}

function isProductsTabRoute(pathname: string): boolean {
  return (
    pathname.startsWith(`${PRODUCT_ROUTE_PREFIX}/`) ||
    pathname === ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT ||
    pathname.startsWith(`${ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT}/`)
  )
}

function isEventsTabRoute(pathname: string): boolean {
  return (
    pathname === ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT ||
    pathname.startsWith(`${ROUTE_NAMES.COMMUNITY.EVENTS_CALENDAR.ROOT}/`)
  )
}

function isChatTabRoute(pathname: string): boolean {
  return (
    pathname === ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT ||
    pathname.startsWith(`${ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL_ROOT}/`) ||
    pathname === ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT ||
    pathname.startsWith(`${ROUTE_NAMES.COMMUNITY.THREADS.DETAIL_ROOT}/`) ||
    pathname.startsWith(`${ROUTE_NAMES.CHAT.ROOT}/`)
  )
}

function extractFeedId(url: string): string | null {
  const pattern = /feed\/([a-zA-Z0-9=]+)\/posts/
  const match = url.match(pattern)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

export {
  extractChatChannelId,
  extractContentUsageId,
  extractFeedId,
  extractOccurrenceId,
  extractProductSlug,
  generateRedirectStateFromLocation,
  getLoginMethodSearch,
  getProductIdBySlug,
  isAdminConsolePath,
  isAdminProductPath,
  isBaseDomain,
  isBrainSearchPath,
  isChatTabRoute,
  isDiscoDomain,
  isEventsTabRoute,
  isOrganizationLandingPageUrl,
  isOrganizationLevelRoute,
  isOrganizationOrProductLandingPageUrl,
  isPreviewPath,
  isProductDetailRootPath,
  isProductLevelRoute,
  isProductsTabRoute,
  isProductUrl,
  redirectToDomain,
  switchToOrganizationDomain,
  useProductSlug,
  useStartViewAsMember,
  useStopViewAsMember,
}
