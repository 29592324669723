import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import { CreateInvitationFormQueryParams } from "@/invitation/create/form/CreateInvitationForm"
import { ProductRole } from "@/invitation/create/form/__generated__/CreateInvitationFormMutation.graphql"
import CreateInvitationModal from "@/invitation/CreateInvitationModal"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import useDisclosure from "@utils/hook/useDisclosure"

interface Props {
  children: OverridableDiscoButtonChildren
  /** If productId is provided, CreateInvitationButton shows a product version of the invitation modal */
  productId?: GlobalID
  productType?: Extract<ProductType, "course" | "pathway">
  testid?: string
  onlyAdminRoles?: boolean
  onlyMemberRoles?: boolean
  defaultProductRole?: ProductRole
  givenEmails?: string[]
  inviteMethod?: CreateInvitationFormQueryParams["InviteTab"]
}

function CreateInvitationButton(props: Props) {
  const {
    children,
    productId = null,
    testid = "CreateInvitationButton",
    onlyAdminRoles,
    onlyMemberRoles,
    defaultProductRole,
    givenEmails,
    inviteMethod,
  } = props
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [_, setQueryParams] = useQueryParamState<CreateInvitationFormQueryParams>()

  return (
    <>
      <OverridableDiscoButton testid={testid} onClick={openModal}>
        {children}
      </OverridableDiscoButton>
      <CreateInvitationModal
        isOpen={isOpen}
        onClose={closeModal}
        onSubmit={handleSubmit}
        productId={productId}
        onlyAdminRoles={onlyAdminRoles}
        onlyMemberRoles={onlyMemberRoles}
        defaultProductRole={defaultProductRole}
        givenEmails={givenEmails}
        inviteMethod={inviteMethod}
      />
    </>
  )

  function handleSubmit() {
    closeModal()
  }

  function openModal() {
    onOpen()
    if (!givenEmails) return
    setQueryParams({ InviteTab: "email" }, "replace")
  }

  function closeModal() {
    onClose()
    setQueryParams({ InviteTab: undefined }, "replace")
  }
}

export default CreateInvitationButton
