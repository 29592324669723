import Badge from "@/admin/experiences/badges/Badge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import { SearchHitType } from "@/search/search-hits/SearchHit"
import SearchHitTemplate, {
  SearchHitTemplateSkeleton,
} from "@/search/search-hits/SearchHitTemplate"
import { ChatMessageSearchHitQuery } from "@/search/search-hits/kinds/__generated__/ChatMessageSearchHitQuery.graphql"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { sanitizeMessage } from "@components/chat/util/chatUtils"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useCopyToClipboard } from "@utils/dom/domUtils"
import { TestIDProps } from "@utils/typeUtils"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type ChatMessageHit = {
  id: string
  message: string
  html: string
  disco_user_id: string
  external_channel_id: string
  parent_id?: string
}

interface ChatMessageSearchHitProps extends TestIDProps {
  hit: SearchHitType
}

function ChatMessageSearchHit({ hit }: ChatMessageSearchHitProps) {
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const copyToClipboard = useCopyToClipboard()
  const history = useHistory()
  const streamMessage = { ...hit.chatMessage, id: Relay.fromGlobalId(hit.id).id }

  const { user, organization } = useLazyLoadQuery<ChatMessageSearchHitQuery>(
    graphql`
      query ChatMessageSearchHitQuery(
        $userId: ID!
        $organizationId: ID!
        $externalChannelId: String!
      ) {
        user: node(id: $userId) {
          ... on User {
            id
            fullName
            ...ProfileAvatarWithDetailsFragment
            ...ProfileAvatarFragment
          }
        }
        organization: node(id: $organizationId) {
          ... on Organization {
            chatChannels(
              first: 1
              externalChannelIds: [$externalChannelId]
              includeProductLevel: true
            ) {
              edges {
                node {
                  id
                  kind
                  product {
                    slug
                  }
                  app {
                    customAppTitle
                    badge {
                      ...BadgeFragment
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      userId: Relay.toGlobalId("User", streamMessage?.disco_user_id || ""),
      organizationId: activeOrganization?.id || "",
      externalChannelId: streamMessage?.external_channel_id || "",
    }
  )

  const channel = Relay.connectionToArray(organization?.chatChannels)[0]
  if (!channel || !user || !organization || !streamMessage) return null

  return (
    <SearchHitTemplate
      hit={hit}
      name={sanitizeMessage(streamMessage.html!)}
      icon={renderIcon()}
      details={renderLabel()}
      onClick={handleClick}
      onCopy={handleCopyLink}
    />
  )

  function renderIcon() {
    return (
      <div className={classes.icon}>
        {channel.app ? (
          <Badge badgeKey={channel.app.badge} size={40} className={classes.badge} />
        ) : (
          <DiscoIcon icon={"chat-alt"} width={40} height={40} />
        )}
        <div className={classes.subIconContainer}>
          <ProfileAvatar userKey={user} />
        </div>
      </div>
    )
  }

  function renderLabel() {
    return (
      <DiscoText
        variant={"body-sm"}
        color={"groovy.neutral.400"}
        truncateText={1}
        className={classes.subtitle}
        display={"inline"}
      >
        {user?.fullName}
        {channel.app?.customAppTitle && (
          <>
            {" in "}
            <DiscoText
              component={"span"}
              variant={"body-sm"}
              color={"primary.main"}
              truncateText={1}
              className={classes.channel}
            >
              {`#${channel.app?.customAppTitle}`}
            </DiscoText>
          </>
        )}
      </DiscoText>
    )
  }

  function getLink() {
    if (!streamMessage) return
    const isThread = Boolean(streamMessage.parent_id)
    const isDM = channel.kind === "direct_message"
    const isProduct = Boolean(channel.product?.slug)
    const path = isDM
      ? ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL
      : isProduct
      ? ROUTE_NAMES.PRODUCT.CHAT.CHANNEL
      : ROUTE_NAMES.COMMUNITY.CHAT.CHANNEL

    return {
      pathname: generatePath(path, {
        channelId: channel.id,
        ...(isProduct && { productSlug: channel.product?.slug }),
      }),
      search: isThread
        ? `streamMsgId=${streamMessage.parent_id}&openThread=true`
        : `streamMsgId=${streamMessage.id}`,
    }
  }

  function handleClick() {
    const link = getLink()
    if (!link) return
    history.push(link)
  }

  function handleCopyLink(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    const link = getLink()
    if (!link) return

    const chatMessageLink = `${window.location.origin}${link.pathname}?${link.search}`
    copyToClipboard(chatMessageLink)
  }
}

const useStyles = makeUseStyles((theme) => ({
  subtitle: {
    width: "100%",
  },
  channel: {
    display: "contents",
  },
  icon: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  badge: {
    "& svg": {
      width: 32,
      height: 32,
    },
  },
  subIconContainer: {
    position: "absolute",
    bottom: -3,
    right: -3,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
  },
}))

export const ChatMessageSearchHitSkeleton: React.FC<ChatMessageSearchHitProps> = () => {
  return <SearchHitTemplateSkeleton />
}

export default Relay.withSkeleton<ChatMessageSearchHitProps>({
  component: ChatMessageSearchHit,
  skeleton: ChatMessageSearchHitSkeleton,
})
