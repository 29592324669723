import { ContentModuleUtils } from "@/content-usage/ContentModuleUtils"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import { AddContentUsageContentListItemFragment$key } from "@/content-usage/buttons/add-button/__generated__/AddContentUsageContentListItemFragment.graphql"
import { AddContentUsageContentListItem_createInstanceMutation } from "@/content-usage/buttons/add-button/__generated__/AddContentUsageContentListItem_createInstanceMutation.graphql"
import {
  AddContentUsageContentListItem_duplicateContentMutation,
  DuplicateContentInput,
} from "@/content-usage/buttons/add-button/__generated__/AddContentUsageContentListItem_duplicateContentMutation.graphql"
import {
  ContentUsageEntity,
  ContentUsageInput,
  UpdateContentUsageInput,
} from "@/content-usage/drawer/__generated__/InlineContentDrawerTitleFormMutation.graphql"
import { useContentUsageDrawer } from "@/content-usage/drawer/useContentUsageDrawer"
import ContentThumbnailWithDetails, {
  ContentThumbnailWithDetailsSkeleton,
} from "@/content/detail/ContentThumbnailWithDetails"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import AIChip from "@components/ai/AIChip"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { ConnectionHandler, graphql } from "relay-runtime"

interface Props extends TestIDProps {
  contentKey: AddContentUsageContentListItemFragment$key
  addingTo: ContentUsageEntity
  onSelect?: VoidFunction
  productId?: GlobalID | null
  contentUsageInput?: ContentUsageInput | null
  onContentSelected?: (contentId: GlobalID) => void
  disabled?: boolean
}

function AddContentUsageContentListItem({
  contentKey,
  productId,
  contentUsageInput,
  onSelect,
  addingTo,
  onContentSelected,
  disabled,
  ...props
}: Props) {
  const theme = useTheme()
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const contentUsageDrawer = useContentUsageDrawer()
  const showOnHoverClasses = useShowOnHoverStyles()
  const { filterContentLabelId } = useQueryParams<{ filterContentLabelId?: string }>()

  const content = useFragment<AddContentUsageContentListItemFragment$key>(
    graphql`
      fragment AddContentUsageContentListItemFragment on Content {
        id
        type
        name
        isAiGenerated
        ...ContentThumbnailWithDetails_ContentFragment
      }
    `,
    contentKey
  )

  const duplicateContentForm = useFormStore<
    AddContentUsageContentListItem_duplicateContentMutation,
    DuplicateContentInput
  >(
    graphql`
      mutation AddContentUsageContentListItem_duplicateContentMutation(
        $input: DuplicateContentInput!
      ) {
        response: duplicateContent(input: $input) {
          node {
            id
            organization {
              adminLibraryContents {
                edges {
                  node {
                    id
                    ...AddContentUsageContentListItemFragment
                  }
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentId: content.id,
    }
  )

  // For creating an instance
  const createInstanceForm = useFormStore<
    AddContentUsageContentListItem_createInstanceMutation,
    UpdateContentUsageInput
  >(
    graphql`
      mutation AddContentUsageContentListItem_createInstanceMutation(
        $input: UpdateContentUsageInput!
        $connections: [ID!]!
        $isCurriculumModule: Boolean!
        $contentLabelIds: [ID!]
      ) {
        response: updateContentUsage(input: $input) {
          node
            @prependNode(
              connections: $connections
              edgeTypeName: "ContentUsageNodeEdge"
            ) {
            id
            entityId
            content {
              name
            }
            module @include(if: $isCurriculumModule) {
              id
              type
              usages {
                edges {
                  node {
                    id
                    ...ContentModuleUtils_RefreshContentModulesFragment
                      @arguments(contentLabelIds: $contentLabelIds)
                  }
                }
              }
            }
            ...InlineContentDrawerTemplateFragment
            ...ContentUsageGridItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId,
      contentId: content.id,
      contentUsageInput,
      contentType: content.type,
    }
  )

  const isCurriculumModule = addingTo === "curriculum"

  const testid = `${props.testid}.${content.name}`

  return (
    <DiscoContainerButton
      testid={testid}
      className={classNames(classes.container, showOnHoverClasses.hoverable)}
      onClick={() => handleCreateInstance()}
    >
      <ContentThumbnailWithDetails
        testid={testid}
        contentKey={content}
        truncateText={1}
        textAdornment={
          content.isAiGenerated && (
            <AIChip testid={`${testid}.AI-generation`} name={"AI-Generation"} />
          )
        }
      />

      {/* Buttons */}
      <div className={classNames(classes.buttonsContainer, showOnHoverClasses.showable)}>
        <DiscoTooltip
          content={
            "Duplicating generates a new piece of content. Newly duplicated content operates independently from its original and any other versions."
          }
        >
          <DiscoIconButton
            size={"small"}
            className={classes.button}
            variant={"outlined"}
            testid={`${testid}.duplicate-button`}
            hoverBackgroundColor={
              theme.palette.type === "dark"
                ? theme.palette.groovy.onDark[400]
                : theme.palette.groovy.neutral[200]
            }
            onClick={async (e) => {
              // Stop propagating to container button onClick
              e.stopPropagation()
              await handleDuplicate()
            }}
            height={40}
            width={40}
            color={theme.palette.text.primary}
            backgroundColor={
              theme.palette.type === "dark" ? theme.palette.groovy.onDark[600] : "white"
            }
            disabled={buttonsAreDisabled()}
          >
            <DiscoIcon icon={"copy"} />
          </DiscoIconButton>
        </DiscoTooltip>
        <DiscoButton
          testid={`${testid}.create-instance-button`}
          onClick={async (e) => {
            // Stop propagating to container button onClick
            e.stopPropagation()
            await handleCreateInstance()
          }}
          disabled={buttonsAreDisabled()}
          shouldDisplaySpinner={createInstanceForm.isSubmitting}
        >
          {"Use"}
        </DiscoButton>
      </div>
    </DiscoContainerButton>
  )

  function buttonsAreDisabled() {
    return (
      createInstanceForm.isSubmitting || duplicateContentForm.isSubmitting || disabled
    )
  }

  async function handleCreateInstance(contentId?: GlobalID) {
    if (onContentSelected) {
      await onContentSelected(contentId || content.id)
      return
    }

    const connections = [
      ConnectionHandler.getConnectionID(
        createInstanceForm.state.contentUsageInput?.entityId || "",
        "ContentUsageGrid__children"
      ),
    ]

    const { didSave, response } = await createInstanceForm.submit(
      {
        ...createInstanceForm.state,
        contentId: contentId || createInstanceForm.state.contentId,
        contentUsageInput: {
          ...createInstanceForm.state.contentUsageInput,
          ...ContentUsageUtils.getDefaultSettings(content.type),
        },
      },
      {
        connections,
        variables: {
          isCurriculumModule,
          contentLabelIds: filterContentLabelId ? [filterContentLabelId] : undefined,
        },
        updater: (store) => {
          // invalidate all collection folder connections if adding content
          ContentModuleUtils.invalidateCollectionFolderConnections(
            store,
            createInstanceForm.state.contentUsageInput?.entity,
            createInstanceForm.state.contentUsageInput?.entityId
          )
        },
      }
    )

    if (!didSave || !response?.node) return

    displaySuccessToast({
      testid: `${testid}.create-isntance.success-toast`,
      message: "Instance created!",
    })

    if (onSelect) onSelect()

    contentUsageDrawer.open({
      drawerContentUsageId: response.node.id,
    })
  }

  /** Duplicate the content and then create instance of it */
  async function handleDuplicate() {
    const { didSave, response } = await duplicateContentForm.submit(
      duplicateContentForm.state
    )
    if (!didSave || !response?.node) return

    await handleCreateInstance(response.node.id)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    alignItems: "center",
    gap: theme.spacing(1.5),
    padding: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.big,
    "&:hover": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[500]
          : theme.palette.groovy.neutral[200],
      cursor: "pointer",
    },
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "195px",
    gap: theme.spacing(1),

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  button: {
    border:
      theme.palette.type === "dark"
        ? `1.5px solid ${theme.palette.groovy.onDark[200]}`
        : undefined,
  },
}))

export function AddContentUsageContentListItemSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <ContentThumbnailWithDetailsSkeleton />
    </div>
  )
}

export default AddContentUsageContentListItem
