import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore from "@/core/form/store/FormStore"
import { useStartImpersonatingUser } from "@/product/util/hook/useInitImpersonateUser"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import TestUserFormFields, {
  TestUserFormFieldsSkeleton,
} from "@components/test-user/TestUserFormFields"
import { CreateTestUserFormQuery } from "@components/test-user/__generated__/CreateTestUserFormQuery.graphql"
import {
  CreateTestUserInput,
  CreateTestUserModalMutation,
} from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

interface Props {
  form: FormStore<CreateTestUserInput, CreateTestUserModalMutation>
  onClose: VoidFunction
}

function CreateTestUserForm({ form, onClose }: Props) {
  const activeOrganization = useActiveOrganization()!
  const { setUnsavedChanges } = useUnsavedChangesModalContext()
  const startImpersonatingUser = useStartImpersonatingUser()

  const { node } = useLazyLoadQuery<CreateTestUserFormQuery>(
    graphql`
      query CreateTestUserFormQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            __typename
            id
            ...TestUserFormFieldsFragment
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only" }
  )

  const organization = Relay.narrowNodeType(node, "Organization")
  if (!organization) return null

  return (
    <Form id={"CreateTestUserForm"} testid={"CreateTestUserForm"} onSubmit={handleSubmit}>
      <TestUserFormFields organizationKey={organization} form={form} />
    </Form>
  )

  async function handleSubmit() {
    const { didSave, response } = await form.submit(form.state, {
      updater: (store) => {
        const orgMembershipRecord = store.get(activeOrganization.viewerMembership!.id)
        if (!orgMembershipRecord) return

        ConnectionHandler.getConnections(
          orgMembershipRecord,
          "TestUserDropdownItem__mainUserLinks"
        ).forEach((connection) => connection.invalidateRecord())
      },
    })
    if (!didSave || !response?.node?.id) return

    setUnsavedChanges(false)

    // close modal
    onClose()

    displaySuccessToast({
      testid: "CreateTestUserForm.success-toast",
      message: "Test Member successfully created",
      body: `Test member "${response.node.testUser.fullName}" has been successfully created. You can start switching to this test member to experience the community.`,
      buttonConfig: {
        buttonLabel: "Switch to Test Member",
        onClick: () => startImpersonatingUser(response.node!.testUser.id),
      },
      timeout: 60 * 1000, // 60 seconds
    })
  }
}

export default Relay.withSkeleton({
  component: observer(CreateTestUserForm),
  skeleton: () => <TestUserFormFieldsSkeleton />,
})
