/**
 * @generated SignedSource<<d2c2b71b180f4ae12a030abff9ab3f41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "onboarding_module" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingSlackContentFragment$data = {
  readonly viewerHasCompleted: boolean;
  readonly content: {
    readonly systemTaskKind: ContentSystemTaskKind | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useMemberOnboardingFormStoreFragment" | "MemberOnboardingContentHeaderFragment" | "MemberOnboardingSlackManualConnectionStepsFragment" | "MemberOnboardingSlackAutoConnectSuccessFragment" | "useTrackContentCompletionFragment">;
  readonly " $fragmentType": "MemberOnboardingSlackContentFragment";
};
export type MemberOnboardingSlackContentFragment$key = {
  readonly " $data"?: MemberOnboardingSlackContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingSlackContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberOnboardingSlackContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMemberOnboardingFormStoreFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingContentHeaderFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingSlackManualConnectionStepsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberOnboardingSlackAutoConnectSuccessFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackContentCompletionFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};

(node as any).hash = "ce26637d4288fca376026ae0eae0b3e5";

export default node;
