import CheckoutCompletePage from "@/checkout/complete/CheckoutCompletePage"
import CheckoutPaymentPage from "@/checkout/payment/CheckoutPaymentPage"
import CheckoutProcessPage from "@/checkout/process/CheckoutProcessPage"
import CheckoutSummaryPage from "@/checkout/summary/CheckoutSummaryPage"
import { ActiveOrganizationContextValue } from "@/core/context/ActiveOrganizationContext"
import ActiveSlackUserImportProvider from "@/core/context/ActiveSlackUserImportContext"
import AuthRequiredRoute from "@/core/route/permissioned-route/AuthRequiredRoute"
import PermissionedRoute from "@/core/route/permissioned-route/PermissionedRoute"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import lazyWithRetry from "@utils/lazy/lazyWithRetry"
import { Route } from "react-router-dom"

const EmailChangeConfirmationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "change-email" */ "../../authentication/email-change-confirmation-page/EmailChangeConfirmationPage"
    )
)

const ChangeEmailPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "set-email" */ "../../authentication/set-email/SetEmailPage"
    )
)

const OrganizationLandingPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "organization-lp" */ "../../organization/landing-page/OrganizationLandingPage"
    )
)

const UpdateUserProfileStepPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "update-user-profile" */ "../../authentication/update-user-profile/UpdateUserProfileStepPage"
    )
)

const OnboardingAppSetupPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "onboarding-app-setup" */ "../../authentication/onboarding-communication-apps/OnboardingAppSelectionPage"
    )
)

const SetupCommunitySlackPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "setup-community-slack" */ "../../admin/integrations/slack/setup/SetupCommunitySlackPage"
    )
)

const SetupCommunitySlackSuccessPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "setup-community-slack-success" */ "../../admin/integrations/slack/setup/SetupCommunitySlackSuccessPage"
    )
)

const SetupAdminSlackPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "setup-admin-slack" */ "../../admin/integrations/slack/setup/SetupAdminSlackPage"
    )
)

const SetupAdminSlackSuccessPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "setup-admin-slack-success" */ "../../admin/integrations/slack/setup/SetupAdminSlackSuccessPage"
    )
)

const SlackUserImportPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "slack-user-import" */ "../../admin/integrations/slack/setup/SlackUserImportPage"
    )
)

const SlackErrorPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "slack-error" */ "../../admin/integrations/slack/setup/SlackErrorPage"
    )
)

const OnboardingDiscordPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "onboarding-discord" */ "../../authentication/onboarding-communication-apps/OnboardingDiscordPage"
    )
)

const OnboardingMicrosoftTeamsPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "onboarding-microsoft-teams" */ "../../authentication/onboarding-communication-apps/OnboardingMicrosoftTeamsPage"
    )
)

const OnboardingOtherIntegrationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "onboarding-other-integration" */ "../../authentication/onboarding-communication-apps/OnboardingOtherIntegrationPage"
    )
)

const StripeIntegrationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "stripe-integration" */ "../../payment/stripe-integration/StripeIntegrationPage"
    )
)

const ZoomIntegrationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "zoom-integration" */ "../../zoom/integration-page/ZoomIntegrationPage"
    )
)

const CalendarIntegrationPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "outlook-integration" */ "../../integrations/calendar/CalendarIntegrationPage"
    )
)

const UpdatePaymentMethodPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "update-payment-method" */ "../../user/payment/UpdatePaymentMethodPage"
    )
)

const AutoRSVPPage = lazyWithRetry(
  () => import(/* webpackChunkName: "auto-rsvp" */ "../../product/event/AutoRSVPPage")
)

const LinkSlackPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "link-slack" */ "../../slack/link-slack-page/LinkSlackPage"
    )
)

const OrganizationAdminLandingPageFlow = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "organization-admin-lp" */ "../../organization/landing-page/OrganizationAdminLandingPageFlow"
    )
)

const CommunityHomePage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "community-home" */ "@/organization/home/CommunityHomePage"
    )
)

const ReactivateTrialPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "reactivate-trial-page" */ "@/organization/billing/ReactivateTrialPage"
    )
)

export function getProtectedRouteComponents(
  activeOrganization: ActiveOrganizationContextValue | null
) {
  return [
    // Route for course registration flow / invited admin flow
    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.REGISTRATION_CREATE_PROFILE}`}
      exact
      unstyled
      path={ROUTE_NAMES.ONBOARDING.REGISTRATION_CREATE_PROFILE}
      metaTitle={"Create Your Profile"}
    >
      <UpdateUserProfileStepPage />
    </AuthRequiredRoute>,
    // Communication tools
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SETUP_APP}`}
      path={ROUTE_NAMES.ONBOARDING.SETUP_APP}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <OnboardingAppSetupPage />
    </PermissionedRoute>,
    // Reactivate Trial
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.REACTIVATE_TRIAL}`}
      path={ROUTE_NAMES.REACTIVATE_TRIAL}
      permission={"plans.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <ReactivateTrialPage />
    </PermissionedRoute>,
    // Slack
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SETUP_COMMUNITY_SLACK.ROOT}`}
      path={ROUTE_NAMES.ONBOARDING.SETUP_COMMUNITY_SLACK.ROOT}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupCommunitySlackPage isOnboarding />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_COMMUNITY_SLACK.ROOT}`}
      path={ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_COMMUNITY_SLACK.ROOT}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupCommunitySlackPage />
    </PermissionedRoute>,

    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SETUP_COMMUNITY_SLACK.SUCCESS}`}
      path={ROUTE_NAMES.ONBOARDING.SETUP_COMMUNITY_SLACK.SUCCESS}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupCommunitySlackSuccessPage isOnboarding />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_COMMUNITY_SLACK.SUCCESS}`}
      path={ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_COMMUNITY_SLACK.SUCCESS}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupCommunitySlackSuccessPage />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SETUP_ADMIN_SLACK.ROOT}`}
      path={ROUTE_NAMES.ONBOARDING.SETUP_ADMIN_SLACK.ROOT}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupAdminSlackPage isOnboarding />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_ADMIN_SLACK.ROOT}`}
      path={ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_ADMIN_SLACK.ROOT}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupAdminSlackPage />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SETUP_ADMIN_SLACK.SUCCESS}`}
      path={ROUTE_NAMES.ONBOARDING.SETUP_ADMIN_SLACK.SUCCESS}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupAdminSlackSuccessPage isOnboarding />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_ADMIN_SLACK.SUCCESS}`}
      path={ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_ADMIN_SLACK.SUCCESS}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SetupAdminSlackSuccessPage />
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SLACK_FINALIZE_CONNECTION}`}
      path={ROUTE_NAMES.ONBOARDING.SLACK_FINALIZE_CONNECTION}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <ActiveSlackUserImportProvider>
        <SlackUserImportPage isOnboarding />
      </ActiveSlackUserImportProvider>
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ADMIN.INTEGRATIONS.SLACK_FINALIZE_CONNECTION}`}
      path={ROUTE_NAMES.ADMIN.INTEGRATIONS.SLACK_FINALIZE_CONNECTION}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <ActiveSlackUserImportProvider>
        <SlackUserImportPage />
      </ActiveSlackUserImportProvider>
    </PermissionedRoute>,
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.SLACK_ERROR}`}
      path={ROUTE_NAMES.ONBOARDING.SLACK_ERROR}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <SlackErrorPage />
    </PermissionedRoute>,
    // Discord
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.COMING_SOON.DISCORD}`}
      path={ROUTE_NAMES.ONBOARDING.COMING_SOON.DISCORD}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <OnboardingDiscordPage />
    </PermissionedRoute>,
    // Teams
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.COMING_SOON.MICROSOFT_TEAMS}`}
      path={ROUTE_NAMES.ONBOARDING.COMING_SOON.MICROSOFT_TEAMS}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <OnboardingMicrosoftTeamsPage />
    </PermissionedRoute>,
    // Other
    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.ONBOARDING.COMING_SOON.OTHER}`}
      path={ROUTE_NAMES.ONBOARDING.COMING_SOON.OTHER}
      permission={"integrations.manage"}
      entityKey={activeOrganization}
      unstyled
      exact
    >
      <OnboardingOtherIntegrationPage />
    </PermissionedRoute>,

    // admin flows
    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.NEW_EMAIL_CONFIRMATION}`}
      path={ROUTE_NAMES.AUTHENTICATION.NEW_EMAIL_CONFIRMATION}
      unstyled
      exact
    >
      <EmailChangeConfirmationPage />
    </AuthRequiredRoute>,
    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.SET_EMAIL}`}
      path={ROUTE_NAMES.AUTHENTICATION.SET_EMAIL}
      unstyled
      exact
    >
      <ChangeEmailPage />
    </AuthRequiredRoute>,
    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.STRIPE_INTEGRATION}`}
      path={ROUTE_NAMES.STRIPE_INTEGRATION}
      unstyled
      exact
    >
      <StripeIntegrationPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.ZOOM_INTEGRATION}`}
      path={ROUTE_NAMES.ZOOM_INTEGRATION}
      unstyled
      exact
    >
      <ZoomIntegrationPage isLandingPage={false} />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.ZOOM_LANDING}`}
      unstyled
      path={ROUTE_NAMES.ZOOM_LANDING}
      exact
    >
      <ZoomIntegrationPage isLandingPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.OUTLOOK_INTEGRATION}`}
      path={ROUTE_NAMES.OUTLOOK_INTEGRATION}
      unstyled
      exact
    >
      <CalendarIntegrationPage platform={"outlook"} isLandingPage={false} />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.OUTLOOK_LANDING}`}
      unstyled
      path={ROUTE_NAMES.OUTLOOK_LANDING}
      exact
    >
      <CalendarIntegrationPage platform={"outlook"} isLandingPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.PAYMENT.UPDATE}`}
      unstyled
      path={ROUTE_NAMES.PAYMENT.UPDATE}
      exact
    >
      <UpdatePaymentMethodPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.AUTO_RSVP}`}
      path={ROUTE_NAMES.AUTO_RSVP}
      unstyled
      exact
    >
      <AutoRSVPPage />
    </AuthRequiredRoute>,

    <PermissionedRoute
      key={`route:=${ROUTE_NAMES.COMMUNITY.HOME.ROOT}`}
      path={ROUTE_NAMES.COMMUNITY.HOME.ROOT}
      exact
      permission={"posts.read"}
      entityKey={activeOrganization}
    >
      <CommunityHomePage />
    </PermissionedRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.AUTHENTICATION.LINK_SLACK}`}
      unstyled
      path={ROUTE_NAMES.AUTHENTICATION.LINK_SLACK}
      exact
    >
      <LinkSlackPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.CHECKOUT.SUMMARY}`}
      path={ROUTE_NAMES.CHECKOUT.SUMMARY}
      exact
    >
      <CheckoutSummaryPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.CHECKOUT.PAYMENT}`}
      path={ROUTE_NAMES.CHECKOUT.PAYMENT}
      exact
    >
      <CheckoutPaymentPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.CHECKOUT.PROCESS}`}
      path={ROUTE_NAMES.CHECKOUT.PROCESS}
      exact
    >
      <CheckoutProcessPage />
    </AuthRequiredRoute>,

    <AuthRequiredRoute
      key={`route:=${ROUTE_NAMES.CHECKOUT.COMPLETE}`}
      path={ROUTE_NAMES.CHECKOUT.COMPLETE}
      exact
    >
      <CheckoutCompletePage />
    </AuthRequiredRoute>,
  ]
}

export function getCommunityProtectedRouteComponents() {
  return [
    <Route
      key={`route:=${ROUTE_NAMES.COMMUNITY.LANDING_PAGE.ADMIN_ROOT}`}
      path={ROUTE_NAMES.COMMUNITY.LANDING_PAGE.ADMIN_ROOT}
    >
      <OrganizationAdminLandingPageFlow />
    </Route>,

    <Route
      key={`route:=${ROUTE_NAMES.COMMUNITY.LANDING_PAGE.SITE}`}
      path={ROUTE_NAMES.COMMUNITY.LANDING_PAGE.SITE}
      exact
    >
      <OrganizationLandingPage />
    </Route>,
  ]
}
