import Format from "@/core/format/format"
import { PricingFrequency } from "@/membership-plan/create/modal/__generated__/CreateMembershipPlanModalMutation.graphql"
import {
  PricingKind,
  pricingUtils_usePricingDisplayValue$key,
} from "@/pricing/__generated__/pricingUtils_usePricingDisplayValue.graphql"
import { UpdatePricingInput } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { addMonths, addWeeks, addYears } from "date-fns"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

export type Pricing = {
  id: string
  amountCents: number
  frequency: PricingFrequency | null
  kind: PricingKind
}

/** Available pricing frequency options */
export const PRICING_FREQUENCIES: PricingFrequency[] = ["weekly", "monthly", "annually"]

/** Labels for pricing frequencies, split up by noun ("Week") and adjective ("Weekly") */
export const PRICING_FREQUENCY_LABEL: Record<
  PricingFrequency,
  { adjective: string; noun: string; short: string }
> = {
  weekly: {
    adjective: "Weekly",
    noun: "Week",
    short: "Wk",
  },
  monthly: {
    adjective: "Monthly",
    noun: "Month",
    short: "Mo",
  },
  annually: {
    adjective: "Yearly",
    noun: "Year",
    short: "Yr",
  },
  "%future added value": {
    adjective: "",
    noun: "",
    short: "",
  },
}

/** Pricing Input */
export type PricingInput = UpdatePricingInput

/** Format the pricing frequency into a readable label */
export function getFrequencyLabel(
  frequency: PricingFrequency | null | undefined,
  speech: "adjective" | "noun" | "short",
  lowerCase?: boolean
) {
  if (!frequency) return ""
  if (lowerCase) return PRICING_FREQUENCY_LABEL[frequency][speech].toLowerCase()
  return PRICING_FREQUENCY_LABEL[frequency][speech]
}

/** Generate the default free payment */
export function defaultFreePayment(): PricingInput {
  return {
    amountCents: 0,
    kind: "free",
    frequency: null,
  }
}

/** Generate the default one time payment */
export function defaultOneTimePayment(amountCents: number): PricingInput {
  return {
    amountCents,
    kind: "one_time",
    frequency: null,
  }
}

/** Generate the default split payment */
export function defaultSplitPayment(amountCents: number): PricingInput {
  return {
    amountCents,
    kind: "recurring",
    frequency: "monthly",
  }
}

/** Generate readable pricing
 * @example "$100 / month"
 */
export function generateReadablePricing(
  pricing: PricingInput,
  currency = "usd",
  length: "full" | "short" = "full"
) {
  if (pricing.kind === "free") return length === "short" ? "Free" : "Free of Charge"
  const frequency = getFrequencyLabel(
    pricing.frequency,
    length === "full" ? "noun" : "short",
    true
  )
  const price = Format.asCurrency(pricing.amountCents / 100, { currency })
  if (!pricing.frequency) return price
  return `${price} / ${frequency}`
}

/** Format the pricing frequency into a readable label */
export function getPricingKindLabel(
  kind: PricingKind,
  frequency?: PricingFrequency | null,
  opts?: { short?: boolean }
) {
  switch (kind) {
    case "free":
      if (opts?.short) return "Free"
      return "Free of charge"
    case "one_time":
      if (opts?.short) return "One-time"
      return "One-time payment"
    case "recurring":
      if (opts?.short) return getFrequencyLabel(frequency || null, "adjective")
      return `Recurring ${getFrequencyLabel(
        frequency || null,
        "adjective",
        true
      )} payments`
    default:
      return ""
  }
}

export function usePricingDisplayValue(input: {
  pricingKey: pricingUtils_usePricingDisplayValue$key | null | undefined
  currency?: string
  length?: "full" | "short"
  basePrice?: number
}) {
  const { pricingKey, currency, length, basePrice } = input

  const pricing = useFragment<pricingUtils_usePricingDisplayValue$key>(
    graphql`
      fragment pricingUtils_usePricingDisplayValue on Pricing {
        frequency
        kind
        amountCents
      }
    `,
    pricingKey || null
  )

  if (!pricing) return ""
  if (!pricing.frequency && basePrice) return Format.asCurrency(basePrice, { currency })

  return generateReadablePricing(pricing, currency, length)
}

/** Get the next billing date for a pricing frequency */
export function calculateNextBillingDate(
  frequency: PricingFrequency | null,
  date: Date = new Date()
): Date | null {
  if (!frequency) return null
  switch (frequency) {
    case "weekly":
      return addWeeks(new Date(), 1)
    case "monthly":
      return addMonths(new Date(), 1)
    case "annually":
      return addYears(new Date(), 1)
    default:
      return date
  }
}
