import { useLabels } from "@/core/context/LabelsContext"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import ProductCard from "@/product/card/ProductCard"
import Relay from "@/relay/relayUtils"
import useTrackUserActivity from "@/reporting/useTrackUserActivity"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoEmptyState, DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import pluralize from "pluralize"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import DashboardBlockAdminDropdown from "../../DashboardBlockAdminDropdown"
import { ContinueYourProductsDashboardBlockContentPaginationFragment$key } from "./__generated__/ContinueYourProductsDashboardBlockContentPaginationFragment.graphql"
import { ContinueYourProductsDashboardBlockContentQuery } from "./__generated__/ContinueYourProductsDashboardBlockContentQuery.graphql"
import { ContinueYourProductsDashboardBlockFragment$data } from "./__generated__/ContinueYourProductsDashboardBlockFragment.graphql"
import { ContinueYourProductsDashboardBlockPaginationQuery } from "./__generated__/ContinueYourProductsDashboardBlockPaginationQuery.graphql"

interface Props {
  block: ContinueYourProductsDashboardBlockFragment$data
}

export default function ContinueYourProductsDashboardBlockContent({ block }: Props) {
  const labels = useLabels()
  const { sliceSize, ...carouselProps } = useDashboardBlockCarouselSize(3, block, "left")
  const classes = useStyles()
  const theme = useTheme()
  const trackUserActivity = useTrackUserActivity()

  const { continueYourProductsBlock } =
    useLazyLoadQuery<ContinueYourProductsDashboardBlockContentQuery>(
      graphql`
        query ContinueYourProductsDashboardBlockContentQuery($id: ID!, $first: Int) {
          continueYourProductsBlock: node(id: $id) {
            ... on ContinueYourProductsDashboardBlock {
              title
              ...ContinueYourProductsDashboardBlockContentPaginationFragment
                @arguments(first: $first)
            }
          }
        }
      `,
      {
        id: block.id,
        first: sliceSize,
      }
    )

  const { data, hasNext, hasPrevious, loadNext, isLoadingNext } = usePaginationFragment<
    ContinueYourProductsDashboardBlockPaginationQuery,
    ContinueYourProductsDashboardBlockContentPaginationFragment$key
  >(
    graphql`
      fragment ContinueYourProductsDashboardBlockContentPaginationFragment on ContinueYourProductsDashboardBlock
      @refetchable(queryName: "ContinueYourProductsDashboardBlockPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id
        products(first: $first, after: $after)
          @connection(key: "ContinueYourProductsDashboardBlockContent__products") {
          __id
          totalCount
          edges {
            node {
              id
              slug
              todos
              ...ProductCardFragment
              ...MembershipPlanDetailsCardFragment
                @arguments(hideDrafts: true, hideNonPublic: true)
            }
          }
        }
      }
    `,
    continueYourProductsBlock
  )

  const todoProducts = Relay.connectionToArray(data?.products)

  return (
    <DiscoCarousel
      {...carouselProps}
      isDashboardBlock
      hideIndicator
      testid={"ContinueYourProductsDashboardBlockContent"}
      title={continueYourProductsBlock?.title}
      moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
      refetch={{
        hasNext,
        hasPrevious,
        loadMore: () => loadNext(sliceSize),
        isLoading: isLoadingNext,
      }}
      totalCount={data?.products.totalCount || 0}
      data={todoProducts}
      emptyState={
        <DiscoEmptyState
          testid={"ContinueYourProductsDashboardBlockContent"}
          className={classes.emptyState}
          title={`No ${labels.experience.plural}`}
          subtitle={"You've completed all Todos."}
          variant={"compact"}
        />
      }
      item={(product) => {
        return (
          <ProductCard
            key={product.id}
            productKey={product}
            hideAdminActions
            className={classes.card}
            onClick={handleTrackUserActivity}
            linkLocation={"curriculum"}
            hideButton
            footer={
              product.todos > 0 ? (
                <div className={classes.footer}>
                  <DiscoIcon
                    icon={"iconsax.lamp-on"}
                    color={theme.palette.groovy.neutral[700]}
                  />
                  <DiscoText
                    testid={"ContinueYourProductsDashboardBlock.card-footer"}
                    variant={"body-md-600"}
                  >{`${product.todos} ${pluralize(
                    labels.assignment.singular,
                    product.todos
                  )} or ${pluralize("Quiz", product.todos)}`}</DiscoText>
                </div>
              ) : undefined
            }
          />
        )
      }}
    />
  )

  function handleTrackUserActivity() {
    trackUserActivity({
      kind: "clicked",
      entityId: block.id,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  card: {
    textAlign: "left",
    boxShadow: theme.palette.groovyDepths.insideCard,
    border: theme.palette.constants.borderSmall,
    "&:hover": {
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },

  footer: {
    backgroundColor: theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.xl,
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    width: "fit-content",
  },
  emptyState: {
    maxHeight: "226px",
    padding: theme.spacing(6.5, 4),
    marginBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
}))
