import { ZoomMeetingProviderDataShape } from "@/admin/integrations/zoom/connection/OrganizationZoomConnectionListItem"
import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import EventFeedbackModal from "@/occurrence/feedback/modal/EventFeedbackModal"
import { EventsCalendarPageParams } from "@/organization/events-calendar/CommunityEventsCalendarPage"
import CreatePublishedOrDraftEventButton from "@/organization/occurrence/create-form/CreatePublishedOrDraftEventButton"
import EventDestinationDropdown, {
  EventDestination,
} from "@/organization/occurrence/create-form/EventDestinationDropdown"
import EventFrequencyFormFields from "@/organization/occurrence/create-form/EventFrequencyFormFields"
import EventRecurringEventFormFields from "@/organization/occurrence/create-form/EventRecurringEventFormFields"
import {
  CreateEventFormMutation,
  CreateEventInput,
  GoogleMapAddressComponents,
  OccurrenceHostInput,
  OccurrenceStatus,
} from "@/organization/occurrence/create-form/__generated__/CreateEventFormMutation.graphql"
import { CreateEventFormQuery } from "@/organization/occurrence/create-form/__generated__/CreateEventFormQuery.graphql"
import { CreateEventForm_MembershipsQuery } from "@/organization/occurrence/create-form/__generated__/CreateEventForm_MembershipsQuery.graphql"
import { OccurrenceReminderFormState } from "@/organization/occurrence/edit-form/EditEventForm"
import EventAccessFormFields from "@/organization/occurrence/edit-form/EventAccessFormFields"
import EventDatetimeFormFields, {
  EventDatetimeFormFieldsSkeleton,
} from "@/organization/occurrence/edit-form/EventDatetimeFormFields"
import EventHostsFormFields from "@/organization/occurrence/edit-form/EventHostsFormFields"
import EventMeetingFormFields, {
  EventMeetingFormFieldsSkeleton,
} from "@/organization/occurrence/edit-form/EventMeetingFormFields"
import EventNameDestinationFormFields, {
  EventNameDestinationFormFieldsSkeleton,
} from "@/organization/occurrence/edit-form/EventNameDestinationFormFields"
import EventReminderFormSection from "@/organization/occurrence/edit-form/EventReminderFormSection"
import { EventRoomChoices } from "@/organization/occurrence/util/eventTypes"
import useUpdateEventFormZoomProviders from "@/organization/occurrence/util/useUpdateEventFormZoomProviders"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoAlert,
  DiscoButton,
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoLink,
  DiscoSwitch,
  DiscoText,
  useModalContext,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoImageFormField from "@disco-ui/file/DiscoImageFormField"
import DiscoNumberInput from "@disco-ui/input/DiscoNumberInput"
import { Collapse, Grid, useTheme } from "@material-ui/core"
import useEffectOnUpdate from "@utils/hook/useEffectOnUpdate"
import usePermissions from "@utils/hook/usePermissions"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { convertGolangDurationFromDays } from "@utils/time/timeUtils"
import { TestIDProps } from "@utils/typeUtils"
import { addHours } from "date-fns"
import { IObservableArray, observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useParams } from "react-router-dom"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"
import { RRule } from "rrule"
import { Options as RRuleOptions } from "rrule/dist/esm/types"

type AllAvailableFields = keyof CreateEventFormMutation | keyof CreateEventInput
type ExcludeFields = Extract<
  AllAvailableFields,
  | "name"
  | "status"
  | "datetimeRange"
  | "videoRoomType"
  | "addMemberGroupIds"
  | "cover"
  | "description"
  | "user"
  | "reminders"
>
type NarrowedMutation = Omit<CreateEventFormMutation, ExcludeFields>

type CreateEventFormState = Omit<CreateEventInput, ExcludeFields> & {
  readonly isCreation: boolean
  status: OccurrenceStatus
  startDatetime: string
  endDatetime: string
  duration: number
  videoRoomType: EventRoomChoices
  meetingTab: "zoom" | "link" | "in-person" | "tbd"
  rrule?: string | null
  rruleConfig: {
    rruleEnd: "until" | "count"
    rruleOptions: Partial<RRuleOptions> | null
    customRRule: boolean
    currentRuleType: "monthly" | "yearly" | "weekly" | "daily" | "custom"
    currentMonthlyRuleType: "date" | "day"
  }
  isRecurring: boolean
  customLink: string
  physicalAddress?: GoogleMapAddressComponents | null
  zoomMeetingTypeRadioSelection: EventRoomChoices
  meetingProvider?: ZoomMeetingProviderDataShape
  readonly initialMeetingProvider?: ZoomMeetingProviderDataShape
  initialMemberGroupIds: IObservableArray<string>
  selectedMemberGroupIds: IObservableArray<string>
  notifyMembersOnChange: boolean
  notificationEmailBody: string
  hosts: IObservableArray<OccurrenceHostInput>
  capacity: number | null
  hasCapacity: boolean
  publicAcknowledgment: boolean
  allowGuestAccess?: boolean | null
  reminders: OccurrenceReminderFormState[]
  collectFeedback?: boolean | null
  destination: EventDestination
}

export type CreateEventFormStore = FormStore<CreateEventFormState, NarrowedMutation>

interface Props extends TestIDProps {
  onClose?: () => void
  onCreate?: (occurrence: { id: GlobalID; datetimeRange: readonly string[] }) => void
  className?: string
}

function CreateEventForm(props: Props) {
  const { testid = "CreateEventForm", onClose, onCreate, className } = props
  const { appId } = useParams<EventsCalendarPageParams>()
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const canCreateCommunityEvents = usePermissions(activeOrganization).has("events.create")
  const canCreateEventInActiveProduct = usePermissions(activeProduct).has("events.create")

  const { admin_experience: productLabel, admin_member } = useLabels()
  const userTimezone = useUserTimezone()
  const now = new Date()
  const drawer = useGlobalDrawer("event")
  const classes = useStyles()
  const [preview, setPreview] = useState(false)
  const [showAdvanced, setShowAdvanced] = useState(false)
  const theme = useTheme()
  const { zoomProviders, formRef: formStoreRef } = useUpdateEventFormZoomProviders()
  const { authUser } = useAuthUser()

  const { connectedApps } = useConnectedProductApps(activeProduct)
  const activeProductHasEventsApp = connectedApps.has("events")
  const defaultToActiveProduct =
    activeProductHasEventsApp && canCreateEventInActiveProduct

  // If we can not create community events or events in the product then find a product that we can create events in
  const { membership } = useLazyLoadQuery<CreateEventForm_MembershipsQuery>(
    graphql`
      query CreateEventForm_MembershipsQuery($membershipId: ID!) {
        membership: node(id: $membershipId) {
          ... on OrganizationMembership {
            id
            adminMemberships: productMemberships(
              roles: [manager, instructor]
              productType: "course"
              appKind: events
            ) {
              edges {
                node {
                  id
                  productId
                }
              }
            }
          }
        }
      }
    `,
    {
      membershipId:
        !defaultToActiveProduct && !canCreateCommunityEvents
          ? activeOrganization.viewerMembership?.id || ""
          : "",
    },
    { fetchPolicy: "network-only" }
  )

  const defaultProductId = getDefaultProductId()
  const defaultToProductEvent = defaultToActiveProduct || !canCreateCommunityEvents

  const [{ product, organization, app }, refetch] =
    Relay.useRefetchableQuery<CreateEventFormQuery>(
      graphql`
        query CreateEventFormQuery($productId: ID!, $organizationId: ID!, $appId: ID!) {
          product: node(id: $productId) {
            id
            __typename
            ... on Product {
              name
              ...EventAccessFormFields_ProductFragment
              ...EventMeetingFormFields_ProductFragment
              createdByUserMembership {
                id
              }
              viewerMembership {
                id
              }
            }
          }
          organization: node(id: $organizationId) {
            id
            __typename
            ... on Organization {
              ...EventAccessFormFields_OrganizationFragment
              ...EventMeetingFormFields_OrganizationFragment
            }
          }
          app: node(id: $appId) {
            id
            __typename
            ... on ProductApp {
              visibility
              visibilityGroups {
                edges {
                  node {
                    id
                  }
                }
              }
              ...EventAccessFormFields_AppFragment
            }
          }
        }
      `,
      {
        productId: defaultProductId || "",
        organizationId: activeOrganization.id,
        appId: appId || "",
      }
    )

  const visibilityGroups = product?.id
    ? []
    : Relay.connectionToArray(app?.visibilityGroups)

  const form = useFormStore<CreateEventFormMutation, CreateEventFormState>(
    graphql`
      mutation CreateEventFormMutation($input: CreateEventInput!) {
        response: createEvent(input: $input) {
          node {
            id
            datetimeRange
            event {
              productId
              isRecurring
            }
            ...OccurrenceListItemFragment @relay(mask: false)
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      productId: defaultProductId || "",
      appId: appId || "",
      isCreation: true,
      startDatetime: addHours(now, 1).toISOString(),
      endDatetime: addHours(now, 2).toISOString(),
      duration: 60,
      timezone: userTimezone,
      visibility: app?.visibility && app.visibility !== "all" ? "member_group" : "cohort",
      status: "draft",
      rrule: null,
      rruleConfig: {
        rruleOptions: {
          freq: RRule.DAILY,
          byweekday: [],
          until: new Date(now.setMonth(now.getMonth() + 2)),
        },
        currentRuleType: "daily",
        currentMonthlyRuleType: "date",
        rruleEnd: "until",
        customRRule: false,
      },
      isRecurring: false,
      videoRoomType: "group" as EventRoomChoices,
      meetingTab: zoomProviders.length ? "zoom" : "link",
      customLink: "",
      physicalAddress: null,
      zoomMeetingTypeRadioSelection: "group",
      meetingProvider:
        zoomProviders.find((zoomProvider) => zoomProvider.user.id === authUser?.id) || // Default to first zoom host active has connected
        zoomProviders[0] ||
        null,
      initialMeetingProvider: undefined,
      initialMemberGroupIds: observable(visibilityGroups.map((g) => g.id) ?? []),
      selectedMemberGroupIds: observable(visibilityGroups.map((g) => g.id) ?? []),
      notifyMembersOnChange: false,
      notificationEmailBody: "",
      content: {
        name: "",
        richEditorDescriptionContent: null,
        coverPhoto: null,
      },
      capacity: null,
      hasCapacity: false,
      hosts: observable(getDefaultHosts()),
      publicAcknowledgment: false,
      allowGuestAccess: false,
      guestCapacity: null,
      reminders: [
        {
          timing: "15m",
          audience: "all",
          emailSubject: "{{ event.name }} is coming up",
          emailRichEditorBody: JSON.stringify(
            EditorUtils.createParagraphs([
              { text: "Hi {{ user.firstName }},", align: "center" },
              {
                text: "This is a reminder that the event {{ event.name }} is coming up ({{ event.dateAndTime }}).",
                align: "center",
              },
            ])
          ),
          sentAt: null,
        },
      ],
      collectFeedback: true,
      destination: defaultToProductEvent ? "product" : "community",
    }
  )

  useEffect(() => {
    formStoreRef.current = form
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // If the selected zoom account is disconnected, default to the first zoom account available
  // if there are no zoom accounts available, set the meeting provider to undefined
  useEffect(() => {
    if (!form.state.meetingProvider && zoomProviders.length) {
      form.state.meetingProvider = zoomProviders[0]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.meetingProvider])

  // If the destination changes, reset the form
  useEffectOnUpdate(() => {
    if (form.state.destination === "product") {
      form.state.productId = defaultProductId
      form.state.selectedMemberGroupIds = observable([])
      form.state.initialMemberGroupIds = observable([])
      form.state.appId = ""
    }

    if (form.state.destination === "community") {
      form.state.productId = ""
      form.state.appId = appId
    }

    refetch({
      productId: defaultProductId || "",
      organizationId: activeOrganization.id,
      appId: appId || "",
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.destination])

  // If the selected product changes, fetch the product and update the form state
  useEffectOnUpdate(() => {
    refetch({
      productId: form.state.productId ?? "",
      organizationId: activeOrganization.id,
      appId: "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.productId])

  // If the selected app changes, fetch the app and update the form state
  useEffectOnUpdate(() => {
    refetch({
      productId: form.state.productId ?? "",
      organizationId: activeOrganization.id,
      appId: form.state.appId ?? "",
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.appId])

  // Update form values for product change
  useEffect(() => {
    if (!product) return
    form.state.hosts = observable(getDefaultHosts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  // Update form values for app change
  useEffect(() => {
    if (!app) return
    const groupIds = Relay.connectionToArray(app?.visibilityGroups).map((g) => g.id) ?? []

    form.state.visibility =
      app?.visibility && app.visibility !== "all" ? "member_group" : "cohort"
    form.state.selectedMemberGroupIds = observable(groupIds)
    form.state.initialMemberGroupIds = observable(groupIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app])

  const modal = useModalContext()

  // Keep the modal title updated
  useEffect(() => {
    modal?.setTitle?.(
      <Grid container wrap={"nowrap"} zeroMinWidth>
        <DiscoText variant={"body-lg-600"} className={classes.text}>
          {"Create Event"}
        </DiscoText>

        <EventDestinationDropdown form={form} />
      </Grid>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  return (
    <div>
      <Form
        testid={testid}
        onSubmit={handleSubmit}
        buttons={
          <>
            <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
              {"Cancel"}
            </DiscoButton>
            <CreatePublishedOrDraftEventButton form={form} />
          </>
        }
        customClassName={className}
      >
        {/* Product Selection */}
        {!form.state.productId &&
          !canCreateCommunityEvents &&
          form.state.destination === "product" && (
            <DiscoAlert
              severity={"warning"}
              marginBottom={1.5}
              message={`You must select a ${productLabel.singular} to create an event`}
            />
          )}

        {/* Details */}
        <EventNameDestinationFormFields form={form} testid={testid} />

        {/* Description */}
        <DiscoFormControl
          errorMessages={form.errorsByField.richEditorDescriptionContent}
          title={"Description"}
          label={"Add a short description about this event."}
          variant={"one-column"}
        >
          <DiscoEditor
            defaultValue={form.state.content!.richEditorDescriptionContent}
            onChange={(v) => (form.state.content!.richEditorDescriptionContent = v)}
            testid={`${testid}.Editor.description`}
            placeholder={""}
            minHeight={100}
            className={classes.editor}
          />
        </DiscoFormControl>

        {/* Cover */}
        <DiscoFormControl
          title={"Cover Image"}
          label={
            "Add an image that's used on the event cards, registration and other places on the platform."
          }
          variant={"one-column"}
          errorMessages={form.errorsByField.cover}
        >
          <DiscoImageFormField
            testid={`${testid}.cover`}
            value={form.state.content!.coverPhoto}
            onChange={(result) => {
              form.state.content!.coverPhoto = result?.url || null
            }}
            cropperProps={{
              stencilProps: {
                initialAspectRatio: ASPECT_RATIOS.COVER_PHOTO,
                aspectRatio: ASPECT_RATIOS.COVER_PHOTO,
              },
            }}
            maxWidth={"fit-content"}
          />
        </DiscoFormControl>

        <DiscoFormControl errorMessages={form.errorsByField.datetimeRange}>
          <EventFrequencyFormFields testid={testid} form={form} />
          <div className={classes.dateTime}>
            <EventDatetimeFormFields form={form} />
            {form.state.isRecurring && (
              <DiscoFormControl>
                <DiscoDivider marginBottom={2.5} />
                <EventRecurringEventFormFields form={form} />
              </DiscoFormControl>
            )}
          </div>
        </DiscoFormControl>

        {/* Meeting */}
        <EventMeetingFormFields
          productKey={product}
          organizationKey={organization}
          form={form}
          formControlVariant={"one-column"}
          testid={testid}
          eventVariant={form.state.productId ? "product" : "community"}
          hideHosts
        />

        {/* Access */}
        <EventAccessFormFields
          form={form}
          productKey={product}
          organizationKey={organization}
          appKey={app}
        />
        <DiscoDivider marginBottom={2.5} />

        <DiscoContainerButton
          className={classes.headerContainer}
          testid={`${testid}.advancedSettings`}
          onClick={() => setShowAdvanced(!showAdvanced)}
        >
          <DiscoIcon
            icon={"iconsax.bold/arrow-down"}
            height={24}
            width={24}
            rotate={showAdvanced ? undefined : "-90"}
            color={theme.palette.text.primary}
          />
          <div className={classes.headerText}>
            <span className={classes.titleContainer}>
              <DiscoText variant={"body-md-600"}>{"Advanced Settings"}</DiscoText>
            </span>
            <DiscoText variant={"body-sm"} color={"text.secondary"}>
              {
                "Additional settings such as capacity, feedback forms and reminder emails are available for events."
              }
            </DiscoText>
          </div>
        </DiscoContainerButton>
        <Collapse in={showAdvanced} mountOnEnter>
          <div className={classes.collapsibleContainer}>
            <EventHostsFormFields
              productId={form.state.productId}
              form={form}
              testid={testid}
              formControlVariant={"one-column"}
            />
            <DiscoDivider marginTop={0} marginBottom={2.5} />

            {/* Capacity */}
            <DiscoFormControl
              title={"Limited Capacity"}
              variant={"one-column"}
              titleTooltip={{
                label: `Limit capacity to a set number of ${admin_member.plural}.`,
                placement: "right",
              }}
              titleAction={
                <DiscoSwitch
                  name={"capacity"}
                  hideForm
                  checked={form.state.hasCapacity}
                  onChange={handleSwitchCapacity}
                  testid={`${testid}.capacity-checkbox`}
                />
              }
            >
              {form.state.hasCapacity ? (
                <div className={classes.formControlChild}>
                  <DiscoFormControl label={"Members Capacity"}>
                    <DiscoNumberInput
                      value={form.state.capacity}
                      onChange={(e) => (form.state.capacity = Number(e.target.value))}
                      fullWidth
                      testid={`${testid}.Input.capacity`}
                      disabled={!form.state.hasCapacity}
                    />
                  </DiscoFormControl>

                  {form.state.allowGuestAccess && (
                    <DiscoFormControl label={"Guests Capacity"} marginBottom={0}>
                      <DiscoNumberInput
                        value={form.state.guestCapacity}
                        onChange={(e) =>
                          (form.state.guestCapacity = Number(e.target.value))
                        }
                        fullWidth
                        testid={`${testid}.Input.guest-capacity`}
                        disabled={!form.state.hasCapacity}
                      />
                    </DiscoFormControl>
                  )}
                </div>
              ) : null}
            </DiscoFormControl>

            <DiscoDivider marginTop={0} marginBottom={2.5} />

            {/* Feedback */}
            <DiscoFormControl
              title={"Feedback"}
              titleTooltip={{
                label: "Request feedback from participants after the event is over.",
                placement: "right",
              }}
              variant={"one-column"}
              titleAction={
                <DiscoSwitch
                  name={"displayFeedback"}
                  hideForm
                  checked={form.state.collectFeedback!}
                  onChange={(isChecked) => (form.state.collectFeedback = isChecked)}
                  testid={`${testid}.feedback-checkbox`}
                />
              }
            >
              <div>
                <DiscoText color={"text.secondary"} display={"inline"}>
                  {"Request feedback from participants after the event is over. "}
                  <DiscoLink onClick={() => setPreview(true)}>
                    {"Preview feedback form"}
                  </DiscoLink>
                </DiscoText>
              </div>
            </DiscoFormControl>

            <DiscoDivider marginTop={0} marginBottom={2.5} />

            {/* Reminders */}
            <EventReminderFormSection form={form} />
          </div>
        </Collapse>
        <DiscoDivider marginTop={2} marginBottom={0} />
      </Form>
      <EventFeedbackModal
        isOpen={preview}
        onClose={() => setPreview(false)}
        occurrenceId={""}
        onSubmitFeedback={() => setPreview(false)}
        previewMode={true}
      />
    </div>
  )

  function getDefaultProductId() {
    // If we can create events in the active product, default to the active product
    if (activeProduct && defaultToActiveProduct) {
      return activeProduct.id
    }

    // If we can create community events, default to community events
    if (canCreateCommunityEvents) return null

    // Attempt to default to the first product with an events app
    const productMemberships = Relay.connectionToArray(membership?.adminMemberships)
    return productMemberships[0]?.productId
  }

  function getDefaultHosts(): OccurrenceHostInput[] {
    // Set default host as current user or product creator membership for product events where user does not have membership
    const pmId = product?.viewerMembership?.id || product?.createdByUserMembership?.id
    const omId = activeOrganization.viewerMembership?.id

    if (form && form.state.productId) {
      return [{ productMembershipId: pmId }]
    }
    return [{ organizationMembershipId: omId }]
  }

  async function handleSubmit() {
    const isPublishing = form.state.status === "published"
    const input: CreateEventInput = {
      organizationId: form.state.organizationId,
      productId: form.state.productId,
      appId: form.state.appId,
      datetimeRange: [form.state.startDatetime, form.state.endDatetime],
      status: form.state.status,
      visibility: form.state.visibility,
      rrule:
        form.state.rruleConfig.rruleOptions && form.state.isRecurring
          ? new RRule({
              ...form.state.rruleConfig.rruleOptions,
              until: form.state.rruleConfig.rruleOptions.until
                ? new Date(
                    new Date(form.state.rruleConfig.rruleOptions.until).setDate(
                      form.state.rruleConfig.rruleOptions.until.getDate() + 1
                    )
                  )
                : undefined,
              // If there is no count, then default to 1
              count:
                !form.state.rruleConfig.rruleOptions.count &&
                !form.state.rruleConfig.rruleOptions.until
                  ? 1
                  : form.state.rruleConfig.rruleOptions.count,
            }).toString()
          : null,
      videoRoomType:
        form.state.meetingTab === "zoom"
          ? form.state.zoomMeetingTypeRadioSelection
          : form.state.meetingTab === "link"
          ? "custom-link"
          : form.state.meetingTab,
      meetingProviderId:
        form.state.meetingTab === "zoom" ? form.state.meetingProvider?.id : undefined,
      meetingUrl:
        form.state.meetingTab === "link" ? form.state.customLink.trim() : undefined,
      physicalAddress: form.state.physicalAddress,
      timezone: form.state.timezone,
      addMemberGroupIds: form.state.selectedMemberGroupIds,
      content: form.state.content,
      hosts: form.state.hosts,
      allowGuestAccess: form.state.productId ? false : form.state.allowGuestAccess,
      capacity: form.state.hasCapacity ? form.state.capacity ?? undefined : null,
      collectFeedback: form.state.collectFeedback,
      guestCapacity: form.state.hasCapacity
        ? form.state.guestCapacity ?? undefined
        : null,
      reminders: form.state.reminders?.map((reminder) => ({
        id: reminder.id,
        timing: convertGolangDurationFromDays(reminder.timing || ""),
        audience: reminder.audience,
        emailSubject: reminder.emailSubject,
        emailRichEditorBody: reminder.emailRichEditorBody,
      })),
    }

    const { didSave, response } = await form.submit(input)
    if (!didSave || !response?.node) return
    const occurrence = response.node!
    invalidateOccurrenceConnections(activeOrganization.id, occurrence.event.productId)

    displaySuccessToast({
      message: `Successfully created event!`,
      testid: `${testid}.success-toast`,
    })

    // open drawer
    drawer.open({
      drawerOccurrenceId: response.node.id,
      drawerEventTab: "details",
      drawerRecentlyPublished: isPublishing ? "1" : "0",
    })

    // must close modal after drawer is opened
    if (onClose) onClose()

    if (onCreate) onCreate(occurrence)
  }

  function handleSwitchCapacity(isChecked: boolean) {
    form.state.hasCapacity = isChecked
    if (isChecked) {
      form.state.capacity = 20 // default to 20
      return
    }
    form.state.capacity = null
    form.state.guestCapacity = null
  }
}

const useStyles = makeUseStyles((theme) => ({
  dateTime: {
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.large,
    listStyle: "none",
    boxShadow: theme.palette.groovyDepths.insideCard,
    margin: theme.spacing(1.5, 0.25, 0),
    padding: theme.spacing(2.5),
  },
  formControlChild: {
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.large,
    listStyle: "none",
    boxShadow: theme.palette.groovyDepths.insideCard,
    margin: theme.spacing(1.5, 0.25, 0),
    padding: theme.spacing(2),
  },
  collapsibleContainer: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.75),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5),
  },
  headerText: {
    width: "100%",
  },
  headerContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  text: {
    whiteSpace: "nowrap",
  },
  editor: {
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.large,
  },
}))

export function CreateEventFormSkeleton() {
  return (
    <>
      <EventNameDestinationFormFieldsSkeleton variant={"one-column"} />
      <EventDatetimeFormFieldsSkeleton variant={"one-column"} />
      <EventMeetingFormFieldsSkeleton variant={"one-column"} />
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(CreateEventForm),
  skeleton: CreateEventFormSkeleton,
})

/** Invalidate relay store state for all event lists */
export function invalidateOccurrenceConnections(
  organizationId: GlobalID,
  productId: GlobalID
) {
  commitLocalUpdate(RelayEnvironment, (store) => {
    const org = store.get(organizationId)
    if (!org) return

    ConnectionHandler.getConnections(org, "EventsBlockOrganization__occurrences").forEach(
      (connection) => connection.invalidateRecord()
    )

    // occurrence connections in OrganizationOccurrenceList
    ConnectionHandler.getConnections(
      org,
      "OrganizationOccurrenceList__nextOccurrences"
    ).forEach((connection) => connection.invalidateRecord())

    ConnectionHandler.getConnections(
      org,
      "OrganizationOccurrenceList__previousOccurrences"
    ).forEach((connection) => connection.invalidateRecord())

    ConnectionHandler.getConnections(
      org,
      "RSVPToAllOrganizationEventsButton_occurrences"
    ).forEach((connection) => connection.invalidateRecord())

    ConnectionHandler.getConnections(
      org,
      "EventsJumpToDateCalendar__occurrences"
    ).forEach((connection) => connection.invalidateRecord())

    // occurrence connection in EventSeriesOccurrenceList
    ConnectionHandler.getConnections(
      org,
      "EventSeriesOccurrenceList__occurrences"
    ).forEach((connection) => connection.invalidateRecord())

    // occurrence connection in LiveEventbar
    ConnectionHandler.getConnections(org, "LiveEventBar__occurrences").forEach(
      (connection) => connection.invalidateRecord()
    )

    org
      .getLinkedRecord("occurrences", {
        datetimeFilter: "upcoming",
      })
      ?.invalidateRecord()

    // Refresh product level occurrence lists
    const productRecord = store.get(productId)
    if (!productRecord) return

    // occurrence connection in CourseDashboardUpcomingEvents
    productRecord
      .getLinkedRecord("eventOccurrences", {
        first: 3,
        isUpcoming: true,
      })
      ?.invalidateRecord()

    // occurrence connection in CourseEventsPage
    ConnectionHandler.getConnections(
      productRecord,
      "ProductOccurrencesList__eventOccurrences"
    ).forEach((connection) => connection.invalidateRecord())

    productRecord.getLinkedRecord("eventOccurrences")?.invalidateRecord()

    // occurrence connection in CourseDashboardUpcomingEvents
    productRecord
      .getLinkedRecord("eventOccurrences", {
        isUpcoming: true,
      })
      ?.invalidateRecord()

    // occurrence connection in EventsDashboardBlockCardView
    ConnectionHandler.getConnections(
      productRecord,
      "EventsDashboardBlockCardView__eventOccurrences"
    ).forEach((connection) => connection.invalidateRecord())
  })
}
