import CommunitySwitcherListItemTemplate from "@/community/switcher/CommunitySwitcherListItemTemplate"
import CreateOrganizationButton from "@/organization/create/new-organization-button/CreateOrganizationButton"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"

export default function CommunitySwitcherCreateCommunityButton({
  testid = "CommunitySwitcherCreateCommunityButton",
}: TestIDProps) {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <CreateOrganizationButton testid={testid}>
      {(renderProps) => (
        <CommunitySwitcherListItemTemplate
          testid={renderProps.testid}
          isActive={false}
          badge={
            <div className={classes.badge}>
              <DiscoIcon icon={"add"} color={theme.palette.text.primary} />
            </div>
          }
          title={
            <DiscoText
              testid={`${testid}.name`}
              variant={"body-sm-700"}
              setColor={"currentColor"}
              truncateText={1}
            >
              {"Create New Community"}
            </DiscoText>
          }
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        />
      )}
    </CreateOrganizationButton>
  )
}

const useStyles = makeUseStyles((theme) => ({
  badge: {
    width: 48,
    height: 48,
    borderRadius: theme.measure.borderRadius.big,
    display: "grid",
    placeItems: "center",
    border: `1.5px solid ${theme.palette.groovy.neutral[300]}`,
  },
}))
