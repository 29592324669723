import { useProductRoleLabels } from "@/role/roleUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ProductRole } from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { DiscoSelect, DiscoTooltip, DiscoTooltipProps, SelectOption } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

interface Props extends TestIDProps {
  value: ProductRole
  onChange: (role: ProductRole | null) => void
  disabledValues?: { role: ProductRole; tooltipContent?: DiscoTooltipProps["content"] }[]
}

function ProductRoleSelect({
  testid = "ProductRoleSelect",
  value,
  onChange,
  disabledValues,
}: Props) {
  const productRoleLabels = useProductRoleLabels()
  const classes = useStyles()

  return (
    <DiscoSelect
      testid={`${testid}`}
      autoComplete={false}
      disableClearable
      options={getOptions()}
      value={value}
      renderOption={(option) => {
        return (
          <DiscoTooltip
            content={option.disabledTooltipContent}
            disabled={!option.disabled}
          >
            <span data-testid={`${testid}-answer-${option.value}`}>{option.title}</span>
          </DiscoTooltip>
        )
      }}
      onChange={(v) => onChange(v)}
      classes={{ root: classes.select }}
      autoWidth
    />
  )

  function getOptions() {
    const roles: SelectOption<ProductRole>[] = []

    for (const role of ["member", "instructor", "manager"] as ProductRole[]) {
      const disabledProps = disabledValues?.find((v) => v.role === role)

      roles.push({
        value: role as ProductRole,
        title: productRoleLabels[role].singular,
        disabled: Boolean(disabledProps),
        disabledTooltipContent: disabledProps?.tooltipContent,
      })
    }
    return roles
  }
}

const useStyles = makeUseStyles(() => ({
  select: {
    flexShrink: 0,
    width: "auto",
  },
}))

export default ProductRoleSelect
