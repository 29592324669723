import { LoadQueryProps } from "@/core/root-app/AppWithContextProviders"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { E2EPreloadPageMutation } from "@/e2e-preload/__generated__/E2EPreloadPageMutation.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { isE2ETest } from "@utils/e2e"
import { useQueryParams } from "@utils/url/urlUtils"
import { useEffect, useState } from "react"
import { graphql } from "react-relay"
import { useHistory } from "react-router-dom"

type E2EPreloadPageProps = {
  userId: GlobalID
}

function E2EPreloadPage(props: LoadQueryProps) {
  const history = useHistory()
  const classes = useStyles()
  const { userId } = useQueryParams<E2EPreloadPageProps>()
  const [error, setError] = useState<unknown | null>(null)

  const signIn = Relay.useAsyncMutation<E2EPreloadPageMutation>(graphql`
    mutation E2EPreloadPageMutation($input: E2ESignInInput!) {
      e2eSignIn(input: $input) {
        data
      }
    }
  `)

  useEffect(() => {
    if (!isE2ETest()) return
    if (!userId) return

    preload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (error) {
    return (
      <div className={classes.container}>
        <DiscoText>{JSON.stringify(error)}</DiscoText>
      </div>
    )
  }

  return null

  async function preload() {
    try {
      // Sign in as the user
      await signIn({ input: { userId } })
      props.loadQuery({ domain: window.location.origin }, { fetchPolicy: "network-only" })
      history.push(ROUTE_NAMES.COMMUNITY.HOME.ROOT)
    } catch (e) {
      setError(e)
    }
  }
}

const useStyles = makeUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
})

export default Relay.withSkeleton({
  component: E2EPreloadPage,
  skeleton: () => null,
})
