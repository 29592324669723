import DiscoInput, { DiscoInputProps } from "@disco-ui/input/DiscoInput"
import { TestIDProps } from "@utils/typeUtils"

type DiscoNumberInputProps = TestIDProps &
  DiscoInputProps & {
    min?: number
    max?: number
  }

function DiscoNumberInput({
  testid = "DiscoNumberInput",
  min = 0,
  max,
  ...props
}: DiscoNumberInputProps) {
  const { inputProps, ...rest } = props

  return (
    <DiscoInput
      data-testid={testid}
      type={"number"}
      inputProps={{
        ...inputProps,
        min,
        max,
      }}
      {...rest}
    />
  )
}

export default DiscoNumberInput
