import { useActiveProduct } from "@/core/context/ActiveProductContext"
import ProductAdminDashboardAction, {
  ProductAdminDashboardActionSkeleton,
} from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/ProductAdminDashboardAction"
import { ProductAdminDashboardActionsQuery } from "@/dashboard/blocks/kinds/ProductAdminDashboardBlockComponents/__generated__/ProductAdminDashboardActionsQuery.graphql"
import useLocalStorageState from "@/onboarding/utils/hooks/useLocalStorageState"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoLink, DiscoSection, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Grid, useTheme } from "@material-ui/core"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

interface Props {
  blockId: GlobalID
}

function ProductAdminDashboardActions(props: Props) {
  const { blockId } = props
  const theme = useTheme()
  const classes = useStyles()
  const activeProduct = useActiveProduct()!

  const [{ block }, refetch] =
    Relay.useRefetchableQuery<ProductAdminDashboardActionsQuery>(
      graphql`
        query ProductAdminDashboardActionsQuery($id: ID!) {
          block: node(id: $id) {
            ... on ProductAdminDashboardBlock {
              product {
                id
                productAdminActions(first: null)
                  @connection(key: "ProductAdminDashboardActions__productAdminActions") {
                  __id
                  edges {
                    node {
                      id
                      ...ProductAdminDashboardActionFragment
                    }
                  }
                }
              }
            }
          }
        }
      `,
      { id: blockId },
      { fetchPolicy: "store-and-network" }
    )
  const product = block?.product

  const [actionsIsCollapsed, setActionsIsCollapsed] = useLocalStorageState({
    key: "disco-admin-actions-collapsed",
    propertyName: activeProduct.id,
    defaultValue: false,
  })

  const [actionsIsShowMore, setActionsIsShowMore] = useLocalStorageState({
    key: "disco-admin-actions-show-more",
    propertyName: activeProduct.id,
    defaultValue: false,
  })

  useSubscribeToInvalidationState(
    product?.productAdminActions ? [product.productAdminActions.__id] : [],
    () => refetch({ id: blockId })
  )

  const allActions = Relay.connectionToArray(product?.productAdminActions)
  const visibleActions = actionsIsShowMore ? allActions.slice(0, 3) : allActions

  return (
    <DiscoSection border padding={2}>
      <DiscoContainerButton
        testid={`ProductAdminDashboardActions.${
          actionsIsCollapsed ? "expand" : "collapse"
        }`}
        className={classes.header}
        onClick={() => {
          setActionsIsCollapsed(!actionsIsCollapsed)
        }}
      >
        <DiscoText
          variant={"body-md-700"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.100" : "groovy.neutral.600"
          }
        >
          {"Suggested Admin Actions"}
        </DiscoText>

        <DiscoIcon icon={actionsIsCollapsed ? "arrow-right" : "arrow-down"} />
      </DiscoContainerButton>
      {!actionsIsCollapsed && allActions.length === 0 && (
        <div className={classes.emptyState}>
          <DiscoText variant={"body-md"} align={"center"} color={"groovy.neutral.500"}>
            {"No Pending Actions"}
          </DiscoText>
        </div>
      )}
      {!actionsIsCollapsed && allActions.length > 0 && (
        <>
          <div className={classes.subHeader}>
            {allActions.length > 3 && (
              <DiscoLink
                data-testid={`ProductAdminDashboardActions.${
                  actionsIsShowMore ? "see-all" : "see-less"
                }`}
                textDecoration={"underline"}
                onClick={() => {
                  setActionsIsShowMore(!actionsIsShowMore)
                }}
              >
                {actionsIsShowMore ? "See All" : "See Less"}
              </DiscoLink>
            )}
          </div>
          <Grid container data-testid={"ProductAdminActions"}>
            {visibleActions.map((act, i) => (
              <ProductAdminDashboardAction
                key={act.id}
                adminActionKey={act}
                testid={`ProductAdminActionItem-${i}`}
              />
            ))}
          </Grid>
        </>
      )}
    </DiscoSection>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHeader: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(1, 0),
  },
  emptyState: {
    padding: theme.spacing(2.5, 0),
  },
}))

export function ProductAdminDashboardActionsSkeleton() {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <DiscoSection border>
      <div className={classes.header}>
        <DiscoText
          variant={"body-md-700"}
          color={
            theme.palette.type === "dark" ? "groovy.onDark.100" : "groovy.neutral.600"
          }
        >
          {"Suggested Admin Actions"}
        </DiscoText>
      </div>
      <Grid container>
        <ProductAdminDashboardActionSkeleton />
        <ProductAdminDashboardActionSkeleton />
        <ProductAdminDashboardActionSkeleton />
      </Grid>
    </DiscoSection>
  )
}

export default Relay.withSkeleton({
  component: ProductAdminDashboardActions,
  skeleton: ProductAdminDashboardActionsSkeleton,
})
