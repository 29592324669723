import { isDiscoDomain } from "@/core/route/util/routeUtils"
import { GlobalID } from "@/relay/RelayTypes"

export type AiApiGenerateTextArgs = {
  organizationId: GlobalID
  productId?: GlobalID
  responseType: AiApiGenerateTextResponseType
  prompt: string
  referenceUrl?: string
  referenceEmbeddingSourceIds?: GlobalID[]
  referenceModuleContentUsageId?: GlobalID
}
export type AiApiGenerateTextResponseType = "title" | "description" | "email"
export type AiApiGeneratePostArgs = {
  feedId: GlobalID
  template?: AiApiGeneratePostTemplate
  responseType: AiApiGeneratePostResponseType
  postTitle?: string // when generating post body, optionally pass an existing title
}
export type AiApiGeneratePostTemplate = "inactive" | "default"
export type AiApiGeneratePostResponseType = "title" | "body"
export type AiApiGenerateMessageArgs = {
  channelId: string
  template: AiApiGenerateMessageTemplate
}
export type AiApiGenerateMessageTemplate = "inactive" | "default"

namespace AiApi {
  export async function request(path: string, body: Record<string, any>) {
    const baseUrl = isDiscoDomain(window.location.host) ? GRAPHQL_URL : "/"
    const abortController = new AbortController()

    const response = await fetch(`${baseUrl}${path}`, {
      method: "post",
      credentials: "include",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
      signal: abortController.signal,
    })

    return { response, abortController }
  }

  export function generateBrainSearchResponse({
    brainSearchId,
    messageText,
  }: {
    brainSearchId: GlobalID
    messageText: string
  }) {
    return request("ai/generate/brain-search", {
      brainSearchId,
      messageText,
    })
  }

  export function generateText(input: AiApiGenerateTextArgs) {
    return request("ai/generate/text", input)
  }

  export function generatePost(input: AiApiGeneratePostArgs) {
    return request("ai/generate/post", input)
  }

  export function generateMessage(input: AiApiGenerateMessageArgs) {
    return request("ai/generate/message", input)
  }
}

export default AiApi
