import useIsWebView from "@/product/util/hook/useIsWebView"
import { isValidationError } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Toast, { ToastProps } from "@components/toast/Toast"
import { generateErrorMessageFromCaughtError } from "@utils/error/errorUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import React from "react"
import { v4 as uuidv4 } from "uuid"

/** Global array of currently visible toasts */
const toasts = observable.array<Omit<ToastProps, "onHide">>()

/** Display a new toast. */
export function displayToast(args: Omit<ToastProps, "id" | "onHide">) {
  toasts.push({ id: uuidv4(), ...args })
}

type CommonDisplayToastArgs = Omit<ToastProps, "id" | "type" | "onHide">

export function displaySuccessToast(args: CommonDisplayToastArgs) {
  displayToast({
    type: "success",
    ...args,
  })
}

export function displayWarningToast(args: CommonDisplayToastArgs) {
  displayToast({
    type: "warning",
    ...args,
  })
}

export function displayInfoToast(args: CommonDisplayToastArgs) {
  displayToast({
    type: "info",
    ...args,
  })
}

export function displayErrorToastWithArgs(args: CommonDisplayToastArgs) {
  displayToast({
    type: "error",
    ...args,
  })
}

export function displayErrorToast(err: any) {
  displayErrorToastWithArgs({
    message:
      typeof err === "string"
        ? err
        : "message" in err // since shape of error is `any` and in other toasts we expect to pass an object with a message
        ? // handle `message` here too in case ex: a ValidationError is passed directly to toast
          err.message
        : generateErrorMessageFromCaughtError(err),
  })
}

export function displayGraphQLErrorToast(error: unknown, fallbackMessage?: string) {
  console.error(error)
  if (isValidationError(error) && error.message) {
    displayErrorToast(error.message)
  } else {
    displayErrorToast(fallbackMessage || "Something went wrong, please try again later")
  }
}

export async function displayRestfulErrorToast(response: Response) {
  const error = await response.json()
  console.error(error)

  if (typeof error === "string") {
    displayErrorToast(error)
  } else if (error.message) {
    displayErrorToast(error.message)
  } else if (error.errors.length) {
    displayErrorToast(error.errors[0].message)
  } else {
    displayErrorToast("Something went wrong, please try again later")
  }
}

const ToastProvider: React.FC = ({ children }) => {
  const isWebView = useIsWebView()
  return (
    <>
      {children}
      {!isWebView && <ToastRenderer />}
    </>
  )
}

/**
 * Separated from the ToastProvider so that only this component
 * re-renders when a toast shows/hides, instead of the entire app
 */
const ToastRenderer = observer(() => {
  const classes = useStyles()

  return (
    <div className={classes.toastList}>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} onHide={() => toasts.remove(toast)} />
      ))}
    </div>
  )
})

const useStyles = makeUseStyles((theme) => ({
  toastList: {
    position: "fixed",
    bottom: 0,
    left: 0,
    marginBottom: "20px",
    marginLeft: "20px",
    zIndex: 9999,
    display: "grid",
    gap: theme.spacing(2),
  },
}))

export default ToastProvider
