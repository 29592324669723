import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIText from "@components/ai/AIText"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface Props extends TestIDProps {
  name: string
  className?: string
}

function AIChip({ testid = "AIChip", name, className }: Props) {
  const classes = useStyles()
  return (
    <div className={classNames(classes.container, className)}>
      <AIText testid={testid} variant={"body-xs-500"}>
        {name}
      </AIText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    minHeight: "26px",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.xl,
    padding: theme.spacing(0, 1),
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.bluePurpleLight,
    width: "fit-content",
  },
}))

export default AIChip
