import BrainSearchAssistantMessage from "@/brain-search/internal/BrainSearchAssistantMessage"
import BrainSearchInput from "@/brain-search/internal/BrainSearchInput"
import { BrainSearchParams } from "@/brain-search/internal/BrainSearchLandingPage"
import BrainSearchPageHeader from "@/brain-search/internal/BrainSearchPageHeader"
import BrainSearchUserMessage from "@/brain-search/internal/BrainSearchUserMessage"
import {
  BrainSearchPage_PaginationQuery,
  BrainSearchPage_PaginationQuery$data,
} from "@/brain-search/internal/__generated__/BrainSearchPage_PaginationQuery.graphql"
import AiApi from "@/common/AiApi"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import RestfulUtil from "@/core/restful/RestfulUtil"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import { displayRestfulErrorToast } from "@components/toast/ToastProvider"
import { DiscoSpinner } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { useQueryParams } from "@utils/url/urlUtils"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import { useEffect, useRef } from "react"
import { generatePath, useParams } from "react-router-dom"
import { default as ConnectionHandlerPlus } from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql, RecordSourceProxy } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

const MESSAGES_PER_LOAD = 100

type MessageResponseStep =
  | "inactive"
  | "start"
  | "internal_sources"
  | "external_sources"
  | "answer"
  | "certified_sources"
  | "expert"
  | "followup"
  | "end"

export type BrainParams = {
  shareId: string
  searchId: string
}

export type BrainSearchMessage = NodeFromConnection<
  NonNullable<BrainSearchPage_PaginationQuery$data["brainSearch"]>["messages"]
>

interface BrainSearchPageProps extends TestIDProps {
  initialMessage?: string
  shared?: boolean
}

function BrainSearchPage({ initialMessage = "", shared = false }: BrainSearchPageProps) {
  const activeOrganization = useActiveOrganization()
  // const history = useHistory()
  const params = useQueryParams<BrainSearchParams>()
  const { shareId, searchId } = useParams<BrainParams>()
  const isShared = shared || !!shareId
  const classes = useStyles()
  const abortControllerRef = useRef<AbortController>()
  const isGenerating = useRef(false)
  const step = useRef<MessageResponseStep>("start")

  // If the response is pending, create the initial message
  useEffect(() => {
    if (params.status !== "pending") return
    if (!initialMessage) return
    handleCreateMessage(initialMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.status])

  const { data } = Relay.useRefetchablePaginationQuery<BrainSearchPage_PaginationQuery>(
    graphql`
      query BrainSearchPage_PaginationQuery($id: ID!, $first: Int!, $after: String) {
        brainSearch: node(id: $id) {
          ... on BrainSearch {
            id
            title
            messages(first: $first, after: $after)
              @connection(key: "BrainSearchPage__messages") {
              totalCount
              edges {
                node {
                  id
                  messageText
                  type
                  expertResponses {
                    totalCount
                    edges {
                      node {
                        id
                        text
                        expert
                      }
                    }
                  }
                  sources {
                    totalCount
                    edges {
                      node {
                        id
                        entity
                        entityId
                        content {
                          id
                          name
                        }
                        asset {
                          id
                          name
                        }
                        trainingData {
                          id
                          name
                          url
                        }
                      }
                    }
                  }
                  externalSources {
                    totalCount
                    edges {
                      node {
                        id
                        entity
                        url
                        owner
                        title
                        isCertified
                        isExternal
                        score
                      }
                    }
                  }
                  suggestedFollowups {
                    edges {
                      node {
                        id
                        text
                      }
                    }
                  }
                }
              }
              pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
              }
            }
          }
        }
      }
    `,
    {
      id: params.searchId || searchId || shareId || "",
      first: MESSAGES_PER_LOAD,
    },
    { connectionName: "brainSearch.messages", refetchInBackground: false }
  )

  if (!data) return null

  const { brainSearch } = data
  const messages = Relay.connectionToArray(brainSearch?.messages)

  if (!brainSearch) return null

  return (
    <div className={classNames(classes.root, classes.fadeIn)}>
      <div className={classes.container}>
        {/* Header */}
        <BrainSearchPageHeader title={brainSearch.title} />

        {/* Content */}
        <ScrollShadowContainer
          hideScrollbar
          classes={{ parentContainer: classes.parentScrollContainer }}
        >
          <div className={classNames(classes.contentPadding, classes.fullHeight)}>
            <div className={classes.content}>
              <div className={classes.lhsContent}>
                {messages.map((message) => {
                  switch (message.type) {
                    case "user":
                      return <BrainSearchUserMessage key={message.id} message={message} />
                    case "assistant":
                      return (
                        <BrainSearchAssistantMessage
                          key={message.id}
                          message={message}
                          createMessage={handleCreateMessage}
                          isShared={isShared}
                        />
                      )
                    default:
                      return null
                  }
                })}

                {/* Input */}
                {!isShared && (
                  <>
                    <div className={classes.spacer} />
                    <BrainSearchInput
                      placeholder={"Ask a followup..."}
                      onSubmit={handleCreateMessage}
                      classes={{ root: classes.input }}
                      multiline={false}
                      isGenerating={isGenerating.current}
                      onAbort={handleAbort}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </ScrollShadowContainer>
      </div>
    </div>
  )

  function handleAbort() {
    abortControllerRef.current?.abort()
    isGenerating.current = false
  }

  function getAssistantMessage(store: RecordSourceProxy, id: string) {
    return store.get(Relay.toGlobalId("BrainSearchMessage", id))
  }

  function getMessagesConnection(store: RecordSourceProxy, brainSearchId: string) {
    const brainSearchRecord = store.get(brainSearchId)
    if (!brainSearchRecord) return

    const messagesConnections = ConnectionHandlerPlus.getConnections(
      brainSearchRecord,
      "BrainSearchPage__messages"
    )
    if (!messagesConnections.length) return
    return messagesConnections[0]
  }

  function getExpertsConnection(store: RecordSourceProxy, assistantMessageId: string) {
    // Get the assistant message
    const tmpAssistantMessage = getAssistantMessage(store, assistantMessageId)
    if (!tmpAssistantMessage) return

    // Get the connection for expert responses on the assistant message
    const expertConnection = tmpAssistantMessage.getLinkedRecord("expertResponses")
    if (!expertConnection) return
    return expertConnection
  }

  function getInternalSourcesConnection(
    store: RecordSourceProxy,
    assistantMessageId: string
  ) {
    // Get the assistant message
    const tmpAssistantMessage = store.get(
      Relay.toGlobalId("BrainSearchMessage", assistantMessageId)
    )
    if (!tmpAssistantMessage) return

    // Get the connection for sources on the assistant message
    const sourcesConnection = tmpAssistantMessage.getLinkedRecord("sources")
    return sourcesConnection
  }

  function getExternalSourcesConnection(
    store: RecordSourceProxy,
    assistantMessageId: string
  ) {
    // Get the assistant message
    const tmpAssistantMessage = store.get(
      Relay.toGlobalId("BrainSearchMessage", assistantMessageId)
    )
    if (!tmpAssistantMessage) return

    // Get the connection for sources on the assistant message
    const sourcesConnection = tmpAssistantMessage.getLinkedRecord("externalSources")
    return sourcesConnection
  }

  function getEntity(entity: string) {
    switch (entity) {
      case "content":
        return "Content"
      case "asset":
        return "Asset"
      case "training_data":
        return "TrainingData"
      default:
        return ""
    }
  }

  function getFollowupsConnection(store: RecordSourceProxy, assistantMessageId: string) {
    // Get the assistant message
    const tmpAssistantMessage = getAssistantMessage(store, assistantMessageId)
    if (!tmpAssistantMessage) return

    // Get the connection for suggested followups on the assistant message
    const followupsConnection = tmpAssistantMessage.getLinkedRecord("suggestedFollowups")
    return followupsConnection
  }

  async function handleCreateMessage(text: string) {
    if (isShared) return
    if (!activeOrganization) return
    isGenerating.current = true

    const tmpUserMessageId = uuidv4()
    const tmpAssistantMessageId = uuidv4()

    commitLocalUpdate(RelayEnvironment, (store) => {
      if (!brainSearch?.id) return

      const messagesConnection = getMessagesConnection(store, brainSearch.id)
      if (!messagesConnection) return

      // Create a tmp user message record in the Relay Store
      const tmpUserMessage = Relay.fabricateNode(store, "BrainSearchMessage", {
        id: tmpUserMessageId,
        messageText: text,
        type: "user",
      })
      Relay.insertNodeIntoPaginatedConnection(store, messagesConnection, tmpUserMessage)

      // Create a tmp assistant message record in the Relay Store
      const tmpAssistantMessage = Relay.fabricateNode(store, "BrainSearchMessage", {
        id: tmpAssistantMessageId,
        messageText: "",
        type: "assistant",
      })

      // Create a connection for sources on the assistant message
      const tmpSourceConnection = Relay.fabricateConnection(
        store,
        "BrainSearchMessageSource",
        []
      )

      // Insert the sources into the assistant message connection
      tmpAssistantMessage.setLinkedRecord(tmpSourceConnection, "sources")

      // Create a connection for external sources on the assistant message
      const tmpExternalSourceConnection = Relay.fabricateConnection(
        store,
        "BrainSearchMessageExternalSource",
        []
      )

      // Insert the external sources into the assistant message connection
      tmpAssistantMessage.setLinkedRecord(tmpExternalSourceConnection, "externalSources")

      // Create a connection for expert responses on the assistant message
      const tmpExpertResponseConnection = Relay.fabricateConnection(
        store,
        "BrainExpertResponse",
        []
      )

      // Insert the expert responses into the assistant message connection
      tmpAssistantMessage.setLinkedRecord(tmpExpertResponseConnection, "expertResponses")

      // Create a connection for suggested followups on the assistant message
      const tmpSuggestedFollowupsConnection = Relay.fabricateConnection(
        store,
        "BrainSuggestedFollowup",
        []
      )

      // Insert the suggested followups into the assistant message connection
      tmpAssistantMessage.setLinkedRecord(
        tmpSuggestedFollowupsConnection,
        "suggestedFollowups"
      )

      // Insert the assistant message into the messages connection
      Relay.insertNodeIntoPaginatedConnection(
        store,
        messagesConnection,
        tmpAssistantMessage
      )
    })

    // Generate the response
    const { response, abortController } = await AiApi.generateBrainSearchResponse({
      brainSearchId: brainSearch?.id || "",
      messageText: text,
    })
    abortControllerRef.current = abortController

    // Handle any errors
    const hasError = RestfulUtil.hasError(response)
    if (hasError) {
      await displayRestfulErrorToast(response)
      return
    }
    if (!response.body) return

    // Handle the text response stream
    await RestfulUtil.handleStream(
      response.body,
      (decodedChunk) => {
        switch (step.current) {
          // Update the assistant message with the decoded chunk
          case "answer":
            commitLocalUpdate(RelayEnvironment, (store) => {
              const tmpAssistantMessage = getAssistantMessage(
                store,
                tmpAssistantMessageId
              )
              if (!tmpAssistantMessage) return

              const existingText = tmpAssistantMessage.getValue("messageText")?.toString()
              Relay.deepUpdate(store, tmpAssistantMessage, {
                messageText: existingText + decodedChunk,
              })
            })

            return true
        }

        return true
      },
      {
        onData: (streamData) => {
          if (streamData.step) step.current = streamData.step

          // Create the internal sources from the data
          if (streamData.internal_sources) {
            if (!streamData.internal_sources.length) return
            commitLocalUpdate(RelayEnvironment, (store) => {
              // Get the connection for sources on the assistant message
              const sourcesConnection = getInternalSourcesConnection(
                store,
                tmpAssistantMessageId
              )
              if (!sourcesConnection) return

              // Create the source objects from the data
              for (const source of streamData.internal_sources) {
                // Make the entityId a global ID
                source.entityId = Relay.toGlobalId(
                  getEntity(source.entity),
                  source.entityId
                )

                // Create the entity node if it doesn't exist
                if (!store.get(source.entityId)) {
                  Relay.fabricateNode(store, getEntity(source.entity), {
                    ...(source.content || source.asset || source.trainingData),
                    id: source.entityId,
                  })
                }

                // Create a tmp source record in the Relay Store
                const tmpSource = Relay.fabricateNode(
                  store,
                  "BrainSearchMessageSource",
                  source
                )

                // Insert the source into the sources connection
                Relay.insertNodeIntoPaginatedConnection(
                  store,
                  sourcesConnection,
                  tmpSource
                )
              }
            })
          }

          // Create the source objects from the data
          if (streamData.external_sources || streamData.certified_sources) {
            const sources = streamData.external_sources || streamData.certified_sources
            if (!sources || !sources.length) return
            commitLocalUpdate(RelayEnvironment, (store) => {
              // Get the connection for sources on the assistant message
              const sourcesConnection = getExternalSourcesConnection(
                store,
                tmpAssistantMessageId
              )
              if (!sourcesConnection) return

              // Create an array to track scores, this is already ordered so no need to sort
              const sourceEdges = sourcesConnection.getLinkedRecords("edges") || []
              const orderedScores = sourceEdges.map((edge) => {
                const node = edge.getLinkedRecord("node")
                if (!node) return 0
                return Number(node.getValue("score")?.toString())
              })

              for (const source of sources) {
                // Create a tmp source record in the Relay Store
                const tmpSource = Relay.fabricateNode(
                  store,
                  "BrainSearchMessageSource",
                  source
                )

                // Determine the index to insert the source by traversing the ordered scores
                // in reverse order to find the first score that is less than or equal
                let insertAtIndex = 0
                for (let i = orderedScores.length - 1; i >= 0; i--) {
                  // If the incoming score is less than or equal to the current
                  // score, insert the source after the current index
                  if (source.score <= orderedScores[i]) {
                    insertAtIndex = i + 1
                    break
                  }

                  // Otherwise move the index to the next position
                  else insertAtIndex = i
                }

                // Insert the score into the ordered scores array
                orderedScores.splice(insertAtIndex, 0, source.score)

                // Insert the source into the sources connection
                Relay.insertNodeIntoPaginatedConnection(
                  store,
                  sourcesConnection,
                  tmpSource,
                  insertAtIndex
                )
              }
            })
          }

          // Create a new expert response from the data
          if (streamData.expert_response) {
            commitLocalUpdate(RelayEnvironment, (store) => {
              // Get the connection for expert responses on the assistant message
              const expertsConnection = getExpertsConnection(store, tmpAssistantMessageId)
              if (!expertsConnection) return

              // Create a tmp expert response record in the Relay Store
              const { expert, text: expertAnswer } = streamData.expert_response
              const tmpExpertResponse = Relay.fabricateNode(
                store,
                "BrainExpertResponse",
                { expert, text: expertAnswer }
              )

              // Insert the source into the sources connection
              Relay.insertNodeIntoPaginatedConnection(
                store,
                expertsConnection,
                tmpExpertResponse
              )
            })
          }

          // Create new suggested followups from the data
          if (streamData.suggested_followups) {
            commitLocalUpdate(RelayEnvironment, (store) => {
              const suggestedFollowupsConnection = getFollowupsConnection(
                store,
                tmpAssistantMessageId
              )
              if (!suggestedFollowupsConnection) return

              // Add new followups
              streamData.suggested_followups.forEach((followup: string) => {
                const tmpSuggestedFollowup = Relay.fabricateNode(
                  store,
                  "BrainSuggestedFollowup",
                  { text: followup }
                )

                Relay.insertNodeIntoPaginatedConnection(
                  store,
                  suggestedFollowupsConnection,
                  tmpSuggestedFollowup
                )
              })
            })
          }
        },
        onEnd: () => {
          isGenerating.current = false
          step.current = "end"

          // Update the URL to reflect the new searchId
          if (params.searchId && params.status === "pending") {
            // Need to use replaceState to avoid adding to the history
            // stack and causing a re-render of the component
            history.replaceState(
              {},
              "",
              generatePath(ROUTE_NAMES.BRAIN.SEARCH, {
                searchId: params.searchId,
              })
            )
          }
        },
      }
    )
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  },
  contentPadding: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0% 10%",

    [theme.breakpoints.down("md")]: {
      padding: "0% 5%",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "0%",
    },
  },
  fullHeight: {
    height: "100%",
  },
  parentScrollContainer: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    gap: theme.spacing(3),
    maxWidth: "1400px",
  },
  lhsContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    flexShrink: 0,
    height: "100%",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  spacer: {
    margin: "auto",
  },
  input: {
    position: "sticky",
    bottom: theme.spacing(3),
    width: "100%",
    border: `0.5px solid ${theme.palette.primary.main}`,
    minHeight: "48px",
  },
  fadeIn: {
    animation: "$fadeIn 1s",
  },
  // eslint-disable-next-line local-rules/disco-unused-classes
  "@keyframes fadeIn": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}))

function BrainSearchPageSkeleton() {
  return <DiscoSpinner size={"lg"} absoluteCenter />
}

export default Relay.withSkeleton({
  component: observer(BrainSearchPage),
  skeleton: BrainSearchPageSkeleton,
})
