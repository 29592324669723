/** AppPageLayout is any page within a community / experience that can contain the admin nav */

import { postWebViewMessage } from "@/apps/util/hooks/useWebViewMessage"
import CommunityAppBar from "@/community/CommunityAppBar"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { DefaultLightThemeProvider } from "@/core/context/CustomThemeProvider"
import ErrorBoundary from "@/core/error/ErrorBoundary"
import HiddenForRoutes from "@/core/route/hidden-for-routes/HiddenForRoutes"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isAdminConsolePath, isBrainSearchPath } from "@/core/route/util/routeUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import GlobalTopBar from "@/main/page/global-nav/GlobalTopBar"
import AppHeaderLayout from "@/main/page/header/AppHeaderLayout"
import usePageSidebar from "@/main/page/side-bar/usePageSidebar"
import MemberOnboardingBanner from "@/member-onboarding/MemberOnboardingBanner"
import ViewAsAppBar from "@/product/course/view-as/ViewAsAppBar"
import useIsInLearnMode from "@/product/util/hook/useIsInLearnMode"
import useIsWebView from "@/product/util/hook/useIsWebView"
import ImpersonateUserAppBar from "@/user/impersonate/ImpersonateUserAppBar"
import styleIf from "@assets/style/util/styleIf"
import { DiscoPageSkeleton } from "@disco-ui/page/DiscoPage"
import { AppBar } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useResizeObserver from "@utils/hook/useResizeObserver"
import {
  getWebViewHeader,
  useWebViewScroll,
  WebViewHeader,
} from "@utils/webView/webViewUtils"
import classNames from "classnames"
import React, { Suspense, useRef, useState } from "react"
import { Route, Switch, useLocation } from "react-router-dom"

export default function AppPageLayout({ children }: { children: React.ReactNode }) {
  const activeOrganization = useActiveOrganization()
  const location = useLocation()
  const isOnAdmin = isAdminConsolePath(location.pathname)
  const isOnBrainSearch = isBrainSearchPath(location.pathname)
  const isMobile = useIsMobile()
  const isWebView = useIsWebView()
  const webViewHeader = getWebViewHeader()
  const pageSidebar = usePageSidebar()

  // Dynamically handle page content height based on the height of app bars
  const appBarRef = useRef<HTMLElement>()
  const pageBarRef = useRef<HTMLDivElement>(null)
  const [appBarHeight, setAppBarHeight] = useState<number>(
    appBarRef.current?.clientHeight || 0
  )
  const [pageBarHeight, setPageBarHeight] = useState<number>(
    pageBarRef.current?.clientHeight || 0
  )
  useResizeObserver(appBarRef, (el) => setAppBarHeight(el.clientHeight))
  useResizeObserver(pageBarRef, (el) => setPageBarHeight(el.clientHeight))

  const parentRef = useRef<HTMLDivElement | null>(null)

  // Handles sending scroll position messages to the webview
  useWebViewScroll({ parentRef, postWebViewMessage })

  const isInLearnMode = useIsInLearnMode()

  const classes = useStyles({
    appBarHeight,
    pageBarHeight,
    isOnAdmin,
    isMobile,
    webViewHeader,
    isInLearnMode,
  })

  if (isWebView) return <div className={classes.container}>{renderPage()}</div>

  return (
    <div className={classes.appContainer}>
      {renderFullWidthAppBars()}
      <div className={classes.mainContainer}>
        {renderSideBars()}
        {renderTopBarAndTab()}
      </div>
    </div>
  )

  function renderPage(roundedPageContainer = false) {
    const newLayout = !isWebView
    const page = (
      <div
        ref={newLayout ? undefined : parentRef}
        id={newLayout ? undefined : "app-page-layout-scroll-container"}
        className={classNames(newLayout ? classes.pageContainer : classes.page, {
          [classes.roundedPageContainer]: roundedPageContainer,
        })}
      >
        {!isOnBrainSearch && renderHeader()}
        <div
          ref={newLayout ? parentRef : undefined}
          id={newLayout ? "app-page-layout-scroll-container" : undefined}
          className={newLayout ? classes.pageContent : classes.fullHeightContentContainer}
        >
          <div id={"product-cover-banner-block-anchor"} />
          {/* Catch any loading/errors state to keep showing the header/nav elements */}
          <Suspense fallback={<DiscoPageSkeleton />}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </Suspense>
        </div>
        {renderMobileFooter()}
      </div>
    )
    // Override to the default light theme when in admin console
    if (isOnAdmin) return <DefaultLightThemeProvider>{page}</DefaultLightThemeProvider>
    return page
  }

  function renderFullWidthAppBars() {
    return (
      <HiddenForRoutes exact path={ALL_COMPONENTS_HIDDEN_ROUTES}>
        <div
          ref={(cbRef) => (appBarRef.current = cbRef as HTMLElement)}
          data-testid={"AppBar-wrapper"}
        >
          <AppBar
            elevation={0}
            color={"default"}
            position={"sticky"}
            classes={{ root: classes.appBarRoot }}
          >
            {/* don't show the onboarding banner anywhere the user is ex: registering for a product */}
            <HiddenForRoutes exact path={SIDEBARS_HIDDEN_ROUTES}>
              {activeOrganization && activeOrganization.viewerMembership?.id && (
                <MemberOnboardingBanner />
              )}
            </HiddenForRoutes>
          </AppBar>
          {/* This app bar will peak over modals and drawers */}
          <AppBar
            elevation={0}
            color={"default"}
            position={"sticky"}
            classes={{ root: classes.ViewAsAppBarRoot }}
          >
            <ViewAsAppBar />
            <ImpersonateUserAppBar />
          </AppBar>
        </div>
      </HiddenForRoutes>
    )
  }

  function renderSideBars() {
    // Users should never see a community side bar unless they are registered for a community
    if (!activeOrganization?.viewerMembership || isMobile) return null
    return (
      <HiddenForRoutes exact path={COMMUNITY_NAVIGATION_HIDDEN_ROUTES}>
        <CommunityAppBar />
      </HiddenForRoutes>
    )
  }

  function renderTopBarAndTab() {
    // Users without org membership should never see topbar or sidebars
    if (!activeOrganization?.viewerMembership || isMobile) return renderPage()
    return (
      <Switch>
        <Route exact path={COMMUNITY_NAVIGATION_HIDDEN_ROUTES}>
          {renderPage()}
        </Route>
        <Route>
          <div className={classes.rightContainer}>
            <GlobalTopBar />
            {renderGlobalTab()}
          </div>
        </Route>
      </Switch>
    )
  }

  function renderGlobalTab() {
    // Render the selected global nav tab's sidebar and page
    return (
      <div className={classes.globalTabContainer}>
        {pageSidebar && React.createElement(pageSidebar)}
        {renderPage(true)}
      </div>
    )
  }

  function renderMobileFooter() {
    if (isWebView) return null
    // Users should never see the mobile footer unless they are registered for a community
    if (!activeOrganization?.viewerMembership || !isMobile) return null
    return (
      <HiddenForRoutes exact path={COMMUNITY_NAVIGATION_HIDDEN_ROUTES}>
        <CommunityAppBar
          variant={"horizontal"}
          classes={{ sidebar: classes.communitySwitcherFooter }}
        />
      </HiddenForRoutes>
    )
  }

  function renderHeader() {
    return (
      <HiddenForRoutes exact path={ALL_COMPONENTS_HIDDEN_ROUTES}>
        {/**
         * can't forward a ref to AppHeaderLayout - maybe an issue with underlying portals?
         * Instead pass to a div wrapper
         */}
        <div
          ref={pageBarRef}
          id={"app-page-layout-header"}
          className={classes.pageHeader}
          data-testid={"AppPageLayout.contextual-header"}
        >
          <AppHeaderLayout />
        </div>
      </HiddenForRoutes>
    )
  }
}

export const SIDEBARS_HIDDEN_ROUTES = [
  ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT,
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.PRODUCT.REGISTRATION),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.PRODUCT.EVENTS.REGISTRATION),
  // Stand alone pages
  ROUTE_NAMES.PRODUCT.COMPLETE_APPLICATION,
  ROUTE_NAMES.STRIPE_INTEGRATION,
  ROUTE_NAMES.ZOOM_INTEGRATION,
  ROUTE_NAMES.ZOOM_LANDING,
  ROUTE_NAMES.OUTLOOK_LANDING,
  ROUTE_NAMES.OUTLOOK_INTEGRATION,
  ROUTE_NAMES.COMMUNITY.GUEST.DETAIL,
  ROUTE_NAMES.NOTIFICATION_OPT_OUT,
]
const ALL_COMPONENTS_HIDDEN_ROUTES = [
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.CHECKOUT),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.COMMUNITY.WEBVIEW),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.PRODUCT.WEBVIEW),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.AUTHENTICATION),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.ONBOARDING),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.COMMUNITY.JOIN),
  // LandingPageEditor renders is own AppHeaderLayout in LandingPageEditorHeader
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.COMMUNITY.LANDING_PAGE),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.PRODUCT.LANDING_PAGE),
  // slack integration pages
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_COMMUNITY_SLACK),
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.ADMIN.INTEGRATIONS.SETUP_ADMIN_SLACK),
  ROUTE_NAMES.ADMIN.INTEGRATIONS.SLACK_FINALIZE_CONNECTION,
  // Membership selection pages
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.COMMUNITY.MEMBERSHIPS.ROOT),
  // Public Brain
  ...recursivelyFlatmapRoutes(ROUTE_NAMES.PUBLIC_BRAIN),
  // E2E Preload
  ROUTE_NAMES.E2E_PRELOAD,
]
const COMMUNITY_NAVIGATION_HIDDEN_ROUTES = [
  ...SIDEBARS_HIDDEN_ROUTES,
  ...ALL_COMPONENTS_HIDDEN_ROUTES,
]

export const ONLY_PAGE_HEADER_VISIBLE_ROUTES = recursivelyFlatmapRoutes(
  ROUTE_NAMES
).filter(
  (r) => !ALL_COMPONENTS_HIDDEN_ROUTES.includes(r) && SIDEBARS_HIDDEN_ROUTES.includes(r)
)

function recursivelyFlatmapRoutes(v: string | Record<string, any>): string[] {
  return typeof v === "object" ? Object.values(v).flatMap(recursivelyFlatmapRoutes) : [v]
}

type StyleProps = {
  appBarHeight: number
  pageBarHeight: number
  isOnAdmin: boolean
  isMobile: boolean
  webViewHeader: WebViewHeader | null
  isInLearnMode: boolean
}

const useStyles = makeUseStyles((theme) => ({
  appBarRoot: { zIndex: theme.zIndex.pageHeader },
  ViewAsAppBarRoot: {
    zIndex: theme.zIndex.modal,
  },
  container: ({ isOnAdmin, isMobile }: StyleProps) => ({
    display: "flex",
    alignItems: "flex-start",
    ...styleIf(isOnAdmin && !isMobile, {
      backgroundColor: theme.palette.groovy.neutral[700],
    }),
  }),
  page: ({ appBarHeight, isOnAdmin, isMobile, webViewHeader }: StyleProps) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: 0,
    // necessary to set height on this container so content does not overflow on an element higher up
    // and cause drawer overlay to have full height of page content
    height: `calc(100dvh - ${appBarHeight}px)`,
    overflowX: "hidden",
    overflowY: "auto",

    ...styleIf(isOnAdmin && !isMobile, {
      borderTopLeftRadius: theme.measure.borderRadius.xl,
      borderBottomLeftRadius: theme.measure.borderRadius.xl,
      backgroundColor: theme.palette.groovy.neutral[100],
    }),

    ...styleIf(webViewHeader, {
      paddingTop: webViewHeader?.height,
    }),
  }),
  pageHeader: {
    position: "sticky",
    top: 0,
    zIndex: theme.zIndex.pageHeader,
  },
  fullHeightContentContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: ({ appBarHeight, pageBarHeight }: StyleProps) =>
      `calc(100dvh - ${appBarHeight}px - ${pageBarHeight}px)`,
    flexShrink: 0,
  },
  communitySwitcherFooter: {
    position: "sticky",
    bottom: 0,
    // give the footer same index as page header since it serves the same purpose
    zIndex: theme.zIndex.pageHeader,
  },
  appContainer: {
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: theme.palette.background.nav,
  },
  mainContainer: {
    height: "100%",
    display: "flex",
    overflow: "hidden",
  },
  rightContainer: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 1, 1, 0),
    minWidth: 0,
  },
  globalTabContainer: {
    height: "100%",
    display: "flex",
    borderRadius: theme.measure.borderRadius.large,
    boxShadow: theme.palette.groovyDepths.boxShadow,
    overflow: "hidden",
    border: theme.palette.constants.borderSmall,
    backgroundColor: theme.palette.background.paper,
  },
  pageContainer: ({ isOnAdmin, isInLearnMode }: StyleProps) => ({
    height: `calc(100% - ${isInLearnMode ? 2 : 0}px)`,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: isOnAdmin
      ? theme.palette.groovy.grey[100]
      : theme.palette.background.default,
  }),
  roundedPageContainer: ({ isInLearnMode }: StyleProps) => ({
    borderRadius: theme.measure.borderRadius.large,
    // Use outline instead of border so it merges seamlessly with globalTabContainer's border
    outline: isInLearnMode
      ? `1px solid ${theme.palette.primary.main}`
      : theme.palette.constants.borderSmall,
    ...(isInLearnMode ? { margin: "1px" } : { marginLeft: "1px" }), // 1px margin so the border doesn't get cut off
    boxShadow: "-4px 4px 16px 0px rgba(0, 0, 0, 0.02)",
  }),
  pageContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: 0,
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
  },
}))
