import { useAuthUser } from "@/core/context/AuthUserContext"
import { withDiscoDefaultTheme } from "@/core/context/CustomThemeProvider"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useStopImpersonatingUser } from "@/product/util/hook/useInitImpersonateUser"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import { DiscoButton, DiscoText } from "@disco-ui"
import { Toolbar } from "@material-ui/core"

function ViewAsAppBar() {
  const classes = useStyles()
  const { authUser, isImpersonatingUser } = useAuthUser()
  const stopImpersonatingUser = useStopImpersonatingUser()

  if (!authUser || !isImpersonatingUser) {
    return null
  }

  return (
    <Toolbar
      data-testid={"ImpersonateUserAppBar"}
      classes={{
        root: classes.toolbarRoot,
      }}
    >
      <DiscoText
        color={"common.white"}
        variant={"body-sm-600"}
      >{`Logged in as:`}</DiscoText>

      <ProfileAvatarWithDetails
        testid={"ImpersonateUserAppBar.profile-avatar-with-details"}
        className={classes.profileAvatarWithDetailsContainer}
        userKey={authUser}
        avatarSize={24}
        linkToProfile={false}
        title={
          <DiscoText color={"common.white"} variant={"body-sm-600"}>
            {authUser.fullName}
          </DiscoText>
        }
      />

      <DiscoButton
        testid={"ImpersonateUserAppBar.turn-off-button"}
        color={"grey"}
        onClick={stopImpersonatingUser}
        size={"small"}
      >
        {"Exit/Stop"}
      </DiscoButton>
    </Toolbar>
  )
}

export default withDiscoDefaultTheme(ViewAsAppBar)

const useStyles = makeUseStyles((theme) => ({
  toolbarRoot: {
    display: "flex",
    width: "100%",
    minHeight: "56px",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.main,
  },
  profileAvatarWithDetailsContainer: {
    minWidth: "unset",
    gap: theme.spacing(0.5),
  },
}))
