import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { PurchaseTracking_ValidCheckoutFragment$key } from "@/organization/tracking/lib/__generated__/PurchaseTracking_ValidCheckoutFragment.graphql"
import FacebookPixel from "@/organization/tracking/lib/FacebookPixel"
import GoogleAnalytics from "@/organization/tracking/lib/GoogleAnalytics"
import GoogleTagManager from "@/organization/tracking/lib/GoogleTagManager"
import Relay from "@/relay/relayUtils"
import { useCallback } from "react"
import { graphql, useFragment } from "react-relay"

/**
 * Hook to track a purchase event.
 */
function useTrackPurchase(
  validCheckoutKey: PurchaseTracking_ValidCheckoutFragment$key | null
) {
  const activeOrganization = useActiveOrganization()
  const validCheckout = useFragment<PurchaseTracking_ValidCheckoutFragment$key>(
    graphql`
      fragment PurchaseTracking_ValidCheckoutFragment on ValidCheckout {
        id
        cart
        order {
          id
          totalPrice
          currency
        }
      }
    `,
    validCheckoutKey
  )

  const order = validCheckout?.order
  const value = order?.totalPrice || 0
  const currency = order?.currency || activeOrganization?.currency || "USD"
  const orderId = order ? Relay.rawId(order.id) : ""
  const cart = validCheckout?.cart ? JSON.parse(validCheckout.cart) : null
  const discountCode = cart?.discountCode || ""

  return useCallback(() => {
    if (!value || !currency || !orderId) return
    trackPurchase({ value, currency, orderId, discountCode })
  }, [value, currency, orderId, discountCode])
}

function trackPurchase(args: {
  value: number
  currency: string
  orderId: string
  discountCode: string
}) {
  const { value, currency, orderId, discountCode } = args

  try {
    GoogleTagManager.trackPurchase({
      value,
      currency,
      orderId,
      discountCode,
    })
  } catch {
    // ignore
  }

  try {
    FacebookPixel.trackPurchase({
      value,
      currency,
      orderId,
    })
  } catch {
    // ignore
  }

  try {
    GoogleAnalytics.trackPurchase({
      value,
      currency,
      orderId,
      discountCode,
    })
  } catch {
    // ignore
  }
}

export default useTrackPurchase
