import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DiscoAlert, DiscoDivider, DiscoFormControl, DiscoInput } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function ContinueYourProductsDashboardBlockForm(props: Props) {
  const { form } = props
  const { continueYourProducts } = form.state

  if (!continueYourProducts) return null

  return (
    <>
      <DiscoAlert message={`Only one Jump Back In block can be added.`} />
      <DiscoDivider marginTop={2.5} marginBottom={2} />
      <DiscoFormControl label={"Title"} errorMessages={form.errorsByField.title}>
        <DiscoInput
          data-testid={"ContinueYourProductsDashboardBlockForm.title"}
          value={continueYourProducts.title}
          onChange={(e) => (continueYourProducts.title = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>
    </>
  )
}

export default observer(ContinueYourProductsDashboardBlockForm)
