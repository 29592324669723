import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { UserflowContextActiveOrganizationFragment$key } from "@/core/context/__generated__/UserflowContextActiveOrganizationFragment.graphql"
import { UserflowContextAuthUserFragment$key } from "@/core/context/__generated__/UserflowContextAuthUserFragment.graphql"
import { sendSentryAnException } from "@/core/sentryHandler"
import { OrganizationUtils } from "@/organization/util/OrganizationUtils"
import Relay from "@/relay/relayUtils"
import { isProduction, isStaging } from "@utils/globalVariables"
import { isWebView } from "@utils/webView/webViewUtils"
import React, { useContext, useEffect } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import userflow, { Userflow } from "userflow.js"

const SHOULD_INITIALIZE_USERFLOW = (isStaging() || isProduction()) && !isWebView()

type UserflowContextValue = Userflow | null

const UserflowContext = React.createContext<UserflowContextValue | null>(null)
export function useUserflow() {
  return useContext(UserflowContext)
}

export const UserflowProvider: React.FC = ({ children }) => {
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()
  const permissions = activeOrganization?.viewerPermissions
  const user = useFragment<UserflowContextAuthUserFragment$key>(
    graphql`
      fragment UserflowContextAuthUserFragment on User {
        id
        userflowSignature
      }
    `,
    authUser
  )
  const organization = useFragment<UserflowContextActiveOrganizationFragment$key>(
    graphql`
      fragment UserflowContextActiveOrganizationFragment on Organization {
        id
        userflowSignature
        subscription {
          status
          trialEndDate
        }
        viewerMembership {
          shouldInitiateUserflow
        }
        attributes {
          edges {
            node {
              id
              name
              value
            }
          }
        }
      }
    `,
    activeOrganization
  )
  const product = useActiveProduct()
  const isAdmin =
    product?.viewerIsManagerOrInstructor || activeOrganization?.viewerIsOwnerOrAdmin
  const daysUntilTrialExpires = OrganizationUtils.daysUntilTrialExpired(
    organization?.subscription?.trialEndDate
  )

  useEffect(() => {
    // if auth user changes, re-identify the user with Userflow
    initializeUserflow()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.id])

  const organizationAttributes = Relay.connectionToArray(organization?.attributes)

  return <UserflowContext.Provider value={userflow}>{children}</UserflowContext.Provider>

  function initializeUserflow() {
    // initialize for admins only
    if (
      !SHOULD_INITIALIZE_USERFLOW ||
      !authUser ||
      !user ||
      !organization ||
      !activeOrganization ||
      // only community / product admins see flows
      !isAdmin ||
      // do not initiate if the user has been added via Disco Control
      !organization.viewerMembership?.shouldInitiateUserflow
    )
      return

    userflow.init(USERFLOW_TOKEN)
    userflow
      .identify(
        Relay.rawId(user.id),
        {
          name: authUser.firstName,
          creationDatetime: authUser.creationDatetime || "",
          firstName: authUser.firstName || "",
          isDiscoStaff: authUser.email.includes("@disco.co"),
          hasPlanManagePermission: permissions?.has("plans.manage"),
        },
        {
          signature: user.userflowSignature,
        }
      )
      .catch((err) => {
        if (!(err instanceof Error)) return
        sendSentryAnException(err, {
          extra: {
            title: "userflowIdentifyFailed",
          },
        })
      })

    // groups allow us to categorize users and use this info when building flows
    userflow.group(
      organization.id,
      {
        name: activeOrganization.name,
        slug: activeOrganization.slug,
        rawId: Relay.fromGlobalId(activeOrganization.id).id,
        currency: activeOrganization.currency,
        isDmEnabled: activeOrganization.isDmEnabled,
        isChannelsEnabled: activeOrganization.isChannelsEnabled,
        hasZoomIntegration: activeOrganization.hasZoomIntegration,
        hasStripeIntegration: activeOrganization.hasStripeConnection,
        hasZapierIntegration: activeOrganization.hasZapierIntegration,
        slackConnectionStatus: activeOrganization.slackConnectionStatus,
        visibility: activeOrganization.visibility,
        creationDatetime: activeOrganization.creationDatetime,
        subscriptionStatus: organization.subscription?.status,
        daysUntilTrialExpires,
        signupSource:
          organizationAttributes.find((attr) => attr.name === "signup_source")?.value ||
          "organic",
      },
      {
        signature: organization.userflowSignature,
        membership: {
          role: activeOrganization.viewerIsOwnerOrAdmin
            ? "community_admin"
            : product?.viewerIsManagerOrInstructor
            ? "experience_admin"
            : "member", // never actually reached because of the isAdmin check earlier, but leaving in for the future
        },
      }
    )
  }
}
