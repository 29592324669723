/**
 * @generated SignedSource<<d2700cca713252c33ad0ae837ed89e18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type E2ESignInInput = {
  userId: string;
};
export type E2EPreloadPageMutation$variables = {
  input: E2ESignInInput;
};
export type E2EPreloadPageMutation$data = {
  readonly e2eSignIn: {
    readonly data: boolean | null;
  };
};
export type E2EPreloadPageMutation = {
  variables: E2EPreloadPageMutation$variables;
  response: E2EPreloadPageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "E2ESignInResponse",
    "kind": "LinkedField",
    "name": "e2eSignIn",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "E2EPreloadPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "E2EPreloadPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cb8252c0cf6c700cb784000c06c2a6fd",
    "id": null,
    "metadata": {},
    "name": "E2EPreloadPageMutation",
    "operationKind": "mutation",
    "text": "mutation E2EPreloadPageMutation(\n  $input: E2ESignInInput!\n) {\n  e2eSignIn(input: $input) {\n    data\n  }\n}\n"
  }
};
})();

(node as any).hash = "51bff4b0110b474a6d8aceab9d1959bc";

export default node;
