/**
 * @generated SignedSource<<8044eb6bb72db848d8892f191c479ce8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "onboarding_module" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type ContentUsageStatus = "published" | "draft" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberOnboardingModalFragment$data = {
  readonly id: string;
  readonly content: {
    readonly id: string;
    readonly children: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly ordering: number | null;
          readonly viewerHasCompleted: boolean;
          readonly status: ContentUsageStatus;
          readonly content: {
            readonly id: string;
            readonly systemTaskKind: ContentSystemTaskKind | null;
          };
          readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingContentFragment">;
        };
      }>;
    } | null;
  };
  readonly " $fragmentType": "MemberOnboardingModalFragment";
};
export type MemberOnboardingModalFragment$key = {
  readonly " $data"?: MemberOnboardingModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberOnboardingModalFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Variable",
  "name": "includeDraft",
  "variableName": "includeDraft"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeDraft"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "systemTaskKinds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "content",
          "children"
        ]
      }
    ]
  },
  "name": "MemberOnboardingModalFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "children",
          "args": [
            (v1/*: any*/),
            {
              "kind": "Variable",
              "name": "systemTaskKinds",
              "variableName": "systemTaskKinds"
            }
          ],
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "__MemberOnboardingModal_children_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ordering",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "viewerHasCompleted",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Content",
                      "kind": "LinkedField",
                      "name": "content",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "systemTaskKind",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": [
                        (v1/*: any*/)
                      ],
                      "kind": "FragmentSpread",
                      "name": "MemberOnboardingContentFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfoObjectType",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "e0b6574e7829951f35cd05e146badd15";

export default node;
