import CreateMemberGroupModal from "@/product/common/member-group/common/create/button/CreateMemberGroupModal"
import { CreateMemberGroupButtonFragment$key } from "@/product/common/member-group/common/create/button/__generated__/CreateMemberGroupButtonFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

type CreateMemberGroupButtonProps = {
  groupKey: CreateMemberGroupButtonFragment$key
  isSubGroup?: boolean
  children: OverridableDiscoButtonChildren
  onCreate?: () => void
  selectedMembershipIds?: GlobalID[]
} & OverridableDiscoButtonProps

function CreateMemberGroupButton(props: CreateMemberGroupButtonProps) {
  const {
    children,
    groupKey,
    onCreate,
    isSubGroup = false,
    selectedMembershipIds = [],
    ...rest
  } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const memberGroup = useFragment<CreateMemberGroupButtonFragment$key>(
    graphql`
      fragment CreateMemberGroupButtonFragment on MemberGroup {
        ...CreateMemberGroupModalFragment
      }
    `,
    groupKey
  )

  return (
    <>
      <OverridableDiscoButton onClick={openModal} {...rest}>
        {children}
      </OverridableDiscoButton>
      <CreateMemberGroupModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onCreate={onCreate}
        groupKey={memberGroup}
        isSubGroup={isSubGroup}
        selectedMembershipIds={selectedMembershipIds}
      />
    </>
  )

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

export default CreateMemberGroupButton
