/**
 * @generated SignedSource<<23872ed2f16ef5ceeea82ce0c63251a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentNotificationEmailCtaButton = "enabled" | "disabled" | "%future added value";
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "onboarding_module" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
export type SubmitAssignmentInput = {
  contentUsageId?: string | null;
  users: ReadonlyArray<string>;
  content: CreateContentInput;
};
export type CreateContentInput = {
  id?: string | null;
  attachments?: ReadonlyArray<ContentAttachmentInput> | null;
  name?: string | null;
  description?: string | null;
  coverPhoto?: string | null;
  coverPhotoAssetId?: string | null;
  coverVideo?: string | null;
  coverVideoAssetId?: string | null;
  coverVideoDurationSeconds?: number | null;
  createdById?: string | null;
  notificationEmailSubject?: string | null;
  sendNotificationEmail?: boolean | null;
  showComments?: boolean | null;
  showCoverPlayIcon?: boolean | null;
  coverAudio?: string | null;
  thumbnailUrl?: string | null;
  thumbnailAssetId?: string | null;
  richEditorDescriptionContent?: string | null;
  richEditorNotificationEmailBodyContent?: string | null;
  releaseDatetime?: string | null;
  notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null;
  systemTaskKind?: ContentSystemTaskKind | null;
  contentLabelId?: string | null;
  webFormRevision?: CreateWebFormRevisionInput | null;
  submissionLink?: string | null;
  isAiGenerated?: boolean | null;
};
export type ContentAttachmentInput = {
  id?: string | null;
  name?: string | null;
  mediaUrl?: string | null;
  mediaAssetId?: string | null;
};
export type CreateWebFormRevisionInput = {
  webFormId: string;
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
};
export type WebFormQuestionInput = {
  type: WebFormQuestionType;
  richEditorBody: string;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type SubmitAssignmentFormMutation$variables = {
  input: SubmitAssignmentInput;
  connections: ReadonlyArray<string>;
};
export type SubmitAssignmentFormMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly contentUsage: {
        readonly id: string;
        readonly assignmentSubmissions: {
          readonly totalCount: number;
        };
        readonly viewerSubmission: {
          readonly id: string;
          readonly createdAt: string;
          readonly submissionUsers: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly productMembershipId: string;
                readonly user: {
                  readonly id: string;
                };
              };
            }>;
          };
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"ContentUsageUtils_ContentCompletionFragment">;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type SubmitAssignmentFormMutation = {
  variables: SubmitAssignmentFormMutation$variables;
  response: SubmitAssignmentFormMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentSubmissionNodeConnection",
  "kind": "LinkedField",
  "name": "assignmentSubmissions",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentSubmission",
  "kind": "LinkedField",
  "name": "viewerSubmission",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmissionUserNodeConnection",
      "kind": "LinkedField",
      "name": "submissionUsers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionUserNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssignmentSubmissionUser",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productMembershipId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerHasCompleted",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLocked",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContentUsageNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentUsage",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitAssignmentFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "SubmitAssignmentResponse",
        "kind": "LinkedField",
        "name": "submitAssignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsage",
                "kind": "LinkedField",
                "name": "contentUsage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContentUsageUtils_ContentCompletionFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SubmitAssignmentFormMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "SubmitAssignmentResponse",
        "kind": "LinkedField",
        "name": "submitAssignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignmentSubmission",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentUsage",
                "kind": "LinkedField",
                "name": "contentUsage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentUsageNodeConnection",
                    "kind": "LinkedField",
                    "name": "dependents",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentUsageNodeConnection",
                        "kind": "LinkedField",
                        "name": "usages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentUsageNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentUsage",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ContentUsageNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "dependents",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ContentUsageNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ContentUsage",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v10/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Content",
                                                "kind": "LinkedField",
                                                "name": "content",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ContentUsageNodeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "children",
                                                    "plural": false,
                                                    "selections": (v11/*: any*/),
                                                    "storageKey": null
                                                  },
                                                  (v3/*: any*/)
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v9/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductMembershipNodeConnection",
                                    "kind": "LinkedField",
                                    "name": "completedProductMemberships",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProductMembershipNodeEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProductMembership",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "member",
                                                "plural": false,
                                                "selections": [
                                                  (v3/*: any*/),
                                                  {
                                                    "alias": "first_name",
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "firstName",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": "last_name",
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "lastName",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "avatar",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContentCompletionNodeConnection",
                        "kind": "LinkedField",
                        "name": "viewerChildContentCompletions",
                        "plural": false,
                        "selections": (v5/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeConnection",
                    "kind": "LinkedField",
                    "name": "completedProductMemberships",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "node",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AssignmentSubmissionNodeConnection"
              }
            ]
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c0b505dbe5c257dea89694b499be772",
    "id": null,
    "metadata": {},
    "name": "SubmitAssignmentFormMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitAssignmentFormMutation(\n  $input: SubmitAssignmentInput!\n) {\n  response: submitAssignment(input: $input) {\n    node {\n      id\n      contentUsage {\n        id\n        assignmentSubmissions {\n          totalCount\n        }\n        viewerSubmission {\n          id\n          createdAt\n          submissionUsers {\n            edges {\n              node {\n                id\n                productMembershipId\n                user {\n                  id\n                }\n              }\n            }\n          }\n        }\n        ...ContentUsageUtils_ContentCompletionFragment\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment ContentUsageUtils_ContentCompletionFragment on ContentUsage {\n  id\n  viewerHasCompleted\n  dependents {\n    edges {\n      node {\n        id\n        isLocked\n      }\n    }\n  }\n  module {\n    id\n    usages {\n      edges {\n        node {\n          id\n          dependents {\n            edges {\n              node {\n                id\n                isLocked\n                content {\n                  children {\n                    edges {\n                      node {\n                        id\n                        isLocked\n                      }\n                    }\n                  }\n                  id\n                }\n              }\n            }\n          }\n          viewerHasCompleted\n          completedProductMemberships {\n            totalCount\n            edges {\n              node {\n                id\n                member {\n                  id\n                  first_name: firstName\n                  last_name: lastName\n                  avatar\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    viewerChildContentCompletions {\n      totalCount\n    }\n  }\n  completedProductMemberships {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "df9c5aab06d0b3d6a34ead5fbab61a25";

export default node;
