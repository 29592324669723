import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { GlobalDrawerParams } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import {
  useExperienceSettingsSelectedPlans_membershipPlansFragment$data,
  useExperienceSettingsSelectedPlans_membershipPlansFragment$key,
} from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import useExperienceSettingsSelectedPlans from "@/product/settings/hooks/useExperienceSettingsSelectedPlans"
import MembershipPlanExperiencePricingListItem from "@/product/settings/pricing/MembershipPlanExperiencePricingListItem"
import { NodeFromConnection } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoLink, DiscoSection, DiscoSwitch, DiscoText } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams } from "@utils/url/urlUtils"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { Fragment, useRef } from "react"

type Plan = NodeFromConnection<
  NonNullable<useExperienceSettingsSelectedPlans_membershipPlansFragment$data>["membershipPlans"]
>

interface Props extends TestIDProps {
  form: FormStore<
    CreateExperienceFormState | ExperienceSettingsFormState,
    ExperienceSettingsFormMutation
  >
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
}

function ExperienceSettingsMembershipPlansFormFields({ form, organizationKey }: Props) {
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const experienceLabel = useLabel("admin_experience")

  const { hasCustomPlans, availableMembershipPlans } = useExperienceSettingsSelectedPlans(
    {
      organizationKey,
      benefits: form.state.benefits,
    }
  )

  const existingBenefits = form.state.benefits
  const existingBenefitsRef = useRef(existingBenefits)

  if (!activeProduct) return null
  if (!hasCustomPlans) return null

  return (
    <DiscoSection className={classes.container} padding={0}>
      <div className={classes.content}>
        <ExperienceSettingsLabel
          title={"Available Membership Plans"}
          sectionId={"available_memberships"}
        />
        <DiscoText variant={"body-sm"} color={"text.secondary"} display={"inline"}>
          {`This ${experienceLabel.singular} is available through the following membership plans. `}

          <DiscoLink
            to={{
              search: setSearchParams<GlobalDrawerParams<"experienceSettings">>(
                location.search,
                {
                  drawerExperienceId: activeProduct.id,
                  experienceSettingsTab: "pricing",
                }
              ),
            }}
          >
            <DiscoText variant={"body-sm-700"} display={"inline"} color={"primary.main"}>
              {"Manage Memberships"}
            </DiscoText>
          </DiscoLink>
        </DiscoText>
      </div>

      {availableMembershipPlans.map((plan) => (
        <Fragment key={plan.id}>
          <DiscoDivider marginTop={0} marginBottom={0} />
          <MembershipPlanExperiencePricingListItem
            testid={`ExperienceSettingsMembershipPlansFormFields.plan.${plan.name}`}
            form={form}
            membershipPlanKey={plan}
            cta={
              <DiscoSwitch
                testid={`ExperienceSettingsMembershipPlansFormFields.plan.${plan.name}.switch`}
                checked={form.state.benefits.some((b) => b.membershipPlanId === plan.id)}
                onChange={() => handleTogglePlan(plan)}
              />
            }
          />
        </Fragment>
      ))}
    </DiscoSection>
  )

  function handleTogglePlan(plan: Plan) {
    const existingBenefit = existingBenefitsRef.current.find(
      (b) => b.membershipPlanId === plan.id
    )
    const isToggleOn = form.state.benefits.some((b) => b.membershipPlanId === plan.id)

    // If toggling off, remove the benefit
    if (isToggleOn) {
      form.state.benefits = observable.array(
        form.state.benefits.filter((b) => b.membershipPlanId !== plan.id)
      )
    }

    // If toggling on, add the benefit
    else if (existingBenefit) {
      // If benefit already exists, add it back
      form.state.benefits.push(existingBenefit)
    }

    // Create benefit with null ID so the resolver knows to create one
    else {
      form.state.benefits.push({
        id: null,
        membershipPlanId: plan.id,
        pricing: {
          amountCents: 0,
          kind: "free",
          frequency: null,
        },
      })
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    margin: theme.spacing(1.5, 0, 2, 0),
    display: "flex",
    flexDirection: "column",
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
  },
}))

export default observer(ExperienceSettingsMembershipPlansFormFields)
