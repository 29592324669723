import {
  AIReferencesListEmbeddingSourceSelect_PaginationQuery,
  EmbeddingSourceEntity,
} from "@/content/ai/__generated__/AIReferencesListEmbeddingSourceSelect_PaginationQuery.graphql"
import ContentThumbnailWithDetails from "@/content/detail/ContentThumbnailWithDetails"
import { ContentThumbnailWithDetails_ContentFragment$key } from "@/content/detail/__generated__/ContentThumbnailWithDetails_ContentFragment.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import MediaListItem from "@/media/add/list/MediaListItem"
import { MediaListItemFragment$key } from "@/media/add/list/__generated__/MediaListItemFragment.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import AIChip from "@components/ai/AIChip"
import { DiscoInputSkeleton } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { AutocompleteGetTagProps } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import { useEffect, useMemo, useRef } from "react"
import { graphql } from "react-relay"
import { FragmentRefs } from "relay-runtime"

type Props = TestIDProps & {
  entity: EmbeddingSourceEntity
  values: GlobalID[]
  onSelect: (contentIds: GlobalID[]) => void
  placeholder?: string
  limit?: number
  onOptionsLoaded?: (options: any[]) => void
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
}

function AIReferencesListEmbeddingSourceSelect(props: Props) {
  const {
    testid = "AIReferencesListEmbeddingSourceSelect",
    entity,
    values,
    onSelect,
    onOptionsLoaded,
    isOpen,
    onOpen,
    onClose,
  } = props

  const theme = useTheme()
  const activeOrganization = useActiveOrganization()!

  const { data, pagination } =
    Relay.useRefetchablePaginationQuery<AIReferencesListEmbeddingSourceSelect_PaginationQuery>(
      graphql`
        query AIReferencesListEmbeddingSourceSelect_PaginationQuery(
          $id: ID!
          $first: Int!
          $after: String
          $search: String
          $entity: EmbeddingSourceEntity!
        ) {
          node(id: $id) {
            ... on Organization {
              embeddingSources(
                first: $first
                after: $after
                search: $search
                entity: $entity
              )
                @connection(
                  key: "AIReferencesListEmbeddingSourceSelect_embeddingSources"
                ) {
                edges {
                  node {
                    id
                    entity
                    asset {
                      id
                      name
                      ...MediaListItemFragment
                    }
                    content {
                      id
                      name
                      isAiGenerated
                      ...ContentThumbnailWithDetails_ContentFragment
                    }
                    trainingData {
                      id
                      name
                    }
                  }
                }
                pageInfo {
                  hasNextPage
                  hasPreviousPage
                  startCursor
                  endCursor
                }
              }
            }
          }
        }
      `,
      { id: activeOrganization.id, entity },
      { connectionName: "node.embeddingSources" }
    )

  const embeddingSources = Relay.connectionToArray(data?.node?.embeddingSources)

  const optionsMap = useMemo(() => {
    const map: Record<EmbeddingSourceEntity, any> = {
      asset: [],
      content: [],
      training_data: [],
      "%future added value": null,
    }

    embeddingSources.forEach((es) => {
      map[es.entity].push(es)
    })

    return map
  }, [embeddingSources])

  const options: {
    id: GlobalID
    entity: EmbeddingSourceEntity
    asset?: {
      name: string
      " $fragmentSpreads"?: FragmentRefs<"MediaListItemFragment">
    }
    content?: {
      name: string
      isAiGenerated: boolean
      " $fragmentSpreads"?: FragmentRefs<"ContentThumbnailWithDetails_ContentFragment">
    }
    trainingData?: {
      name: string
    }
  }[] = optionsMap[entity]

  const totalOptionsLoadedInitially = useRef<number | null>(null)

  useEffect(() => {
    if (totalOptionsLoadedInitially.current !== null) return // Only let this run the first time the component renders and then loads options
    if (!data?.node) return

    onOptionsLoaded?.(options)
    totalOptionsLoadedInitially.current = options.length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.node])

  if (!totalOptionsLoadedInitially) return null

  return (
    <DiscoMultiSelect
      testid={`${testid}.select`}
      placeholder={`Select ${
        entity === "asset" ? "Asset" : entity === "content" ? "Content" : "Training Data"
      }`}
      values={values}
      options={options.map((o) => {
        return {
          ...(o.entity === "asset"
            ? o.asset
            : o.entity === "content"
            ? o.content
            : o.trainingData),
          value: o.id,
          title:
            o.entity === "asset"
              ? o.asset!.name
              : o.entity === "content"
              ? o.content!.name
              : o.trainingData!.name,
        }
      })}
      renderOption={(option) => {
        return entity === "asset" ? (
          <MediaListItem
            mediaItemKey={option as unknown as MediaListItemFragment$key}
            testid={`${testid}.${option.title}`}
            viewType={"list"}
          />
        ) : entity === "content" ? (
          <DiscoDropdownItem
            title={
              <ContentThumbnailWithDetails
                testid={`${testid}.${option.title}`}
                contentKey={
                  option as unknown as ContentThumbnailWithDetails_ContentFragment$key
                }
                truncateText={1}
                textAdornment={
                  (option as unknown as { isAiGenerated: boolean }).isAiGenerated ? (
                    <AIChip testid={`${testid}.AI-generation`} name={"AI-Generation"} />
                  ) : null
                }
              />
            }
          />
        ) : entity === "training_data" ? (
          <DiscoDropdownItem title={option.title} />
        ) : null
      }}
      renderTags={renderTags}
      onChange={(ids) => onSelect(ids)}
      pagination={pagination}
      isOpen={isOpen}
      autoFocusInput
      handleOpen={onOpen}
      handleClose={onClose}
    />
  )

  function renderTags(
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) {
    return value.map((o, index) => (
      <DiscoTag
        key={o.value}
        {...getTagProps({ index })}
        testid={`${testid}.option.remove.${o.title}`}
        name={o.title}
        backgroundColor={theme.palette.common.white}
      />
    ))
  }
}

function AIReferencesListEmbeddingSourceSelectSkeleton() {
  return <DiscoInputSkeleton />
}

export default Relay.withSkeleton({
  component: AIReferencesListEmbeddingSourceSelect,
  skeleton: AIReferencesListEmbeddingSourceSelectSkeleton,
})
