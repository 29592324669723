import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { ArrayElement } from "@/core/context/NotificationsContext"
import { ProductType } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import {
  ProductMultiSelectQuery,
  ProductMultiSelectQuery$data,
  ProductRegistrationAvailability,
} from "@/experience/components/__generated__/ProductMultiSelectQuery.graphql"
import ProductDropdownItem from "@/product/common/ProductDropdownItem"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { DiscoInputSkeleton } from "@disco-ui"
import DiscoMultiSelect, {
  DiscoMultiSelectOption,
} from "@disco-ui/select/DiscoMultiSelect"
import DiscoTag from "@disco-ui/tag/DiscoTag"
import { useTheme } from "@material-ui/core"
import { AutocompleteGetTagProps } from "@material-ui/lab"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { TestIDProps } from "@utils/typeUtils"
import { graphql } from "relay-runtime"

export type ProductSelectionData = ArrayElement<
  NonNullable<
    NonNullable<
      ProductMultiSelectQuery$data["node"] & { __typename: "Organization" }
    >["products"]
  >["edges"]
>["node"]

interface Props extends TestIDProps {
  values: GlobalID[]
  onChange: (productIds: GlobalID[], productKeys: ProductSelectionData[]) => void
  disabled?: boolean
  type?: ProductType
  includeAvailabilities?: ProductRegistrationAvailability[]
  hideTags?: boolean
  hideNonPublic?: boolean
  hideAutoJoin?: boolean
  placeholder?: string
  forMembershipPlanId?: GlobalID
}

function ProductMultiSelect(props: Props) {
  const {
    values,
    onChange,
    type,
    disabled,
    testid = "ProductMultiSelect",
    hideTags = false,
    hideNonPublic = true,
    hideAutoJoin = false,
    includeAvailabilities,
    placeholder,
    forMembershipPlanId,
  } = props
  const activeOrganization = useActiveOrganization()!
  const theme = useTheme()
  const productLabel = useAdminProductLabel(type ?? "course")

  const [{ node }] = Relay.useRefetchableQuery<ProductMultiSelectQuery>(
    graphql`
      query ProductMultiSelectQuery(
        $id: ID!
        $hideNonPublic: Boolean!
        $type: String
        $forMembershipPlanId: ID
      ) {
        node(id: $id) {
          ... on Organization {
            __typename
            id
            products(
              hideNonPublic: $hideNonPublic
              type: $type
              forMembershipPlanId: $forMembershipPlanId
            ) {
              edges {
                node {
                  id
                  ...ProductMultiSelect_ProductFragment @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
      hideNonPublic,
      type: type || null,
      forMembershipPlanId,
    },
    { fetchPolicy: "store-and-network" }
  )

  const organization = Relay.narrowNodeType(node, "Organization")
  if (!organization) return null

  const products = Relay.connectionToArray(organization.products).filter((p) => {
    if (includeAvailabilities)
      return includeAvailabilities.includes(p.registrationAvailability)
    if (hideAutoJoin) return !p.isAutoJoined
    return true
  })
  const productsById = ArrayUtils.mapBy(products, "id")

  return (
    <DiscoMultiSelect
      testid={testid}
      placeholder={placeholder || productLabel.plural}
      values={values}
      options={products.map((p) => ({
        value: p.id,
        title: p.name,
        color: theme.palette.groovy.grey[300],
        searchable: [p.name, p.slug],
      }))}
      onChange={handleSelectedProductsChange}
      disabled={disabled}
      renderOption={renderOption}
      // Don't render any selected tags in the input
      renderTags={hideTags ? () => null : renderTags}
    />
  )

  function handleSelectedProductsChange(productIds: GlobalID[]) {
    onChange(
      // Still separately pass the global IDs for convenience
      productIds,
      // Pass the product data in the callback so we can render the option
      // outside of the component
      productIds.filter((id) => productsById[id]).map((id) => productsById[id])
    )
  }

  function renderOption(option: DiscoMultiSelectOption) {
    const product = productsById[option.value]

    return (
      <ProductDropdownItem
        testid={`${testid}.option.${option.title}`}
        productKey={product}
      />
    )
  }

  function renderTags(
    value: DiscoMultiSelectOption[],
    getTagProps: AutocompleteGetTagProps
  ) {
    return value.map((o, index) => (
      <DiscoTag
        key={o.value}
        {...getTagProps({ index })}
        testid={`${testid}.option.remove.${o.title}`}
        name={o.title}
        backgroundColor={theme.palette.common.white}
      />
    ))
  }
}

export default Relay.withSkeleton<Props>({
  component: ProductMultiSelect,
  skeleton: () => <DiscoInputSkeleton />,
})

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment ProductMultiSelect_ProductFragment on Product {
    id
    name
    slug
    registrationAvailability
    isAutoJoined
    ...ProductDropdownItemFragment
    ...ProductWithDetailsFragment
    ...MembershipPlanExperienceDetailsFragment
  }
`
