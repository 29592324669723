import ROUTE_NAMES from "@/core/route/util/routeNames"
import CancelMembershipPlanButton from "@/membership-plan/cancel/button/CancelMembershipPlanButton"
import MembershipPlanSelectButton from "@/membership-plan/register/MembershipPlanSelectButton"
import RemoveCommunityMemberButton from "@/product/member/admin/list/card/more-actions/remove-community-member/RemoveCommunityMemberButton"
import Relay from "@/relay/relayUtils"
import IconWithContent from "@/user/settings/common/icon-with-content/IconWithContent"
import { useMemberProfileContext } from "@/user/settings/context/MemberProfileContext"
import { ProfileSettingsAccountTabFragment$key } from "@/user/settings/subtabs/account/__generated__/ProfileSettingsAccountTabFragment.graphql"
import { ProfileSettingsAccountTabMutation } from "@/user/settings/subtabs/account/__generated__/ProfileSettingsAccountTabMutation.graphql"
import { ProfileSettingsAccountTab_OpenCustomerPortalMutation } from "@/user/settings/subtabs/account/__generated__/ProfileSettingsAccountTab_OpenCustomerPortalMutation.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  DiscoButton,
  DiscoLink,
  DiscoSection,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { useFragment } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props {
  userKey: ProfileSettingsAccountTabFragment$key
}

function ProfileSettingsAccountTab(props: Props) {
  const { userKey } = props
  const { profile } = useMemberProfileContext()!
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()
  const viewerMembershipPlan =
    profile.organizationMembership?.organization.viewerMembershipPlan

  const classes = useStyles()

  const user = useFragment<ProfileSettingsAccountTabFragment$key>(
    graphql`
      fragment ProfileSettingsAccountTabFragment on User
      @argumentDefinitions(organizationId: { type: "ID!" }) {
        email
        hasSocialAuth
        canChangeEmail
        organizationMembership(organizationId: $organizationId) {
          ...RemoveCommunityMemberButtonFragment
          stripeConnectedCustomerId
          organization {
            stripeAccountId
            checkoutVersion
            stripePortalConfigId
            plans: products(type: "membership_plan", hideDrafts: true) {
              totalCount
            }
          }
        }
      }
    `,
    userKey
  )

  const organization = user.organizationMembership?.organization
  const plansCount = user.organizationMembership?.organization?.plans.totalCount || 0
  const changePasswordMutation =
    Relay.useAsyncMutation<ProfileSettingsAccountTabMutation>(
      graphql`
        mutation ProfileSettingsAccountTabMutation($input: ForgotPasswordInput!) {
          response: forgotPassword(input: $input) {
            errors {
              field
              message
            }
          }
        }
      `
    )

  const openCustomerPortalMutation =
    Relay.useAsyncMutation<ProfileSettingsAccountTab_OpenCustomerPortalMutation>(
      graphql`
        mutation ProfileSettingsAccountTab_OpenCustomerPortalMutation(
          $input: CreateStripeCustomerPortalSessionInput!
        ) {
          response: createStripeCustomerPortalSession(input: $input) {
            url
            errors {
              field
              message
            }
          }
        }
      `
    )

  return (
    <DiscoSection padding={0} className={classes.container}>
      <DiscoText variant={"body-md-600"} marginBottom={1.5}>
        {"Account Settings"}
      </DiscoText>
      <IconWithContent
        icon={"email"}
        content={
          <div>
            <DiscoText
              testid={"ProfileSettingsAccountTab.email"}
              color={"text.secondary"}
              marginBottom={1}
            >
              {user.email}
            </DiscoText>

            {user.canChangeEmail ? (
              user.hasSocialAuth ? (
                <>
                  <DiscoLink
                    data-testid={"ProfileSettingsAccountTab.change-email.button"}
                    onClick={openModal}
                  >
                    {"Change Email"}
                  </DiscoLink>

                  <DiscoWarningModal
                    testid={"ProfileSettingsAccountTab.change-email-warning-modal"}
                    variant={"primary"}
                    icon={"user"}
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    title={`Change email address?`}
                    confirmationButtonProps={{
                      onClick: () =>
                        history.push(ROUTE_NAMES.AUTHENTICATION.NEW_EMAIL_CONFIRMATION),
                      children: `Yes`,
                    }}
                    description={
                      `Are you sure you want to change your email address? ` +
                      `This account uses social auth and changing your email address will disconnect your Disco account from your social auth provider.`
                    }
                    modalContentLabel={`Change email dialog`}
                  />
                </>
              ) : (
                <DiscoLink
                  data-testid={"ProfileSettingsAccountTab.change-email.button"}
                  to={ROUTE_NAMES.AUTHENTICATION.NEW_EMAIL_CONFIRMATION}
                >
                  {"Change Email"}
                </DiscoLink>
              )
            ) : (
              <div className={classes.tooltipContainer}>
                <DiscoText
                  fontStyle={"italic"}
                  variant={"body-sm"}
                  color={"text.secondary"}
                >
                  {"*Cannot change email address"}
                </DiscoText>
                <DiscoTooltip
                  content={
                    "Your email address is used for Single Sign On authentication in a community. Email update is disabled."
                  }
                  placement={"bottom"}
                />
              </div>
            )}
          </div>
        }
      />

      {user.hasSocialAuth && (
        <IconWithContent
          icon={"lock"}
          content={
            <div>
              <DiscoText color={"text.secondary"} marginBottom={1}>
                {"Logged in via Social Auth"}
              </DiscoText>
            </div>
          }
        />
      )}
      <IconWithContent
        icon={"lock"}
        content={
          <div>
            <DiscoText color={"text.secondary"} marginBottom={1}>
              {"Password"}
            </DiscoText>
            <DiscoLink
              data-testid={"ProfileSettingsAccountTab.update-password.button"}
              onClick={() => handleUpdatePasswordClick()}
            >
              {"Update Password"}
            </DiscoLink>
          </div>
        }
      />

      {organization?.stripeAccountId && organization.checkoutVersion === "stripe" && (
        <IconWithContent
          icon={"credit-card"}
          content={
            <div>
              <DiscoText color={"text.secondary"} marginBottom={1}>
                {"Payment Method"}
              </DiscoText>
              <DiscoLink
                data-testid={"ProfileSettingsAccountTab.update-payment-method.button"}
                to={ROUTE_NAMES.PAYMENT.UPDATE}
              >
                {"Update Payment Method"}
              </DiscoLink>
            </div>
          }
        />
      )}

      {organization?.stripeAccountId &&
        user.organizationMembership?.stripeConnectedCustomerId &&
        organization.stripePortalConfigId &&
        organization.checkoutVersion === "stripe_acacia" && (
          <IconWithContent
            icon={"credit-card"}
            content={
              <div>
                <DiscoText color={"text.secondary"} marginBottom={1}>
                  {"Billing"}
                </DiscoText>
                <DiscoLink
                  data-testid={"ProfileSettingsAccountTab.billing.button"}
                  onClick={openCustomerPortal}
                >
                  {"View Billing Portal"}
                </DiscoLink>
              </div>
            }
          />
        )}

      {plansCount > 1 && (
        <IconWithContent
          icon={"rocket"}
          content={
            <div>
              <DiscoText
                color={"text.secondary"}
                marginBottom={1}
                testid={"ProfileSettingsAccountTab.plan-name"}
              >
                {viewerMembershipPlan?.name || "No Membership Plan"}
              </DiscoText>

              <div className={classes.planButtons}>
                <MembershipPlanSelectButton>
                  {(buttonProps) => (
                    <DiscoButton
                      {...buttonProps}
                      color={"grey"}
                      variant={"outlined"}
                      testid={"ProfileSettingsAccountTab.change-membership-plan.button"}
                    >
                      {"Change Plan"}
                    </DiscoButton>
                  )}
                </MembershipPlanSelectButton>

                <CancelMembershipPlanButton membershipPlanKey={viewerMembershipPlan!}>
                  {(buttonProps) => (
                    <DiscoButton
                      data-testid={
                        "ProfileSettingsAccountTab.cancel-membership-plan.button"
                      }
                      disabled={!viewerMembershipPlan}
                      {...buttonProps}
                      color={"error"}
                    >
                      {"Cancel Plan"}
                    </DiscoButton>
                  )}
                </CancelMembershipPlanButton>
              </div>
            </div>
          }
        />
      )}

      <DiscoText variant={"body-md-600"} marginTop={4} marginBottom={1.5}>
        {"Community"}
      </DiscoText>
      {user.organizationMembership && (
        <RemoveCommunityMemberButton
          organizationMembershipKey={user.organizationMembership}
        >
          {(buttonProps) => (
            <DiscoButton
              {...buttonProps}
              color={"error"}
              testid={`ProfileSettingsAccountTab.leave-community-button`}
            >
              {"Leave Community"}
            </DiscoButton>
          )}
        </RemoveCommunityMemberButton>
      )}
    </DiscoSection>
  )

  async function openCustomerPortal() {
    const { response } = await openCustomerPortalMutation({
      input: {
        returnUrl: window.location.href,
      },
    })

    if (response.errors?.length || !response.url) {
      return displayErrorToast(
        new Error(`Failed to open customer portal, please try again later.`)
      )
    }

    window.open(response.url, "_blank")
  }

  async function handleUpdatePasswordClick() {
    const { response } = await changePasswordMutation({
      input: {
        email: user.email,
      },
    })

    if (response.errors) {
      displayErrorToast(
        new Error(`Failed to initiate password change, please try again later.`)
      )
      return
    }
    displaySuccessToast({
      message: `Password change email sent to ${user.email!}`,
      testid: "ProfileSettingsAccountTabPasswordChange.confetti-emoji",
    })
  }

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "flex-start",
  },
  planButtons: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(1),
  },
  tooltipContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5), // 4px in theme spacing
  },
}))

export const ProfileSettingsAccountTabSkeleton: React.FC = () => (
  <>
    <DiscoTextSkeleton width={"30%"} />
    <DiscoTextSkeleton width={"40%"} />
    <DiscoTextSkeleton width={"30%"} />
    <DiscoTextSkeleton width={"40%"} />
    <DiscoTextSkeleton width={"30%"} />
  </>
)

export default Relay.withSkeleton<Props>({
  component: ProfileSettingsAccountTab,
  skeleton: ProfileSettingsAccountTabSkeleton,
})
