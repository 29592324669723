/**
 * @generated SignedSource<<ae3354847efea017218bae52eed38be8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OnboardingChecklistItemKind = "create_community" | "customize_branding_and_appearance" | "write_description" | "introduction_to_learning_products" | "create_course" | "introduction_to_community_home" | "write_welcome_post" | "introduction_to_member_management" | "create_group" | "add_custom_profile_fields" | "create_automation" | "invite_admins" | "customize_member_onboarding" | "download_mobile_app" | "set_up_community_public_page" | "invite_members" | "%future added value";
export type ReportingOnboardingChecklistActivityActionSource = "manual" | "automatic" | "%future added value";
export type CompleteOrganizationOnboardingChecklistItemInput = {
  organizationOnboardingChecklistItemId?: string | null;
  kind?: OnboardingChecklistItemKind | null;
  actionSource: ReportingOnboardingChecklistActivityActionSource;
};
export type OnboardingChecklistContextCompleteItemMutation$variables = {
  input: CompleteOrganizationOnboardingChecklistItemInput;
};
export type OnboardingChecklistContextCompleteItemMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly completedAt: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"AdminOnboardingChecklistItemTemplateFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type OnboardingChecklistContextCompleteItemMutation = {
  variables: OnboardingChecklistContextCompleteItemMutation$variables;
  response: OnboardingChecklistContextCompleteItemMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingChecklistContextCompleteItemMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CompleteOrganizationOnboardingChecklistItemResponse",
        "kind": "LinkedField",
        "name": "completeOrganizationOnboardingChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationOnboardingChecklistItem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdminOnboardingChecklistItemTemplateFragment"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingChecklistContextCompleteItemMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "CompleteOrganizationOnboardingChecklistItemResponse",
        "kind": "LinkedField",
        "name": "completeOrganizationOnboardingChecklistItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationOnboardingChecklistItem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OnboardingChecklistItem",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "helpArticle",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "364ec6913a4caced0bddf8be3429cb18",
    "id": null,
    "metadata": {},
    "name": "OnboardingChecklistContextCompleteItemMutation",
    "operationKind": "mutation",
    "text": "mutation OnboardingChecklistContextCompleteItemMutation(\n  $input: CompleteOrganizationOnboardingChecklistItemInput!\n) {\n  response: completeOrganizationOnboardingChecklistItem(input: $input) {\n    node {\n      id\n      completedAt\n      ...AdminOnboardingChecklistItemTemplateFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment AdminOnboardingChecklistItemTemplateFragment on OrganizationOnboardingChecklistItem {\n  id\n  completedAt\n  content {\n    kind\n    name\n    description\n    helpArticle\n    id\n  }\n  ...OnboardingChecklistItemCTAButtonFragment\n  ...OnboardingChecklistItemImageFragment\n}\n\nfragment OnboardingChecklistItemCTAButtonFragment on OrganizationOnboardingChecklistItem {\n  completedAt\n  content {\n    kind\n    id\n  }\n  organization {\n    id\n  }\n}\n\nfragment OnboardingChecklistItemImageFragment on OrganizationOnboardingChecklistItem {\n  content {\n    kind\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4b3875029e910d6cfbbcbbd63ab4a674";

export default node;
