import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import DiscoTooltip, { DiscoTooltipProps } from "@disco-ui/tooltip/DiscoTooltip"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { MouseEventHandler, useRef } from "react"

export type DiscoContainerButtonProps = TestIDProps & {
  children?: React.ReactNode
  onClick?: MouseEventHandler
  onMouseDown?: MouseEventHandler
  onMouseUp?: MouseEventHandler
  className?: string
  tooltipWapperClassName?: string
  tabIndex?: number
  disabled?: boolean
  style?: React.CSSProperties
  tooltip?: React.ReactNode
  tooltipProps?: Partial<DiscoTooltipProps>
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

export default function DiscoContainerButton(props: DiscoContainerButtonProps) {
  const {
    children,
    onClick,
    onMouseDown,
    onMouseUp,
    onKeyDown,
    className,
    tooltipWapperClassName,
    tabIndex = 0,
    testid = "DiscoContainerButton",
    disabled = false,
    tooltip,
    tooltipProps,
    style,
  } = props
  const classes = useStyles({ disabled })
  // Hold a ref to the button so we simulate a click on it when the user presses enter or space
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const content = (
    <div
      ref={buttonRef}
      className={classNames(classes.root, className)}
      data-testid={testid}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onKeyDown={handleKeyDown}
      style={style}
      role={"button"}
      tabIndex={tabIndex}
      aria-disabled={disabled}
    >
      {children}
    </div>
  )

  if (tooltip) {
    return (
      <DiscoTooltip content={tooltip} {...tooltipProps}>
        {/** To allow the tooltip to show even if the button is disabled */}
        <span className={tooltipWapperClassName}>{content}</span>
      </DiscoTooltip>
    )
  }

  return content

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    onKeyDown?.(e)
    const isFocused = document.activeElement === buttonRef.current
    if (!isFocused || !onClick || (e.key !== "Enter" && e.key !== " ")) return
    buttonRef.current?.click()
  }
}

type StyleProps = {
  disabled: boolean
}

const useStyles = makeUseStyles({
  root: (props: StyleProps) => ({
    cursor: "pointer",
    userSelect: "none",

    ...styleIf(props.disabled, {
      pointerEvents: "none",
      cursor: "not-allowed",
      userSelect: "none",
      opacity: 0.4,
    }),
  }),
})
