/**
 * @generated SignedSource<<3917c5ef3d6d2d7eea912b09eba52a9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateMemberGroupButtonFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupModalFragment">;
  readonly " $fragmentType": "CreateMemberGroupButtonFragment";
};
export type CreateMemberGroupButtonFragment$key = {
  readonly " $data"?: CreateMemberGroupButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateMemberGroupButtonFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateMemberGroupModalFragment"
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};

(node as any).hash = "73fdaed22bafd0eecbb8f8a61ea1575b";

export default node;
