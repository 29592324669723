/**
 * @generated SignedSource<<cf4e44103618c8e581afbb16133fc289>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DashboardBlockContentMode = "preview" | "full" | "%future added value";
export type DashboardBlockCurriculumView = "card" | "list" | "%future added value";
export type DashboardBlockEventsView = "card" | "list" | "%future added value";
export type DashboardBlockFeaturedItemConfigKind = "experience" | "event" | "collection" | "membership" | "%future added value";
export type DashboardBlockFeedView = "card" | "list" | "%future added value";
export type DashboardBlockKind = "leaderboard" | "welcome_banner" | "members_list" | "feed" | "featured_items" | "memberships" | "rich_text" | "experience_details" | "upcoming_events" | "curriculum" | "channels" | "banner" | "community_welcome_hero" | "content" | "collection_folder" | "product_admin" | "pathway_sequence" | "recently_viewed" | "continue_your_products" | "%future added value";
export type DashboardBlockLeaderboardLookbackWindow = "this_week" | "last_week" | "this_month" | "last_month" | "this_year" | "last_year" | "last_7_days" | "last_30_days" | "last_90_days" | "last_365_days" | "%future added value";
export type DashboardBlockLeaderboardView = "card" | "list" | "%future added value";
export type DashboardBlockMembersListMemberType = "member_only" | "admin_only" | "all" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditDashboardBlockFormFragment$data = {
  readonly id: string;
  readonly kind: DashboardBlockKind;
  readonly position: DashboardBlockPosition;
  readonly showOnMobile: boolean;
  readonly dashboardId: string;
  readonly __typename: string;
  readonly title?: string;
  readonly subtitle?: string;
  readonly richEditorDescription?: string;
  readonly cover?: string | null;
  readonly hideAfter?: string | null;
  readonly richTextBlockTitle?: string | null;
  readonly richEditorContent?: string | null;
  readonly hasCtaButton?: boolean | null;
  readonly ctaUrl?: string | null;
  readonly ctaText?: string | null;
  readonly description?: string | null;
  readonly showMembers?: boolean;
  readonly showDates?: boolean;
  readonly showCountdown?: boolean;
  readonly showProgress?: boolean;
  readonly showAttendance?: boolean;
  readonly contentMode?: DashboardBlockContentMode;
  readonly contentUsages?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly contentId: string;
      };
    }>;
  } | null;
  readonly contentId?: string;
  readonly featuredKind?: DashboardBlockFeaturedItemConfigKind;
  readonly featuredProducts?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly leaderboardCount?: number;
  readonly leaderboardTitle?: string;
  readonly leaderboardDescription?: string;
  readonly leaderboardLookbackWindow?: DashboardBlockLeaderboardLookbackWindow;
  readonly leaderboardView?: DashboardBlockLeaderboardView;
  readonly membersListCount?: number;
  readonly memberType?: DashboardBlockMembersListMemberType;
  readonly count?: number;
  readonly eventType?: string;
  readonly showCover?: boolean;
  readonly eventsView?: DashboardBlockEventsView;
  readonly feedTitle?: string;
  readonly feedView?: DashboardBlockFeedView;
  readonly curriculumTitle?: string;
  readonly curriculumView?: DashboardBlockCurriculumView;
  readonly channelsTitle?: string;
  readonly showAvailability?: boolean;
  readonly " $fragmentType": "EditDashboardBlockFormFragment";
};
export type EditDashboardBlockFormFragment$key = {
  readonly " $data"?: EditDashboardBlockFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditDashboardBlockFormFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showDates",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showCountdown",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showCover",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditDashboardBlockFormFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOnMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dashboardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "richEditorDescription",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideAfter",
          "storageKey": null
        }
      ],
      "type": "WelcomeBannerDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "richTextBlockTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "richEditorContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCtaButton",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaText",
          "storageKey": null
        }
      ],
      "type": "RichTextDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showMembers",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showAttendance",
          "storageKey": null
        }
      ],
      "type": "ExperienceDetailsDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "contentMode",
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "contentUsages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ContentDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v6/*: any*/)
      ],
      "type": "CollectionFolderDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "featuredKind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeConnection",
          "kind": "LinkedField",
          "name": "featuredProducts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "FeaturedItemsDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "leaderboardCount",
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": "leaderboardTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "leaderboardDescription",
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": "leaderboardLookbackWindow",
          "args": null,
          "kind": "ScalarField",
          "name": "lookbackWindow",
          "storageKey": null
        },
        {
          "alias": "leaderboardView",
          "args": null,
          "kind": "ScalarField",
          "name": "view",
          "storageKey": null
        }
      ],
      "type": "LeaderboardDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "membersListCount",
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "memberType",
          "storageKey": null
        }
      ],
      "type": "MembersListDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eventType",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": "eventsView",
          "args": null,
          "kind": "ScalarField",
          "name": "view",
          "storageKey": null
        }
      ],
      "type": "UpcomingEventsDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "feedTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "feedView",
          "args": null,
          "kind": "ScalarField",
          "name": "view",
          "storageKey": null
        }
      ],
      "type": "FeedDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "curriculumTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": "curriculumView",
          "args": null,
          "kind": "ScalarField",
          "name": "view",
          "storageKey": null
        }
      ],
      "type": "CurriculumDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "channelsTitle",
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "type": "ChannelsDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v7/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showAvailability",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "type": "BannerDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "type": "CommunityWelcomeHeroDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/)
      ],
      "type": "ContinueYourProductsDashboardBlock",
      "abstractKey": null
    }
  ],
  "type": "DashboardBlock",
  "abstractKey": "__isDashboardBlock"
};
})();

(node as any).hash = "4649e5dfd4b7a7f4cc85de4c0f89bad8";

export default node;
