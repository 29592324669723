/**
 * @generated SignedSource<<7d1f915030157ab976daf6290563924f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OnboardingChecklistSectionKind = "customize_community" | "build_learning_products" | "set_up_community_home" | "manage_segment_members" | "launch_invite_members" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AdminOnboardingChecklistSectionFragment$data = {
  readonly id: string;
  readonly content: {
    readonly kind: OnboardingChecklistSectionKind;
    readonly name: string;
  };
  readonly items: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AdminOnboardingChecklistItemTemplateFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "AdminOnboardingChecklistSectionFragment";
};
export type AdminOnboardingChecklistSectionFragment$key = {
  readonly " $data"?: AdminOnboardingChecklistSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminOnboardingChecklistSectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminOnboardingChecklistSectionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OnboardingChecklistSection",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationOnboardingChecklistItemNodeConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationOnboardingChecklistItemNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationOnboardingChecklistItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AdminOnboardingChecklistItemTemplateFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationOnboardingChecklistSection",
  "abstractKey": null
};
})();

(node as any).hash = "11691d32b9c9a84adff569cc1c45f032";

export default node;
