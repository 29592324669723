import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import { CreateMemberGroupModalFragment$key } from "@/product/common/member-group/common/create/button/__generated__/CreateMemberGroupModalFragment.graphql"
import CreateMemberGroupForm from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import CreateSubGroupForm from "@/product/common/member-group/common/create/form/CreateSubGroupForm"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal } from "@disco-ui"
import { graphql, useFragment } from "react-relay"

interface Props {
  isOpen: boolean
  onClose: () => void
  isSubGroup?: boolean
  groupKey: CreateMemberGroupModalFragment$key
  onCreate?: () => void
  selectedMembershipIds?: GlobalID[]
}

function CreateMemberGroupModal({
  isOpen,
  onClose,
  isSubGroup,
  groupKey,
  onCreate,
  selectedMembershipIds = [],
}: Props) {
  const memberGroup = useFragment<CreateMemberGroupModalFragment$key>(
    graphql`
      fragment CreateMemberGroupModalFragment on MemberGroup {
        id
      }
    `,
    groupKey
  )

  const activeProduct = useActiveProduct()
  const membersLabel = useLabel(activeProduct ? "product_member" : "organization_member")
  const { completeOrIgnoreChecklistItem } = useOnboardingChecklistContext()

  return (
    <DiscoModal
      isOpen={isOpen}
      onClose={onClose}
      width={"720px"}
      testid={"CreateMemberGroupButtonModal"}
      modalContentLabel={"CreateMemberGroupButtonModal"}
      title={isSubGroup ? "Create a Sub-group" : "Create a Group"}
      subtitle={
        isSubGroup
          ? `Sub-groups help to foster deeper connections among ${membersLabel.plural}, encourage more focused and relevant discussions, and provide a more personalized and engaging experience.`
          : `Groups are used to group a collection of ${membersLabel.plural} and provide access to specific events, assignments and community experiences.`
      }
      buttons
      body={
        isSubGroup ? (
          <CreateSubGroupForm
            memberGroupId={memberGroup.id}
            onCancel={onClose}
            onSave={onCreateGroup}
            selectedMembershipIds={selectedMembershipIds}
          />
        ) : (
          <CreateMemberGroupForm
            memberGroupId={memberGroup.id}
            onCancel={onClose}
            onSave={onCreateGroup}
            selectedMembershipIds={selectedMembershipIds}
          />
        )
      }
    />
  )

  async function onCreateGroup() {
    onClose()

    if (onCreate) {
      onCreate()
    }

    await completeOrIgnoreChecklistItem("create_group")
  }
}

export default CreateMemberGroupModal
