import AdminSidebarItem from "@/admin/common/sidebar/AdminSidebarItem"
import useCanAccessTrainingSources from "@/admin/training-sources/useCanAccessTrainingSources"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isAdminConsolePath } from "@/core/route/util/routeUtils"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoSideBar, DiscoSideBarProps } from "@disco-ui"
import DiscoSidebarHeader from "@disco-ui/sidebar/DiscoSidebarHeader"
import { ArrayUtils } from "@utils/array/arrayUtils"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { useLocation } from "react-router-dom"

export default function AdminSideBar(props: Partial<Omit<DiscoSideBarProps, "classes">>) {
  const location = useLocation()
  const isOnAdmin = isAdminConsolePath(location.pathname)
  const isOpen = isOnAdmin
  const activeOrganization = useActiveOrganization()!
  const permissions = activeOrganization.viewerPermissions
  const canAccessTrainingSources = useCanAccessTrainingSources()
  const { slackV2, surveys, aggregateProductReports, memberReports, learningPathways } =
    useFeatureFlags()
  const classes = useStyles()
  const experienceLabel = useLabel("admin_experience")
  const membersLabel = useLabel("admin_member")
  const productsInsightsSelected =
    location.pathname === ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCTS ||
    location.pathname.startsWith(`${ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCTS}/`)

  const { shouldHideChecklist } = useOnboardingChecklistContext()

  if (
    !activeOrganization.viewerPermissions.has("organization_settings.manage") &&
    activeOrganization.viewerMembership?.hasInstructorOrManagerRole &&
    (aggregateProductReports || memberReports)
  )
    return (
      <DiscoSideBar
        {...props}
        testid={`AdminSideBar.${isOpen ? "open" : "closed"}`}
        header={<DiscoSidebarHeader title={"Admin Area"} icon={"flash"} />}
        items={
          <div className={classes.itemsContainer}>
            <AdminSidebarItem
              to={ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCTS}
              name={"Insights"}
              leftIcon={"chart-bar"}
              testid={"AdminSideBar.Insights"}
              tooltip={isOpen ? "" : "Insights"}
              showItemsRoute={ROUTE_NAMES.ADMIN.INSIGHTS.ROOT}
              selected={false}
              items={[
                ...ArrayUtils.spreadIf(
                  {
                    name: experienceLabel.plural,
                    to: ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCTS,
                    selected: productsInsightsSelected,
                  },
                  aggregateProductReports
                ),
                ...ArrayUtils.spreadIf(
                  {
                    name: membersLabel.plural,
                    to: ROUTE_NAMES.ADMIN.INSIGHTS.MEMBERS,
                  },
                  memberReports
                ),
              ]}
            />
          </div>
        }
      />
    )

  return (
    <DiscoSideBar
      {...props}
      testid={`AdminSideBar.${isOpen ? "open" : "closed"}`}
      header={<DiscoSidebarHeader title={"Admin Area"} icon={"flash"} />}
      items={
        <div className={classes.itemsContainer}>
          {!shouldHideChecklist && (
            <AdminSidebarItem
              to={ROUTE_NAMES.ADMIN.ONBOARDING_CHECKLIST}
              name={"Getting Started"}
              leftIcon={"file-check"}
              testid={"AdminSideBar.GettingStarted"}
              tooltip={isOpen ? "" : "Getting Started"}
            />
          )}

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.DASHBOARD}
            name={"Dashboard"}
            leftIcon={"critical"}
            testid={"AdminSideBar.Dashboard"}
            tooltip={isOpen ? "" : "Dashboard"}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.MEMBERS.ROOT}
            name={membersLabel.plural}
            leftIcon={"user"}
            testid={"AdminSideBar.Members"}
            tooltip={isOpen ? "" : membersLabel.plural}
            showItemsRoute={ROUTE_NAMES.ADMIN.MEMBERS.ROOT}
            items={[
              {
                name: "Invites",
                to: ROUTE_NAMES.ADMIN.MEMBERS.INVITES.ROOT,
                testid: "AdminSideBar.Members.Invites",
              },
              {
                name: "Applications",
                to: ROUTE_NAMES.ADMIN.MEMBERS.APPLICATIONS.ROOT,
                testid: "AdminSideBar.Members.Applications",
              },
              {
                name: "Groups",
                to: ROUTE_NAMES.ADMIN.MEMBERS.GROUPS.ROOT,
                testid: "AdminSideBar.Members.Groups",
              },
              {
                name: "Profile Fields",
                to: ROUTE_NAMES.ADMIN.MEMBERS.PROFILE_FIELDS.ROOT,
              },
              {
                name: "Onboarding",
                to: ROUTE_NAMES.ADMIN.MEMBERS.ONBOARDING.ROOT,
              },
              {
                name: "Guests",
                to: ROUTE_NAMES.ADMIN.MEMBERS.GUESTS.ROOT,
              },
              ...ArrayUtils.spreadIf(
                {
                  name: "Slack Members",
                  to: ROUTE_NAMES.ADMIN.MEMBERS.IMPORTED_MEMBERS.ROOT,
                },
                slackV2 && activeOrganization.slackConnectionStatus === "active"
              ),
              {
                name: "Roles",
                to: ROUTE_NAMES.ADMIN.MEMBERS.ROLES.ROOT,
              },
            ]}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.CONTENT.ROOT}
            name={"Content"}
            leftIcon={"book-open"}
            testid={"AdminSideBar.Content"}
            tooltip={isOpen ? "" : "Content"}
            showItemsRoute={ROUTE_NAMES.ADMIN.CONTENT.ROOT}
            items={[
              {
                name: "Media Assets",
                to: ROUTE_NAMES.ADMIN.CONTENT.MEDIA_ASSETS.ROOT,
                testid: "AdminSideBar.Content.Assets",
              },
              ...ArrayUtils.spreadIf(
                {
                  name: "Surveys",
                  to: ROUTE_NAMES.ADMIN.CONTENT.SURVEYS.ROOT,
                  testid: "AdminSideBar.Content.Surveys",
                },
                surveys
              ),
            ]}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.EVENTS.ROOT}
            name={"Events"}
            leftIcon={"calendar"}
            testid={"AdminSideBar.Events"}
            tooltip={isOpen ? "" : "Events"}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.PRODUCTS.ROOT}
            name={experienceLabel.plural}
            leftIcon={"3d-box"}
            testid={"AdminSideBar.Products"}
            tooltip={isOpen ? "" : experienceLabel.plural}
            showItemsRoute={ROUTE_NAMES.ADMIN.PRODUCTS.ROOT}
            items={[
              {
                name: "Transactions",
                to: ROUTE_NAMES.ADMIN.PRODUCTS.TRANSACTIONS.ROOT,
              },
              ...ArrayUtils.spreadIf(
                {
                  name: "Pathways",
                  to: ROUTE_NAMES.ADMIN.PRODUCTS.PATHWAYS.ROOT,
                  selected: location.pathname.startsWith(
                    ROUTE_NAMES.ADMIN.PRODUCTS.PATHWAYS.ROOT
                  ),
                },
                learningPathways
              ),
              {
                name: "Memberships",
                to: ROUTE_NAMES.ADMIN.PRODUCTS.MEMBERSHIPS.ROOT,
              },
              ...ArrayUtils.spreadIf(
                {
                  name: "Discounts",
                  to: ROUTE_NAMES.ADMIN.PRODUCTS.DISCOUNTS.ROOT,
                  selected: location.pathname.startsWith(
                    ROUTE_NAMES.ADMIN.PRODUCTS.DISCOUNTS.ROOT
                  ),
                },
                activeOrganization.checkoutVersion !== "stripe_acacia"
              ),
              {
                name: "Public Pages",
                to: ROUTE_NAMES.ADMIN.PRODUCTS.PUBLIC_PAGES.ROOT,
              },
              {
                name: "Media Library",
                to: ROUTE_NAMES.ADMIN.PRODUCTS.MEDIA_LIBRARY.ROOT,
              },
            ]}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.AUTOMATIONS}
            name={"Automations"}
            leftIcon={"lightning"}
            testid={"AdminSideBar.Automations"}
            tooltip={isOpen ? "" : "Automations"}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.INSIGHTS.ROOT}
            name={"Insights"}
            leftIcon={"chart-bar"}
            testid={"AdminSideBar.Insights"}
            tooltip={isOpen ? "" : "Insights"}
            showItemsRoute={ROUTE_NAMES.ADMIN.INSIGHTS.ROOT}
            items={[
              {
                name: "Engagement",
                to: ROUTE_NAMES.ADMIN.INSIGHTS.ENGAGEMENT,
              },
              {
                name: "Messages",
                to: ROUTE_NAMES.ADMIN.INSIGHTS.MESSAGES,
              },
              ...ArrayUtils.spreadIf(
                {
                  name: experienceLabel.plural,
                  to: ROUTE_NAMES.ADMIN.INSIGHTS.PRODUCTS,
                  selected: productsInsightsSelected,
                },
                aggregateProductReports
              ),
              ...ArrayUtils.spreadIf(
                {
                  name: membersLabel.plural,
                  to: `${ROUTE_NAMES.ADMIN.INSIGHTS.MEMBERS}?tab=overview`,

                  selected: location.pathname.startsWith(
                    ROUTE_NAMES.ADMIN.INSIGHTS.MEMBERS
                  ),
                },
                memberReports
              ),
              ...ArrayUtils.spreadIf(
                {
                  name: "Pathways",
                  to: ROUTE_NAMES.ADMIN.INSIGHTS.PATHWAYS.ROOT,
                  selected: location.pathname.startsWith(
                    ROUTE_NAMES.ADMIN.INSIGHTS.PATHWAYS.ROOT
                  ),
                },
                learningPathways
              ),
            ]}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.AI.ROOT}
            name={"Disco AI"}
            leftIcon={"stars"}
            testid={"AdminSideBar.DiscoAI"}
            tooltip={isOpen ? "" : "Disco AI"}
            showItemsRoute={ROUTE_NAMES.ADMIN.AI.ROOT}
            items={[
              ...ArrayUtils.spreadIf(
                {
                  name: "Training Sources",
                  to: ROUTE_NAMES.ADMIN.AI.TRAINING_SOURCES.ROOT,
                },
                canAccessTrainingSources
              ),
            ]}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.INTEGRATIONS.ROOT}
            name={"Integrations"}
            leftIcon={"grid"}
            testid={"AdminSideBar.Integrations"}
            tooltip={isOpen ? "" : "Integrations"}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
            name={"Appearance"}
            leftIcon={"color-swatch"}
            testid={"AdminSideBar.Appearance"}
            tooltip={isOpen ? "" : "Appearance"}
            showItemsRoute={ROUTE_NAMES.ADMIN.APPEARANCE.ROOT}
            items={[
              {
                name: "Domain",
                to: ROUTE_NAMES.ADMIN.APPEARANCE.DOMAIN.ROOT,
              },
              {
                name: "Labels",
                to: ROUTE_NAMES.ADMIN.APPEARANCE.LABELS.ROOT,
              },
              {
                name: "Sidebar",
                to: ROUTE_NAMES.ADMIN.APPEARANCE.SIDEBAR.ROOT,
              },
            ]}
          />

          <AdminSidebarItem
            to={ROUTE_NAMES.ADMIN.SETTINGS.ROOT}
            name={"Settings"}
            leftIcon={"settings"}
            testid={"AdminSideBar.Settings"}
            tooltip={isOpen ? "" : "Settings"}
            showItemsRoute={ROUTE_NAMES.ADMIN.SETTINGS.ROOT}
            items={[
              ...ArrayUtils.spreadIf(
                {
                  name: "Billing",
                  to: ROUTE_NAMES.ADMIN.SETTINGS.BILLING.ROOT,
                },
                permissions.has("plans.manage")
              ),
              {
                name: "Registration",
                to: ROUTE_NAMES.ADMIN.SETTINGS.REGISTRATION.ROOT,
              },
              ...ArrayUtils.spreadIf(
                {
                  name: "Advanced",
                  to: ROUTE_NAMES.ADMIN.SETTINGS.ADVANCED.ROOT,
                },
                permissions.has("organization.delete")
              ),
            ]}
          />
        </div>
      }
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  itemsContainer: {
    flex: "auto",
    display: "inline-flex",
    flexFlow: "column",
    flexShrink: 0,
    minHeight: "100%",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}))
