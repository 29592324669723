import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { LabelFormFieldSkeleton } from "@/admin/labels/LabelFormField"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabel } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import StripeConnectionButton from "@/payment/stripe-integration/button/StripeConnectionButton"
import { ExperienceSettingsFormMutation } from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import { ExperienceSettingsFormState } from "@/product/settings/ExperienceSettingsForm"
import ExperienceSettingsLabel from "@/product/settings/ExperienceSettingsLabel"
import { useExperienceSettingsSelectedPlans_membershipPlansFragment$key } from "@/product/settings/hooks/__generated__/useExperienceSettingsSelectedPlans_membershipPlansFragment.graphql"
import useExperienceSettingsSelectedPlans from "@/product/settings/hooks/useExperienceSettingsSelectedPlans"
import MembershipPlanExperiencePricingListItem from "@/product/settings/pricing/MembershipPlanExperiencePricingListItem"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoLink,
  DiscoSection,
  DiscoSwitch,
  DiscoText,
} from "@disco-ui"
import DiscoNumberInput from "@disco-ui/input/DiscoNumberInput"
import { InputAdornment } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { Fragment } from "react"
import { generatePath } from "react-router-dom"

interface Props extends TestIDProps {
  form: FormStore<
    CreateExperienceFormState | ExperienceSettingsFormState,
    ExperienceSettingsFormMutation
  >
  organizationKey: useExperienceSettingsSelectedPlans_membershipPlansFragment$key
  hidePricing?: boolean
}

function ExperiencePricingFormFields({
  testid = "PricingFormFields",
  form,
  organizationKey,
  hidePricing,
}: Props) {
  const experienceLabel = useLabel("admin_experience")
  const membersLabel = useLabel("admin_member")
  const activeOrganization = useActiveOrganization()!
  const isStripeConnected = activeOrganization.hasStripeConnection
  const classes = useStyles()

  const { hasCustomPlans, availableMembershipPlans } = useExperienceSettingsSelectedPlans(
    {
      organizationKey,
      benefits: form.state.benefits,
    }
  )

  return (
    <>
      {!hidePricing && (
        <DiscoFormControl>
          <DiscoSwitch
            checked={form.state.isPaid}
            onChange={handleToggleIsPaid}
            testid={`${testid}.one-time-fee-switch`}
            label={
              <ExperienceSettingsLabel
                title={"One-time Fee"}
                sectionId={"experience_one_time_fee"}
                tooltipContent={`Grant access to a ${experienceLabel.singular} by charging a one-time fee, through a membership plan, or by using a combination of both.`}
              />
            }
            sublabel={
              <DiscoText variant={"body-sm"} marginBottom={1.5}>
                {`Charge a one-off access fee for this ${experienceLabel.singular}.`}
              </DiscoText>
            }
          />
          {form.state.isPaid &&
            (isStripeConnected ? (
              <DiscoSection className={classes.extraCharge}>
                <DiscoNumberInput
                  fullWidth
                  testid={`${testid}.price`}
                  value={form.state.price}
                  disabled={!form.state.isPaid}
                  onChange={handleUpdateExtraCharge}
                  endAdornment={
                    <InputAdornment position={"end"}>
                      {activeOrganization.currency.toUpperCase()}
                    </InputAdornment>
                  }
                />
              </DiscoSection>
            ) : (
              <DiscoSection groovyDepths={"insideCard"}>
                <div className={classes.stripe}>
                  <DiscoText>{`Stripe must be connected to create a paid ${experienceLabel.singular}.`}</DiscoText>
                  <StripeConnectionButton showConnectConfirmDialog testid={testid} />
                </div>
              </DiscoSection>
            ))}
        </DiscoFormControl>
      )}

      {hasCustomPlans && (
        <DiscoFormControl
          label={
            <ExperienceSettingsLabel
              title={"Membership Plans"}
              sectionId={"experience_membership_plans"}
              tooltipContent={`Only ${membersLabel.plural} on the selected membership plan(s) can access this ${experienceLabel.singular}.`}
            />
          }
          sublabel={
            <DiscoText variant={"body-sm"} color={"text.secondary"} display={"inline"}>
              {`Attach this ${experienceLabel.singular} to one or more existing membership plans. `}

              <DiscoLink to={generatePath(ROUTE_NAMES.ADMIN.PRODUCTS.MEMBERSHIPS.ROOT)}>
                <DiscoText
                  variant={"body-sm-700"}
                  display={"inline"}
                  color={"primary.main"}
                >
                  {"Manage Memberships"}
                </DiscoText>
              </DiscoLink>
            </DiscoText>
          }
        >
          {/* Membership Plans */}
          <DiscoSection className={classes.container}>
            {availableMembershipPlans.map((p, index) => (
              <Fragment key={p.id}>
                {index > 0 && <DiscoDivider marginTop={1.5} marginBottom={1.5} />}
                <MembershipPlanExperiencePricingListItem
                  form={form}
                  membershipPlanKey={p}
                />
              </Fragment>
            ))}
          </DiscoSection>
        </DiscoFormControl>
      )}
    </>
  )

  function handleToggleIsPaid() {
    form.state.isPaid = !form.state.isPaid
  }

  function handleUpdateExtraCharge(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    form.state.price = Number(e.target.value)
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    margin: theme.spacing(0.5, 0, 2, 0),
    display: "flex",
    flexDirection: "column",
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(2),
  },
  stripe: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  extraCharge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: theme.palette.groovyDepths.insideCard,
    padding: theme.spacing(2),
  },
}))

export const ExperiencePricingFormFieldsSkeleton = () => {
  const heights = [60]

  return (
    <div>
      {heights.map((height) => (
        <LabelFormFieldSkeleton key={height} height={height} width={350} />
      ))}
    </div>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(ExperiencePricingFormFields),
  skeleton: ExperiencePricingFormFieldsSkeleton,
})
