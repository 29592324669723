import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CheckoutStore from "@/product/checkout/store/CheckoutStore"
import { DiscoText } from "@disco-ui"
import { Grid, Theme, useMediaQuery } from "@material-ui/core"
import { FC } from "react"

interface Props {
  store: CheckoutStore
}

const CheckoutFormInvitationSection: FC<Props> = ({ store }) => {
  const classes = useStyles()
  const experienceLabel = useLabel("experience")
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  if (!store.checkout?.invitation) return null
  const { invitation } = store.checkout

  return (
    <Grid
      container
      spacing={2}
      wrap={"nowrap"}
      data-testid={"CheckoutFormInvitationSection.container"}
      className={classes.container}
    >
      <Grid item container alignItems={"center"}>
        <DiscoText
          variant={isXsScreen ? "body-lg-600" : "heading-md"}
          testid={"CheckoutFormInvitationSection.message"}
        >
          {getInvitationHeaderText()}
        </DiscoText>
        <DiscoText marginTop={1} variant={"body-sm"} color={"text.secondary"}>
          {"Create an account to continue."}
        </DiscoText>
      </Grid>
    </Grid>
  )

  function getInvitationHeaderText() {
    switch (invitation.kind) {
      case "member":
        return `You have been invited to join this ${experienceLabel.singular}!`
      default:
        return ""
    }
  }
}

export default CheckoutFormInvitationSection

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))
