/**
 * @generated SignedSource<<54d413beab66bff94c3fd75b5e4c8725>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TestUserFormFieldsFragment$data = {
  readonly membershipPlans: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupsMultiSelect_OrganizationFragment">;
  readonly " $fragmentType": "TestUserFormFieldsFragment";
};
export type TestUserFormFieldsFragment$key = {
  readonly " $data"?: TestUserFormFieldsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestUserFormFieldsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestUserFormFieldsFragment",
  "selections": [
    {
      "alias": "membershipPlans",
      "args": [
        {
          "kind": "Literal",
          "name": "hideNonPublic",
          "value": false
        },
        {
          "kind": "Literal",
          "name": "type",
          "value": "membership_plan"
        }
      ],
      "concreteType": "ProductNodeConnection",
      "kind": "LinkedField",
      "name": "products",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "products(hideNonPublic:false,type:\"membership_plan\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MemberGroupsMultiSelect_OrganizationFragment"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ff402f4f0047c0c6f3219656c227bfab";

export default node;
