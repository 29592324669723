import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useCommunityLandingPagesFragment$key } from "@/landing-page/util/__generated__/useCommunityLandingPagesFragment.graphql"
import Relay from "@/relay/relayUtils"
import { graphql, useFragment } from "react-relay"

/** Returns the GlobalID for the `community_home` and
 * `community_marketing landing pages */
const useCommunityLandingPages = () => {
  const activeOrganization = useActiveOrganization()

  const node = useFragment<useCommunityLandingPagesFragment$key>(
    graphql`
      fragment useCommunityLandingPagesFragment on Organization {
        landingPages(kinds: [community_home, community_marketing]) {
          edges {
            node {
              id
              kind
              mode
              dashboard {
                id
              }
            }
          }
        }
      }
    `,
    activeOrganization
  )

  const landingPages = Relay.connectionToArray(node?.landingPages)

  const communityHomeLandingPage = landingPages.find((lp) => lp.kind === "community_home")
  const communityMarketingLandingPage = landingPages.find(
    (lp) => lp.kind === "community_marketing"
  )

  return {
    home: {
      id: communityHomeLandingPage?.id,
      dashboardId: communityHomeLandingPage?.dashboard?.id,
    },
    marketing: {
      id: communityMarketingLandingPage?.id,
      dashboardId: communityMarketingLandingPage?.dashboard?.id,
      isDisabled: communityMarketingLandingPage?.mode === "none",
    },
    landingPages: [communityHomeLandingPage, communityMarketingLandingPage],
  }
}
export default useCommunityLandingPages
