import { DiscoButton, DiscoButtonProps, DiscoSpinner, DiscoSpinnerProps } from "@disco-ui"
import { LocationDescriptorObject } from "history"
import React, {
  createElement,
  FC,
  isValidElement,
  MouseEventHandler,
  ReactElement,
  useCallback,
  useState,
} from "react"

export type OverridableDiscoButtonModalProps = {
  isOpen: boolean
  onClose: () => void
}

export type OverridableDiscoButtonProps = DiscoButtonProps & {
  children: OverridableDiscoButtonChildren
  onClick?: MouseEventHandler
  modal?: React.FC<OverridableDiscoButtonModalProps>
  modalOpenByDefault?: boolean
  testid?: string
  stopPropagation?: boolean
  spinnerProps?: DiscoSpinnerProps
}

export type OverridableDiscoButtonChildren =
  | FC<{
      onClick: MouseEventHandler
      onMouseEnter?: MouseEventHandler
      testid?: string
      to?: string | LocationDescriptorObject<unknown>
      disabled?: boolean
    }>
  | ReactElement
  | string

/** A DiscoButton that accepts a component as props and renders that component instead of a DiscoButton
 * @example
 * {({ onClick }) => <DiscoTextButton onClick={onClick}>{"New Button"}</DiscoTextButton>)}
 */
export const OverridableDiscoButton = React.forwardRef<
  HTMLButtonElement,
  OverridableDiscoButtonProps
>(function OverridableDiscoButton(props, ref) {
  const {
    children,
    testid,
    modal,
    modalOpenByDefault = false,
    onClick: _onClick,
    onMouseEnter,
    to,
    stopPropagation = false,
    color,
    spinnerProps,
    ...rest
  } = props

  const [modalOpen, setModalOpen] = useState(modalOpenByDefault)
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (stopPropagation) e.stopPropagation()
      if (rest.disabled) return
      if (modal) {
        setModalOpen((v) => !v)
      }
      if (_onClick) {
        _onClick(e)
      }
    },
    [modal, _onClick, stopPropagation, rest.disabled]
  )

  return (
    <>
      {/* Render the button by default as DiscoButton, or use custom component */}
      {isValidElement(children) || typeof children === "string" ? (
        <DiscoButton
          ref={ref}
          data-testid={testid}
          onClick={onClick}
          to={to}
          color={color}
          {...rest}
          style={rest.disabled ? { pointerEvents: "none" } : {}}
          onMouseEnter={onMouseEnter}
        >
          {props.shouldDisplaySpinner ? <DiscoSpinner size={"sm"} /> : children}
        </DiscoButton>
      ) : props.shouldDisplaySpinner ? (
        <DiscoSpinner size={"sm"} {...spinnerProps} />
      ) : (
        createElement(children, {
          ref,
          onClick,
          testid,
          to,
          style: { ...rest.style, ...(rest.disabled && { pointerEvents: "none" }) },
          ...rest,
          onMouseEnter,
        })
      )}

      {/* Render DiscoModal for forms, settings, etc */}
      {modal &&
        createElement(modal, {
          isOpen: modalOpen,
          onClose: () => setModalOpen(false),
        })}
    </>
  )
})
