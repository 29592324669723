/**
 * @generated SignedSource<<57f37fa59b55665bd53f87b39cf62d5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "onboarding_module" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
export type ContentUsageSubmissionMethod = "submission" | "mark_as_complete" | "%future added value";
export type ContentUsageSubmissionVisibility = "cohort" | "private" | "member_group" | "%future added value";
export type ProductAppKind = "content" | "link" | "curriculum" | "events" | "members" | "resources" | "slack" | "collection" | "product_link" | "organization_events" | "organization_members" | "chat_channel" | "posts" | "nav_folder" | "%future added value";
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InlineContentDrawerTemplateFragment$data = {
  readonly id: string;
  readonly isLocked: boolean;
  readonly hasPrerequisites: boolean;
  readonly entity: ContentUsageEntity;
  readonly entityId: string;
  readonly ordering: number | null;
  readonly dueAt: string | null;
  readonly submissionMethod: ContentUsageSubmissionMethod | null;
  readonly submissionVisibility: ContentUsageSubmissionVisibility | null;
  readonly submissionFormat: {
    readonly text: {
      readonly enabled: boolean;
      readonly required: boolean;
    };
    readonly file: {
      readonly enabled: boolean;
      readonly required: boolean;
    };
    readonly link: {
      readonly enabled: boolean;
      readonly required: boolean;
    };
  } | null;
  readonly showComments: boolean | null;
  readonly passPercentage: number | null;
  readonly content: {
    readonly id: string;
    readonly name: string | null;
    readonly type: ContentType;
    readonly label: string;
    readonly systemTaskKind: ContentSystemTaskKind | null;
    readonly richEditorDescriptionContent: string | null;
    readonly attachments: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
          readonly mediaUrl: string | null;
        };
      }>;
    };
    readonly thumbnailUrl: string | null;
    readonly coverPhoto: string | null;
    readonly coverVideo: string | null;
    readonly coverVideoAsset: {
      readonly id: string;
    } | null;
    readonly coverVideoDurationSeconds: number | null;
    readonly showCoverPlayIcon: boolean;
    readonly usages: {
      readonly totalCount: number;
    };
    readonly contentLabelId: string | null;
    readonly webForm: {
      readonly id: string;
      readonly currentRevision: {
        readonly id: string;
        readonly webFormId: string;
        readonly questions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly type: WebFormQuestionType;
              readonly ordering: number;
              readonly richEditorBody: string;
              readonly isRequired: boolean;
              readonly options: ReadonlyArray<{
                readonly id: string;
                readonly label: string;
              }> | null;
              readonly answerKey: {
                readonly correctIds: ReadonlyArray<string>;
                readonly feedback: string | null;
              } | null;
            };
          }>;
        };
      };
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"InlineContentHeroSectionFragment" | "InlineContentAttachmentsSectionFragment" | "InlineContentDescriptionSectionFragment" | "InlineContentHeroAndProperties_ContentFragment" | "BookmarkButtonContentFragment" | "DiscoEditorMentionsFragment">;
  };
  readonly prerequisites: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  } | null;
  readonly product: {
    readonly id: string;
    readonly adminCanLearnMode: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"LearnModeSwitchFragment">;
  } | null;
  readonly productApp: {
    readonly id: string;
    readonly kind: ProductAppKind;
  } | null;
  readonly module: {
    readonly name: string | null;
    readonly usages: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"InlineContentDrawerTemplate_ModuleContentUsageFragment">;
        };
      }>;
    };
  } | null;
  readonly contentDashboardBlock: {
    readonly id: string;
  } | null;
  readonly confirmationModalContent: {
    readonly id: string;
    readonly name: string | null;
    readonly richEditorDescriptionContent: string | null;
    readonly ctaText: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSubmissionEditorContentUsageFragment" | "InlineContentDrawerTitleFormFragment" | "InlineContentDrawerHeaderOverflowFragment" | "ContentFooterSection_ContentUsageFragment" | "InlineContentDrawerFooterFragment" | "usePermissionsFragment" | "useTrackContentViewFragment" | "QuizEditorBanner_ContentUsageFragment" | "ContentUsageAssignmentSubmitBannerFragment" | "ConnectSlackCurriculumItemBannerFragment" | "BookmarkButtonContentUsageFragment" | "ShareContentUsageButtonFragment">;
  readonly " $fragmentType": "InlineContentDrawerTemplateFragment";
};
export type InlineContentDrawerTemplateFragment$key = {
  readonly " $data"?: InlineContentDrawerTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InlineContentDrawerTemplateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordering",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enabled",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "required",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "richEditorDescriptionContent",
  "storageKey": null
},
v7 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InlineContentDrawerTemplateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrerequisites",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionMethod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionVisibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentSubmissionFormat",
      "kind": "LinkedField",
      "name": "submissionFormat",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionFormatSettings",
          "kind": "LinkedField",
          "name": "text",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionFormatSettings",
          "kind": "LinkedField",
          "name": "file",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentSubmissionFormatSettings",
          "kind": "LinkedField",
          "name": "link",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showComments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "passPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "systemTaskKind",
          "storageKey": null
        },
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentAttachmentNodeConnection",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentAttachmentNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentAttachment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mediaUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverPhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverVideo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Asset",
          "kind": "LinkedField",
          "name": "coverVideoAsset",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverVideoDurationSeconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showCoverPlayIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentLabelId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WebForm",
          "kind": "LinkedField",
          "name": "webForm",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "WebFormRevision",
              "kind": "LinkedField",
              "name": "currentRevision",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "webFormId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WebFormQuestionNodeConnection",
                  "kind": "LinkedField",
                  "name": "questions",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "WebFormQuestionNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WebFormQuestion",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v4/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "richEditorBody",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isRequired",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WebFormQuestionOption",
                              "kind": "LinkedField",
                              "name": "options",
                              "plural": true,
                              "selections": [
                                (v0/*: any*/),
                                (v5/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "WebFormQuestionAnswerKey",
                              "kind": "LinkedField",
                              "name": "answerKey",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "correctIds",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "feedback",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentHeroSectionFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentAttachmentsSectionFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentDescriptionSectionFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InlineContentHeroAndProperties_ContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BookmarkButtonContentFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DiscoEditorMentionsFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentUsageNodeConnection",
      "kind": "LinkedField",
      "name": "prerequisites",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsage",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v7/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "adminCanLearnMode",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LearnModeSwitchFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "productApp",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "usages",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "InlineContentDrawerTemplate_ModuleContentUsageFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentDashboardBlock",
      "kind": "LinkedField",
      "name": "contentDashboardBlock",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "confirmationModalContent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctaText",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuizSubmissionEditorContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentDrawerTitleFormFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentDrawerHeaderOverflowFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentFooterSection_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InlineContentDrawerFooterFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackContentViewFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuizEditorBanner_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageAssignmentSubmitBannerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectSlackCurriculumItemBannerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookmarkButtonContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShareContentUsageButtonFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "fab7efca2bb9ac0da26d38880457dd9f";

export default node;
