import AiApi, { AiApiGeneratePostResponseType } from "@/common/AiApi"
import { PostFormState } from "@/content/form/util/contentFormUtil"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore from "@/core/form/store/FormStore"
import RestfulUtil from "@/core/restful/RestfulUtil"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import {
  CreatePostInput,
  CreatePostModalMutation,
} from "@/post/add/__generated__/CreatePostModalMutation.graphql"
import PostFormFields from "@/post/PostFormFields"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import useTrackAiActivity from "@/reporting/useTrackAIActivity"
import EditorUtils from "@components/editor/EditorUtils"
import { EditorInstance } from "@components/editor/LexicalEditor"
import Form from "@components/form/Form"
import { DiscoButton } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { MutableRefObject, useEffect, useRef, useState } from "react"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import { commitLocalUpdate, ConnectionHandler } from "relay-runtime"

interface Props extends TestIDProps {
  form: FormStore<CreatePostInput & PostFormState, CreatePostModalMutation>
  refetch?: VoidFunction
  onClose?: (ignoreUnsavedChanges: boolean) => void
  // feedId is nullable when looking at card on a place like the community home
  feedId?: GlobalID
  editorButtonsRef?: MutableRefObject<HTMLDivElement | null>
}

function CreatePostForm({ testid = "CreatePostForm", ...props }: Props) {
  const { form, refetch, onClose, editorButtonsRef } = props
  const history = useHistory()
  const location = useLocation<LocationState>()
  const isSubmitConfirmed = useRef(false)
  const [showDraftWarningModal, setShowDraftWarningModal] = useState(false)
  const drawer = useGlobalDrawer("scheduledPosts")
  const { setUnsavedChanges } = useUnsavedChangesModalContext()
  const trackAiActivity = useTrackAiActivity()
  const { completeOrIgnoreChecklistItem } = useOnboardingChecklistContext()

  const [editor, setEditor] = useState<EditorInstance | null>(null)

  useEffect(() => {
    if (!form.state.aiTemplate || !editor) return
    handleAiPostCreation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.state.aiTemplate, editor])

  if (showDraftWarningModal)
    return (
      <DiscoWarningModal
        isOpen
        onClose={() => onClose?.(false)}
        title={"Confirm Scheduling"}
        modalContentLabel={"Confirm Scheduling"}
        testid={"CreatePostModal.confirm-scheduled-share"}
        variant={"primary"}
        description={`This Experience is currently in draft mode. If the Experience is still in draft mode at the time this post is published, the post will not be shared by email.`}
        cancelButtonText={"Cancel"}
        customConfirmationButton={
          <DiscoButton
            testid={`${testid}.confirm-submit`}
            width={"100%"}
            onClick={() => {
              isSubmitConfirmed.current = true
              handleSubmit()
            }}
            shouldDisplaySpinner={form.isSubmitting}
            disabled={form.isSubmitting}
          >
            {"Schedule Post"}
          </DiscoButton>
        }
      />
    )

  return (
    <Form id={"CreatePostForm"} testid={testid} onSubmit={handleSubmit}>
      <PostFormFields
        form={form}
        editorButtonsRef={editorButtonsRef}
        setEditorRef={setEditor}
      />
    </Form>
  )

  async function handleAiPostCreation() {
    await handleAiGeneration("title")
    await handleAiGeneration("body")
  }

  async function handleAiGeneration(responseType: AiApiGeneratePostResponseType) {
    if (responseType === "title") {
      await RestfulUtil.createStream({
        startStreamCb: () =>
          AiApi.generatePost({
            feedId: form.state.feedId,
            template: form.state.aiTemplate,
            responseType: "title",
          }),
        handleText: (text) => {
          form.state.content.name = text
          return true
        },
      })
    }
    if (responseType === "body" && editor) {
      await EditorUtils.aiStreamToEditor(
        editor,
        {
          feedId: form.state.feedId,
          template: form.state.aiTemplate,
          responseType: "body",
          postTitle: form.state.content.name ?? undefined,
        },
        {
          onStreamEnd: (data) => {
            if (!data.botResponseId) return

            trackAiActivity({
              kind: "post_suggestion_drafted",
              entityId: data.botResponseId,
            })
            form.state.botResponseId = data.botResponseId
          },
        }
      )
    }
  }

  async function handleSubmit() {
    // when publishing a post in a product-level experience
    // show a confirmation modal so admin is aware the email will not be sent
    // if the product is still in draft at time of publishing
    if (
      form.state.selectedFeed.product &&
      form.state.selectedFeed.product.status !== "published" &&
      form.state.content.sendNotificationEmail &&
      form.state.isScheduled &&
      !isSubmitConfirmed.current
    )
      return setShowDraftWarningModal(true)

    const connectionIds: string[] = []

    // update the selected feed the post belongs to
    connectionIds.push(
      ConnectionHandler.getConnectionID(form.state.selectedFeed.id, "FeedPosts__posts", {
        kind: form.state.isScheduled ? "scheduled" : "released",
      })
    )

    const { isScheduled } = form.state

    const { didSave, response } = await form.submit(
      {
        feedId: form.state.feedId,
        content: {
          ...form.state.content,
          richEditorNotificationEmailBodyContent: form.state.selectedFeed.product
            ? form.state.content.richEditorDescriptionContent
            : form.state.content.richEditorNotificationEmailBodyContent,
          releaseDatetime: form.state.isScheduled
            ? form.state.content.releaseDatetime
            : null,
        },
        botResponseId: form.state.botResponseId,
      },
      {
        connections: connectionIds,
      }
    )
    if (!didSave || !response?.node?.id) return
    setUnsavedChanges(false)

    // Increase the totalCount for the connections
    commitLocalUpdate(RelayEnvironment, (store) => {
      connectionIds.forEach((connectionId) => {
        const connection = store.get(connectionId)
        if (connection) {
          const totalCount = Number(connection.getValue("totalCount"))
          connection.setValue(totalCount + 1, "totalCount")
        }

        // If refetch is available do not invalidate connection
        if (refetch) return
        const feedConnection = store.get(form.state.feedId)
        if (!feedConnection) return
        feedConnection.invalidateRecord()
      })
    })

    // Complete the 'write_welcome_post' onboarding checklist item if it's not already completed
    await completeOrIgnoreChecklistItem("write_welcome_post")

    // Refetch connection
    if (refetch) refetch()

    // close modal
    if (onClose) onClose(true)

    let openSharePostModalState: GlobalID | undefined

    // Only allow sharing in a published product
    const isProductPublished = form.state.selectedFeed.product
      ? form.state.selectedFeed.product?.status === "published"
      : true
    if (response.node.viewerPermissions.includes("post.share") && isProductPublished) {
      openSharePostModalState = response.node.id
    }

    // If posting from the community home, open the share modal
    if (location.pathname === ROUTE_NAMES.COMMUNITY.HOME.ROOT && response?.node?.id) {
      history.replace({
        ...location,
        state: {
          ...location.state,
          openSharePostModal: openSharePostModalState,
        },
      })
    }

    // If posting from product home or feed dashboard
    if (response?.node?.feedId) {
      if (isScheduled) {
        // Open the scheduled posts drawer
        drawer.open({
          feedId: response.node.feedId,
        })
      } else {
        // if we posted immediately, open the share modal
        history.push({
          pathname: form.state.selectedFeed.product
            ? generatePath(ROUTE_NAMES.PRODUCT.FEED.POSTS.LIST, {
                productSlug: form.state.selectedFeed.product.slug,
                feedId: response.node.feedId,
              })
            : generatePath(ROUTE_NAMES.COMMUNITY.FEED.POSTS.LIST, {
                feedId: response.node.feedId,
              }),
          state: {
            openSharePostModal: openSharePostModalState,
          },
        })
      }
    }
  }
}

export default observer(CreatePostForm)
