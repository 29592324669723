import {
  CreateAIGenerationInput,
  GenerateWithAIButtonMutation,
} from "@/content/ai/button/__generated__/GenerateWithAIButtonMutation.graphql"
import GenerateWithAIForm from "@/content/ai/GenerateWithAIForm"
import useTrackAIGenerationStatus from "@/content/ai/hooks/useTrackAIGenerationStatus"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { DiscoModal } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import useDisclosure from "@utils/hook/useDisclosure"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  curriculumId: GlobalID
  children: OverridableDiscoButtonChildren
  onSuccess?: () => void
}

export type GenerateWithAIFormState = CreateAIGenerationInput & {
  assetEmbeddingSourceIds: GlobalID[]
  contentEmbeddingSourceIds: GlobalID[]
  trainingDataEmbeddingSourceIds: GlobalID[]
  uploadedAssets: MediaResult[]
  referenceMeter: number
}

type CurriculumGenerationQueryParams = {
  openCurriculumGenModal?: "1"
}

function GenerateWithAIButton({
  curriculumId,
  children,
  onSuccess,
  testid = "GenerateAIButton",
}: Props) {
  const activeProduct = useActiveProduct()
  const classes = useStyles()
  const [{ openCurriculumGenModal }, setQueryParams] =
    useQueryParamState<CurriculumGenerationQueryParams>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { curriculumGeneration } = useFeatureFlags()

  const { status } = useTrackAIGenerationStatus({
    entityId: activeProduct?.curriculum?.id || "",
    skip: !curriculumGeneration,
  })
  const isAIGenerationRunning = status === "running" || status === "pending"

  const form = useFormStore<GenerateWithAIButtonMutation, GenerateWithAIFormState>(
    graphql`
      mutation GenerateWithAIButtonMutation($input: CreateAIGenerationInput!) {
        response: createAIGeneration(input: $input) {
          node {
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      prompt: "",
      referenceUrl: "",
      referenceEmbeddingSourceIds: [],
      entityId: curriculumId,
      productId: activeProduct?.id || null,
      assetEmbeddingSourceIds: [],
      contentEmbeddingSourceIds: [],
      trainingDataEmbeddingSourceIds: [],
      uploadedAssetIds: [],
      referenceMeter: 0,
      uploadedAssets: [],
      searchWeb: true,
    }
  )

  if (!curriculumGeneration) return null

  return (
    <>
      <OverridableDiscoButton
        testid={testid}
        onClick={onOpen}
        shouldDisplaySpinnerWithText={isAIGenerationRunning}
        disabled={isAIGenerationRunning}
      >
        {children}
      </OverridableDiscoButton>

      <DiscoModal
        testid={testid}
        modalContentLabel={"Curriculum Generation"}
        title={"Generate Curriculum with AI"}
        width={"720px"}
        maxWidth={"90vw"}
        classes={{
          footer: classes.modalFooter,
          leftSideFooter: classes.modalLeftSideFooter,
        }}
        isOpen={openCurriculumGenModal === "1" || isOpen}
        onClose={handleOnClose}
        body={<GenerateWithAIForm form={form} onSubmit={handleSubmit} />}
      />
    </>
  )

  async function handleSubmit() {
    const {
      contentEmbeddingSourceIds,
      assetEmbeddingSourceIds,
      trainingDataEmbeddingSourceIds,
    } = form.state

    const { didSave } = await form.submit({
      prompt: form.state.prompt,
      entityId: form.state.entityId,
      productId: form.state.productId,
      uploadedAssetIds: form.state.uploadedAssetIds,
      referenceUrl: form.state.referenceUrl,
      referenceEmbeddingSourceIds: [
        ...contentEmbeddingSourceIds,
        ...assetEmbeddingSourceIds,
        ...trainingDataEmbeddingSourceIds,
      ],
      searchWeb: form.state.searchWeb,
    })

    if (!didSave) return

    onSuccess?.()
    handleOnClose()
  }

  function handleOnClose() {
    setQueryParams({ openCurriculumGenModal: undefined })
    onClose()
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalFooter: {
    gap: theme.spacing(1.5),
  },
  modalLeftSideFooter: {
    width: "100%",
  },
}))

export default observer(GenerateWithAIButton)
