import { useHistoryStack } from "@/core/history/historyStack"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DiscoTextButton } from "@disco-ui"
import classnames from "classnames"
import { LocationDescriptorObject } from "history"
import { observer } from "mobx-react-lite"
import React from "react"
import { useHistory } from "react-router-dom"
import ChevronIcon from "../../core/ui/iconsax/linear/arrow-up-1.svg"

interface BackButtonProps {
  label?: string | null
  testid?: string
  defaultPath: string | LocationDescriptorObject
  className?: string
  underline?: boolean
  icon?: React.ReactNode
  hideLabel?: boolean
}

const BackButton = observer(
  ({
    label,
    testid,
    defaultPath,
    color,
    marginBottom,
    className,
    underline,
    icon,
    hideLabel = false,
  }: BackButtonProps & StyleProps) => {
    const history = useHistory()
    const historyStack = useHistoryStack()
    const classes = useStyles({ color, marginBottom })

    return (
      <DiscoTextButton
        data-testid={testid}
        className={classnames(classes.backButton, className, {
          [classes.underline]: underline,
        })}
        onClick={handleBackButtonClick}
        color={color}
      >
        {icon || <ChevronIcon width={24} height={24} className={classes.chevronIcon} />}
        {!hideLabel && (label || "Back")}
      </DiscoTextButton>
    )

    function handleBackButtonClick() {
      if (historyStack.length >= 2) {
        history.goBack()
      } else {
        history.push(defaultPath)
      }
    }
  }
)

type StyleProps = {
  color?: React.CSSProperties["color"]
  marginBottom?: React.CSSProperties["marginBottom"]
}

const useStyles = makeUseStyles((theme) => ({
  backButton: ({ color, marginBottom }: StyleProps) => ({
    marginBottom: marginBottom ?? theme.spacing(4),
    padding: "0",
    color: color || theme.palette.groovy.neutral[300],
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    height: "16px",
    "& .MuiButton-label": {
      color: color || theme.palette.groovy.neutral[300],
    },
  }),
  chevronIcon: ({ color }: StyleProps) => ({
    transform: "rotate(270deg)",
    marginBottom: theme.spacing(0.33),

    "& path": {
      color: color || theme.palette.groovy.neutral[300],
    },
  }),
  underline: ({ color }: StyleProps) => ({
    borderBottom: `1px solid ${color || theme.palette.groovy.neutral[300]}`,
  }),
}))

export default BackButton
