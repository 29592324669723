import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import { useFormStore } from "@/core/form/store/FormStore"
import DashboardBlockKindsGrid from "@/dashboard/add/DashboardBlockKindsGrid"
import {
  AddDashboardBlockInput,
  AddDashboardBlockModalMutation,
  DashboardBlockKind,
  DashboardBlockPosition,
} from "@/dashboard/add/__generated__/AddDashboardBlockModalMutation.graphql"
import { useDashboardBlockKindForms } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import DashboardBlockShowOnMobileField from "@/dashboard/form/fields/DashboardBlockShowOnMobileField"
import useCommunityLandingPages from "@/landing-page/util/useCommunityLandingPages"
import ProductUtils from "@/product/util/productUtils"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoAlert, DiscoButton, DiscoDivider, DiscoModal } from "@disco-ui"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import React from "react"
import { graphql } from "relay-runtime"
import { useDashboardContext } from "../util/DashboardContext"

export interface AddDashboardBlockModalProps {
  dashboardId: GlobalID
  position: DashboardBlockPosition
  ordering: number
  onClose: () => void
}

export type AddDashboardBlockFormState = Omit<AddDashboardBlockInput, "kind"> & {
  kind: DashboardBlockKind | null
}

function AddDashboardBlockModal(props: AddDashboardBlockModalProps) {
  const { onClose, dashboardId, position, ordering } = props

  const activeProduct = useActiveProduct()
  const { completeOrIgnoreChecklistItem } = useOnboardingChecklistContext()
  const { landingPages } = useCommunityLandingPages()
  const dashboard = useDashboardContext()!

  const form = useFormStore<AddDashboardBlockModalMutation, AddDashboardBlockFormState>(
    graphql`
      mutation AddDashboardBlockModalMutation($input: AddDashboardBlockInput!) {
        response: addDashboardBlock(input: $input) {
          node {
            id
            dashboard {
              mainBlocks: blocks(position: main) {
                edges {
                  node {
                    ...DashboardBlockItemFragment
                  }
                }
              }
              sideBlocks: blocks(position: side) {
                edges {
                  node {
                    ...DashboardBlockItemFragment
                  }
                }
              }
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      dashboardId,
      position,
      ordering,
      kind: null,
      showOnMobile: true,
    }
  )

  const classes = useStyles()
  const productLabel = useLabel("admin_experience")
  const memberLabel = useLabel("organization_member")

  const publicLabel = ProductUtils.getAvailabilityLabel("public", memberLabel)
  const privateLabel = ProductUtils.getAvailabilityLabel("private", memberLabel)

  const kinds = useDashboardBlockKindForms(position)
  const kindConfig = form.state.kind
    ? form.state.kind === "featured_items" &&
      form.state.featuredItems?.featuredKind === "membership"
      ? kinds.memberships
      : kinds[form.state.kind as keyof typeof kinds]
    : null

  const isFeaturedProductsBlock =
    form.state.kind === "featured_items" &&
    form.state.featuredItems?.featuredKind === "experience"
  const isFeaturedMembershipsBlock =
    form.state.kind === "featured_items" &&
    form.state.featuredItems?.featuredKind === "membership"

  if (kindConfig && (isFeaturedMembershipsBlock || isFeaturedProductsBlock)) {
    kindConfig.alert = isFeaturedProductsBlock
      ? `Only Published & ${publicLabel} ${
          dashboard.isCommunityWelcome ? "" : `or ${privateLabel} `
        }${productLabel.plural} can be featured.`
      : isFeaturedMembershipsBlock
      ? "Only published memberships can be featured."
      : "Select items to feature."
  }

  // Select block type step
  if (!kindConfig) {
    return (
      <DiscoModal
        isOpen
        onClose={onClose}
        title={"New Block"}
        subtitle={`Add blocks to build your custom learning ${
          activeProduct ? productLabel.singular : "Community"
        }.`}
        modalContentLabel={"New Block"}
        testid={"AddDashboardBlockModal.kind-picker"}
        className={classNames(classes.blockTypeModal, classes.modalWidth)}
        body={
          <DashboardBlockKindsGrid
            form={form}
            position={position}
            dashboardId={dashboardId}
          />
        }
      />
    )
  }

  // Block-specific form
  return (
    <DiscoModal
      isOpen
      onClose={onClose}
      onBack={() => (form.state.kind = null)}
      title={kindConfig?.name || "New Block"}
      modalContentLabel={kindConfig?.name || "New Block"}
      testid={"AddDashboardBlockModal.kind-form-modal"}
      className={classes.modalWidth}
      body={
        <Form
          id={"AddDashboardBlockForm"}
          testid={"AddDashboardBlockModal.kind-form"}
          onSubmit={handleSubmit}
          buttons={
            <>
              <DiscoButton onClick={onClose} color={"grey"} variant={"outlined"}>
                {"Cancel"}
              </DiscoButton>
              <Form.SubmitButton
                id={"AddDashboardBlockForm"}
                form={form}
                testid={"AddDashboardBlockModal.save"}
                disabled={shouldDisableForm()}
              >
                {"Save"}
              </Form.SubmitButton>
            </>
          }
        >
          {kindConfig.alert?.length && (
            <>
              <DiscoAlert message={kindConfig.alert} />
              <DiscoDivider marginTop={2.5} marginBottom={2} />
            </>
          )}
          {kindConfig && React.createElement(kindConfig.form, { form, onClose })}
          <DashboardBlockShowOnMobileField form={form} />
        </Form>
      }
      buttons
    />
  )

  async function handleSubmit() {
    if (!form.state.kind) return
    const { didSave } = await form.submit({
      ...form.state,
      kind: form.state.kind,
    })
    if (didSave) {
      // Complete checklist item if we're editing one of the community landing pages
      if (landingPages.find((lp) => lp?.dashboard?.id === dashboardId)) {
        await completeOrIgnoreChecklistItem("set_up_community_public_page")
      }

      displaySuccessToast({
        message: `${kindConfig ? kindConfig.name : "Block"} added`,
        testid: "AddDashboardBlockModal.success-toast",
      })
      onClose()
    }
  }

  function shouldDisableForm() {
    if (!form.isChanged || form.isSubmitting) return true

    if (form.state.kind === "content") {
      if (!form.state.content?.contentIds?.length) return true
    }

    return false
  }
}

const useStyles = makeUseStyles((theme) => ({
  modalWidth: {
    width: theme.measure.modalMaxWidth.large,
    [theme.breakpoints.only("sm")]: {
      width: theme.measure.modalMaxWidth.medium,
    },
    [theme.breakpoints.only("xs")]: {
      width: theme.measure.modalMaxWidth.mobile,
    },
  },
  blockTypeModal: {
    minHeight: "unset",
  },
}))

export default observer(AddDashboardBlockModal)
