import { useAuthUser } from "@/core/context/AuthUserContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoTooltip } from "@disco-ui"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { useHistory } from "react-router-dom"

type CreateOrganizationButtonProps = {
  children: OverridableDiscoButtonChildren
  testid?: string
}

export default function CreateOrganizationButton(props: CreateOrganizationButtonProps) {
  const { children, testid = "CreateOrganizationButton" } = props
  const history = useHistory()
  const { authUser } = useAuthUser({ required: true })

  return (
    <DiscoTooltip
      content={
        "You can only create one trial community, please contact support@disco.co."
      }
      disabled={authUser.canCreateTrialOrganization}
    >
      <div>
        <OverridableDiscoButton
          testid={testid}
          onClick={onClick}
          disabled={!authUser.canCreateTrialOrganization}
        >
          {children}
        </OverridableDiscoButton>
      </div>
    </DiscoTooltip>
  )

  function onClick() {
    history.push(ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED)
  }
}
