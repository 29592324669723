/**
 * @generated SignedSource<<145d64301fdafd8fe863a8df9f95f195>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContentNotificationEmailCtaButton = "enabled" | "disabled" | "%future added value";
export type ContentSystemTaskKind = "slack_connection" | "calendar_connection" | "custom_profile_field" | "community_agreement" | "default_profile_fields" | "onboarding_complete" | "onboarding_welcome" | "download_mobile_app" | "onboarding_module" | "complete_profile" | "slack_invite" | "add_events_to_calendar" | "connect_slack" | "%future added value";
export type ContentType = "assignment" | "assignment_submission" | "post" | "occurrence" | "comment" | "module" | "text" | "video" | "attachment" | "embed" | "quiz" | "custom" | "survey" | "confirmation" | "%future added value";
export type ContentUsageEntity = "content" | "collection" | "curriculum" | "member_onboarding" | "product_app" | "dashboard_block_content" | "organization" | "content_usage_confirmation" | "%future added value";
export type ContentUsageStatus = "published" | "draft" | "%future added value";
export type ContentUsageSubmissionMethod = "submission" | "mark_as_complete" | "%future added value";
export type ContentUsageSubmissionVisibility = "cohort" | "private" | "member_group" | "%future added value";
export type MessageTemplateKind = "course_welcome" | "community_welcome" | "product_completion" | "custom" | "%future added value";
export type WebFormQuestionType = "single_select" | "multiple_select" | "written_answer" | "link" | "rating" | "ranking" | "%future added value";
export type UpdateMemberOnboardingInput = {
  contentUsageId?: string | null;
  contentUsageInput?: ContentUsageInput | null;
  content?: EditContentInput | null;
  contentType?: ContentType | null;
  contentSystemTaskKind?: ContentSystemTaskKind | null;
  contentId?: string | null;
  organizationId: string;
  productId?: string | null;
  detachFromContent?: boolean | null;
  botResponseIds?: ReadonlyArray<string> | null;
  childUsageInput?: ReadonlyArray<ChildContentUsageInput> | null;
};
export type ContentUsageInput = {
  entity?: ContentUsageEntity | null;
  entityId?: string | null;
  ordering?: number | null;
  releasedAt?: string | null;
  dueAt?: string | null;
  dueDateUpdatedEmailMessageInput?: MessageTemplateInput | null;
  submissionVisibility?: ContentUsageSubmissionVisibility | null;
  submissionMethod?: ContentUsageSubmissionMethod | null;
  submissionFormat?: AssignmentSubmissionFormatInput | null;
  occurrences?: ReadonlyArray<string> | null;
  status?: ContentUsageStatus | null;
  showComments?: boolean | null;
  passPercentage?: number | null;
  prerequisiteIds?: ReadonlyArray<string> | null;
  confirmationModalInput?: EditContentInput | null;
};
export type MessageTemplateInput = {
  id?: string | null;
  name: string;
  kind?: MessageTemplateKind | null;
  emailContent?: EmailContentInput | null;
  slackContent?: SlackContentInput | null;
  chatContent?: ChatContentInput | null;
};
export type EmailContentInput = {
  send: boolean;
  subject: string;
  richBody: string;
  ccEmails?: ReadonlyArray<string> | null;
  bccEmails?: ReadonlyArray<string> | null;
  ccUserIds?: ReadonlyArray<string> | null;
  bccUserIds?: ReadonlyArray<string> | null;
};
export type SlackContentInput = {
  send: boolean;
  sendAs?: string | null;
  richBody: string;
};
export type ChatContentInput = {
  send: boolean;
  sendAs?: string | null;
  richBody: string;
};
export type AssignmentSubmissionFormatInput = {
  link: AssignmentSubmissionFormatSettingsInput;
  text: AssignmentSubmissionFormatSettingsInput;
  file: AssignmentSubmissionFormatSettingsInput;
};
export type AssignmentSubmissionFormatSettingsInput = {
  enabled: boolean;
  required: boolean;
};
export type EditContentInput = {
  attachments?: ReadonlyArray<ContentAttachmentInput> | null;
  name?: string | null;
  description?: string | null;
  coverPhoto?: string | null;
  coverPhotoAssetId?: string | null;
  coverVideo?: string | null;
  coverVideoAssetId?: string | null;
  notificationEmailSubject?: string | null;
  richEditorNotificationEmailBodyContent?: string | null;
  sendNotificationEmail?: boolean | null;
  coverVideoDurationSeconds?: number | null;
  showComments?: boolean | null;
  showCoverPlayIcon?: boolean | null;
  coverAudio?: string | null;
  coverAudioAssetId?: string | null;
  thumbnailUrl?: string | null;
  thumbnailAssetId?: string | null;
  richEditorDescriptionContent?: string | null;
  releaseDatetime?: string | null;
  releaseNow?: boolean | null;
  notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null;
  ctaText?: string | null;
  contentLabelId?: string | null;
  webFormRevision?: CreateWebFormRevisionInput | null;
  submissionLink?: string | null;
  isAiGenerated?: boolean | null;
};
export type ContentAttachmentInput = {
  id?: string | null;
  name?: string | null;
  mediaUrl?: string | null;
  mediaAssetId?: string | null;
};
export type CreateWebFormRevisionInput = {
  webFormId: string;
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
};
export type WebFormQuestionInput = {
  type: WebFormQuestionType;
  richEditorBody: string;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type ChildContentUsageInput = {
  entity?: ContentUsageEntity | null;
  entityId?: string | null;
  ordering?: number | null;
  releasedAt?: string | null;
  dueAt?: string | null;
  dueDateUpdatedEmailMessageInput?: MessageTemplateInput | null;
  submissionVisibility?: ContentUsageSubmissionVisibility | null;
  submissionMethod?: ContentUsageSubmissionMethod | null;
  submissionFormat?: AssignmentSubmissionFormatInput | null;
  occurrences?: ReadonlyArray<string> | null;
  status?: ContentUsageStatus | null;
  showComments?: boolean | null;
  passPercentage?: number | null;
  prerequisiteIds?: ReadonlyArray<string> | null;
  confirmationModalInput?: EditContentInput | null;
  id?: string | null;
};
export type AdminMemberOnboardingListItemMutation$variables = {
  input: UpdateMemberOnboardingInput;
};
export type AdminMemberOnboardingListItemMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly status: ContentUsageStatus;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AdminMemberOnboardingListItemMutation = {
  variables: AdminMemberOnboardingListItemMutation$variables;
  response: AdminMemberOnboardingListItemMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMemberOnboardingResponse",
    "kind": "LinkedField",
    "name": "updateMemberOnboarding",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentUsage",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminMemberOnboardingListItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminMemberOnboardingListItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c4c8be820f6ba273cc84920fbde664e5",
    "id": null,
    "metadata": {},
    "name": "AdminMemberOnboardingListItemMutation",
    "operationKind": "mutation",
    "text": "mutation AdminMemberOnboardingListItemMutation(\n  $input: UpdateMemberOnboardingInput!\n) {\n  response: updateMemberOnboarding(input: $input) {\n    node {\n      id\n      status\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7005848d995a11e89ae269caa2007707";

export default node;
