import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import { GlobalID } from "@/relay/RelayTypes"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { isE2ETest } from "@utils/e2e"
import { useCallback, useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 * Whether or not the user is impersonating a user should be determined by checking this
 * variable on the global window instead of checking the query params
 */
declare global {
  interface Window {
    discoImpersonateUserId?: GlobalID
  }
}

export function useStartImpersonatingUser() {
  return useCallback((userIdToImpersonate: GlobalID) => {
    const url = new URL(window.location.href)
    url.pathname = ROUTE_NAMES.COMMUNITY.HOME.ROOT
    url.searchParams.set("iUserId", userIdToImpersonate)

    if (isE2ETest()) {
      window.location.href = url.toString()
    } else {
      window.open(url.toString(), "_blank")
    }
  }, [])
}

export function useStopImpersonatingUser(): () => void {
  return useCallback(() => {
    // When we are in a window opened by entering view as mode, close the
    // window instead of refreshing the immediate page. In e2e tests or if we
    // don't have a window to take you back to, refresh the current window
    // without viewAs params set.
    const wasOpenedViewAsWindow = window.opener?.origin === window.origin
    if (wasOpenedViewAsWindow && !isE2ETest()) {
      window.close()
      return
    }

    // Reload the current page without viewAs params set.
    const url = new URL(window.location.href)
    url.searchParams.delete("iUserId")
    window.location.href = url.toString()
  }, [])
}

const AUTH_ROUTES = new Set<string>(Object.values(ROUTE_NAMES.AUTHENTICATION))

/**
 * Initialize hook at root app level to append `iUserId` to query params if it was removed
 * while still impersonating a user
 */
function useInitImpersonateUser() {
  const { pathname } = useLocation<LocationState>()
  const stopImpersonatingUser = useStopImpersonatingUser()
  const [params, setParams] = useQueryParamState<{ iUserId?: GlobalID }>()

  // Set the global var immediately for use in GraphQL queries if necessary
  const isExcludedPath = AUTH_ROUTES.has(pathname)
  if (
    !isExcludedPath &&
    params.iUserId &&
    params.iUserId !== window.discoImpersonateUserId
  ) {
    window.discoImpersonateUserId = params.iUserId
  }

  useEffect(() => {
    // If navigating to excluded path, do not append iUserId param, and close/reload the
    // window if we are currently impersonating a user
    if (isExcludedPath) {
      if (window.discoImpersonateUserId) stopImpersonatingUser()
      return
    }

    // If iUserId was on, persist param on navigation
    if (!params.iUserId && window.discoImpersonateUserId) {
      setParams({ iUserId: window.discoImpersonateUserId }, "replace")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExcludedPath, stopImpersonatingUser, params.iUserId])
}
export default useInitImpersonateUser
