import MembershipPlanIncludedProducts from "@/membership-plan/register/MembershipPlanIncludedProducts"
import { RegistrationMembershipPlanDetailsFragment$key } from "@/membership-plan/register/__generated__/RegistrationMembershipPlanDetailsFragment.graphql"
import RegistrationExperienceDetailsForm from "@/product/register/RegistrationExperienceDetailsForm"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import DiscoEditor from "@components/editor/DiscoEditor"
import ScrollShadowContainer from "@components/scroll-shadow/ScrollShadowContainer"
import usePermissions from "@utils/hook/usePermissions"
import { useQueryParams } from "@utils/url/urlUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  membershipPlanKey: RegistrationMembershipPlanDetailsFragment$key
  onPage?: boolean
}

function RegistrationMembershipPlanDetails({ membershipPlanKey, onPage }: Props) {
  const classes = useStyles({ onPage })
  const { registrationStep } = useQueryParams()

  const product = useFragment<RegistrationMembershipPlanDetailsFragment$key>(
    graphql`
      fragment RegistrationMembershipPlanDetailsFragment on Product
      @argumentDefinitions(
        hideDrafts: { type: "Boolean!" }
        hideNonPublic: { type: "Boolean!" }
      ) {
        id
        cover
        richEditorCheckoutDescription
        myCheckout {
          id
        }
        ...RegistrationExperienceDetailsFormFragment
        ...MembershipPlanIncludedProductsFragment
          @arguments(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic)
        ...usePermissionsFragment
      }
    `,
    membershipPlanKey
  )
  const permissions = usePermissions(product)

  if (!product) return null

  // Hide section if successful registration
  if (isRegistrationComplete()) return null

  if (permissions.has("experience_settings.manage"))
    return (
      <div>
        <RegistrationExperienceDetailsForm
          productKey={product}
          classes={classes}
          onPage={onPage}
        />

        {/* List of products included in membership plan */}
        <MembershipPlanIncludedProducts membershipPlanKey={product} />
      </div>
    )

  return (
    <ScrollShadowContainer>
      <div
        className={classes.contentContainer}
        data-testid={"RegistrationMembershipPlanDetails"}
      >
        {product.cover && (
          <CoverPhoto
            testid={"RegistrationMembershipPlanDetails.cover-photo"}
            coverPhoto={product.cover}
            customClassName={classes.coverPhoto}
          />
        )}
        <DiscoEditor
          defaultValue={product.richEditorCheckoutDescription}
          readOnly
          disableGutter
          testid={"RegistrationMembershipPlanDetails.static.description"}
        />

        {/* List of products included in membership plan */}
        <MembershipPlanIncludedProducts membershipPlanKey={product} />
      </div>
    </ScrollShadowContainer>
  )

  function isRegistrationComplete() {
    const successStep = registrationStep === "success"
    if (!successStep) return false
    if (product.myCheckout?.id) return true
    return false
  }
}

type StyleProps = {
  onPage?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  contentContainer: (props: StyleProps) => ({
    display: "grid",
    gap: theme.spacing(3),
    alignContent: "start",
    width: "100%",
    maxWidth: "1024px",
    margin: "0 auto",
    padding: theme.spacing(2.5),

    ...styleIf(props.onPage, {
      padding: theme.spacing(0, 1, 1, 1),
    }),
  }),
  coverPhoto: {
    borderRadius: theme.measure.borderRadius.big,
    backgroundSize: "cover",
    maxHeight: "336px",
  },
}))

export default observer(RegistrationMembershipPlanDetails)
