import {
  BrainSearchLandingPageMutation,
  CreateBrainSearchInput,
} from "@/brain-search/internal/__generated__/BrainSearchLandingPageMutation.graphql"
import { ROLES } from "@/brain-search/internal/brainSearchUtils"
import FormStore from "@/core/form/store/FormStore"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { lighten } from "@material-ui/core"
import { ArrayUtils } from "@utils/array/arrayUtils"
import { observer } from "mobx-react-lite"
import { useMemo } from "react"

type Props = {
  form: FormStore<CreateBrainSearchInput, BrainSearchLandingPageMutation>
  onSelect: () => Promise<void>
}

function BrainSearchSuggestionList({ form, onSelect }: Props) {
  const classes = useStyles()
  const suggestions = useMemo(() => {
    const allPrompts = Object.values(ROLES)
      .map((r) => r.prompts)
      .flat()
    return ArrayUtils.randomItems(allPrompts, 3)
  }, [])

  return (
    <div className={classes.suggestionsList}>
      {suggestions.map((suggestion) => (
        <DiscoContainerButton
          key={suggestion}
          className={classes.suggestion}
          onClick={() => handleClickSuggestion(suggestion)}
        >
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {suggestion}
          </DiscoText>
        </DiscoContainerButton>
      ))}
    </div>
  )

  async function handleClickSuggestion(prompt: string) {
    form.state.title = prompt
    await onSelect()
  }
}

const useStyles = makeUseStyles((theme) => ({
  suggestionsList: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  suggestion: {
    borderRadius: theme.measure.borderRadius.large,
    backgroundColor: theme.palette.groovy.neutral[100],
    padding: theme.spacing(2),
    border: theme.palette.constants.borderSmall,
    flex: "1 1 0px",

    "&:hover": {
      border: theme.palette.constants.borderSmall,
      borderColor: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      boxShadow:
        "0px 2px 4px 0px rgba(63, 63, 70, 0.04), 0px 6px 18px 0px rgba(63, 63, 70, 0.08)",

      "& > p": {
        color: theme.palette.primary.main,
      },
    },
  },
}))

export default observer(BrainSearchSuggestionList)
