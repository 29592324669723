import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import { useStartImpersonatingUser } from "@/product/util/hook/useInitImpersonateUser"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CreateTestUserButton from "@components/test-user/CreateTestUserButton"
import { TestUserDropdownItem_TestMembersQuery } from "@components/test-user/__generated__/TestUserDropdownItem_TestMembersQuery.graphql"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoDropdown,
  DiscoDropdownProps,
  DiscoIcon,
  DiscoSpinner,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import { useTheme } from "@material-ui/core"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

interface Props {
  children: DiscoDropdownProps["menuButton"]
}

function TestUserDropdownItem({ children }: Props) {
  const activeOrganization = useActiveOrganization()!
  const labels = useLabels()
  const theme = useTheme()
  const classes = useStyles()
  const startImpersonatingUser = useStartImpersonatingUser()

  const [{ node }, refetchMainUserLinks] =
    Relay.useRefetchableQuery<TestUserDropdownItem_TestMembersQuery>(
      graphql`
        query TestUserDropdownItem_TestMembersQuery($organizationMembershipId: ID!) {
          node(id: $organizationMembershipId) {
            ... on OrganizationMembership {
              id
              __typename
              mainUserLinks(first: 10)
                @connection(key: "TestUserDropdownItem__mainUserLinks") {
                __id
                edges {
                  node {
                    id
                    testUser {
                      id
                      fullName
                      ...ProfileAvatarWithDetailsFragment
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        organizationMembershipId: activeOrganization.viewerMembership!.id,
      },
      {
        fetchPolicy: "network-only",
      }
    )
  const organizationMembership = Relay.narrowNodeType(node, "OrganizationMembership")

  useSubscribeToInvalidationState(
    organizationMembership?.mainUserLinks?.__id
      ? [organizationMembership?.mainUserLinks.__id]
      : [],
    () =>
      refetchMainUserLinks({
        organizationMembershipId: activeOrganization.viewerMembership!.id,
      })
  )

  if (!organizationMembership) return null

  const userLinks = Relay.connectionToArray(organizationMembership.mainUserLinks)
  const hasUserLinks = userLinks.length > 0

  return (
    <DiscoDropdown
      menuButton={children}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      isNested
    >
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <DiscoText variant={"body-md-600"}>{"Test Members"}</DiscoText>
          {hasUserLinks && (
            <DiscoTooltip
              content={getHelpText()}
              placement={"right"}
              data-testid={"TestUserDropdownItem.title.tooltip"}
            >
              <span style={{ display: "inherit" }}>
                <DiscoIcon
                  icon={"iconsax.custom-info"}
                  width={18}
                  height={18}
                  color={theme.palette.text.primary}
                />
              </span>
            </DiscoTooltip>
          )}
        </div>
        {hasUserLinks ? (
          userLinks.map((userLink) => {
            return (
              <DiscoDropdownItem
                key={userLink.id}
                className={classes.userLinkDropdownItem}
                title={
                  <ProfileAvatarWithDetails
                    testid={`TestUserDropdownItem.profile-avatar.${userLink.testUser.fullName}`}
                    userKey={userLink.testUser}
                    linkToProfile={false}
                    avatarSize={32}
                  />
                }
                onClick={() => startImpersonatingUser(userLink.testUser.id)}
              />
            )
          })
        ) : (
          <DiscoText
            color={"text.secondary"}
            testid={"TestUserDropdownItem.empty-state-message"}
          >
            {getHelpText()}
          </DiscoText>
        )}
        {userLinks.length < 5 && (
          <CreateTestUserButton>
            {(buttonProps) => {
              return (
                <DiscoButton
                  {...buttonProps}
                  variant={"outlined"}
                  color={"grey"}
                  leftIcon={"iconsax.add"}
                >
                  {"Create Test Member"}
                </DiscoButton>
              )
            }}
          </CreateTestUserButton>
        )}
      </div>
    </DiscoDropdown>
  )

  function getHelpText() {
    return `You can have up to 5 test members that can be used to test the ${labels.admin_member.singular} experience in the community.`
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  userLinkDropdownItem: {
    padding: theme.spacing(1.5),
  },
}))

function TestUserDropdownItemSkeleton({ children }: Props) {
  const classes = useStyles()

  return (
    <DiscoDropdown
      menuButton={children}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      isNested
    >
      <div className={classes.container}>
        <DiscoText variant={"body-md-600"}>{"Test Members"}</DiscoText>
        <DiscoSpinner />
        <DiscoButtonSkeleton />
      </div>
    </DiscoDropdown>
  )
}

export default Relay.withSkeleton<Props>({
  component: TestUserDropdownItem,
  skeleton: TestUserDropdownItemSkeleton,
})
