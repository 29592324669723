import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import ProductMultiSelect from "@/experience/components/ProductMultiSelect"
import MemberGroupsMultiSelect from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import ProductSelect from "@/product/common/ProductSelect"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import TestUserFormFieldsProductCard from "@components/test-user/TestUserFormFieldsProductCard"
import { CreateTestUserInput } from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { TestUserFormFieldsFragment$key } from "@components/test-user/__generated__/TestUserFormFieldsFragment.graphql"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoInputSkeleton,
  DiscoSelectSkeleton,
} from "@disco-ui"
import { observable, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql, useFragment } from "react-relay"

interface Props {
  organizationKey: TestUserFormFieldsFragment$key
  form: FormStore<CreateTestUserInput>
}

function TestUserFormFields({ organizationKey, form }: Props) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const labels = useLabels()

  const organization = useFragment<TestUserFormFieldsFragment$key>(
    graphql`
      fragment TestUserFormFieldsFragment on Organization {
        membershipPlans: products(type: "membership_plan", hideNonPublic: false) {
          edges {
            node {
              id
            }
          }
        }
        ...MemberGroupsMultiSelect_OrganizationFragment
      }
    `,
    organizationKey
  )

  const membershipPlans = Relay.connectionToArray(organization.membershipPlans)
  const onlyHasOneMembershipPlan = membershipPlans.length === 1

  useEffect(() => {
    if (onlyHasOneMembershipPlan) {
      // Automatically set the selected membership plan to the default plan if it's the only one available
      form.state.membershipPlanId = membershipPlans[0].id

      // This is needed so the modal doesn't think there are any changes
      // after we auto set values for them and they try to leave
      form.resetInitialState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.nameFieldsContainer}>
        <DiscoFormControl
          label={"First Name"}
          error={Boolean(form.errorsByField.firstName)}
          errorMessages={form.errorsByField.firstName}
        >
          <DiscoInput
            data-testid={"TestUserFormFields.first-name"}
            value={form.state.firstName}
            onChange={(e) => (form.state.firstName = e.target.value)}
          />
        </DiscoFormControl>
        <DiscoFormControl
          label={"Last Name"}
          error={Boolean(form.errorsByField.lastName)}
          errorMessages={form.errorsByField.lastName}
        >
          <DiscoInput
            data-testid={"TestUserFormFields.last-name"}
            value={form.state.lastName}
            onChange={(e) => (form.state.lastName = e.target.value)}
          />
        </DiscoFormControl>
      </div>

      <DiscoDivider />

      {!onlyHasOneMembershipPlan && (
        <DiscoFormControl
          label={"Select Membership"}
          error={Boolean(form.errorsByField.membershipPlanId)}
          errorMessages={form.errorsByField.membershipPlanId}
        >
          <ProductSelect
            testid={"TestUserFormFields.membership-plan"}
            organizationId={activeOrganization.id}
            value={form.state.membershipPlanId}
            onChange={(productId) => {
              form.state.products = observable.array() // Reset products when selecting another membership plan
              form.state.membershipPlanId = productId || ""
            }}
            type={"membership_plan"}
            hideNonPublic={false}
            placeholder={`Please select a membership plan`}
          />
        </DiscoFormControl>
      )}

      <DiscoFormControl label={"Add to Community Groups (Optional)"}>
        <MemberGroupsMultiSelect
          testid={"TestUserFormFields.organization.member-groups"}
          selectedGroupIds={
            form.state.organizationMemberGroupIds
              ? form.state.organizationMemberGroupIds.slice()
              : []
          }
          setSelectedGroupIds={(ids: GlobalID[]) =>
            form.state.organizationMemberGroupIds
              ? form.state.organizationMemberGroupIds.replace(ids)
              : (form.state.organizationMemberGroupIds = observable.array(ids))
          }
          organizationKey={organization}
          showGroupKinds={["custom"]}
        />
      </DiscoFormControl>

      <DiscoFormControl label={`Add to ${labels.admin_experience.plural} (Optional)`}>
        <div className={classes.productsContainer}>
          <ProductMultiSelect
            values={toJS(form.state.products?.map((p) => p.productId) || [])}
            onChange={handleProductSelection}
            testid={"TestUserFormFields.products"}
            type={"course"}
            hideNonPublic={false}
            disabled={!form.state.membershipPlanId}
            forMembershipPlanId={form.state.membershipPlanId}
          />

          {form.state.products?.map((p) => {
            return (
              <TestUserFormFieldsProductCard
                key={p.productId}
                productId={p.productId}
                form={form}
              />
            )
          })}
        </div>
      </DiscoFormControl>
    </div>
  )

  function handleProductSelection(ids: GlobalID[]) {
    if (!form.state.products) {
      form.state.products = observable.array()
    }

    // Remove product
    const idsToRemove = form.state.products
      .filter((p) => !ids.includes(p.productId))
      .map((p) => p.productId)
    for (const idToRemove of idsToRemove) {
      const productToRemove = form.state.products.find((p) => p.productId === idToRemove)
      if (!productToRemove) continue
      form.state.products.remove(productToRemove)
    }

    // Add product
    const idsToAdd = ids.filter(
      (id) => !form.state.products!.find((p) => p.productId === id)
    )
    for (const idToAdd of idsToAdd) {
      form.state.products.push({
        productId: idToAdd,
        role: "member",
        memberGroupIds: observable.array(),
      })
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    gap: theme.spacing(2.5),
  },
  nameFieldsContainer: {
    display: "flex",
    gap: theme.spacing(2.5),
  },
  productsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}))

export function TestUserFormFieldsSkeleton() {
  const labels = useLabels()
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.nameFieldsContainer}>
        <DiscoFormControl label={"First Name"}>
          <DiscoInputSkeleton />
        </DiscoFormControl>
        <DiscoFormControl label={"Last Name"}>
          <DiscoInputSkeleton />
        </DiscoFormControl>
      </div>

      <DiscoDivider />

      <DiscoFormControl label={"Add to Community Groups (Optional)"}>
        <DiscoSelectSkeleton />
      </DiscoFormControl>

      <DiscoFormControl label={`Add to ${labels.admin_experience.plural} (Optional)`}>
        <div className={classes.productsContainer}>
          <DiscoSelectSkeleton />
        </div>
      </DiscoFormControl>
    </div>
  )
}

export default observer(TestUserFormFields)
