import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoButton,
  DiscoIcon,
  DiscoIconButton,
  DiscoText,
  GroovyTextColorKind,
} from "@disco-ui"
import { useTheme } from "@material-ui/core"
import useOnKeydown, { KEY_CODES } from "@utils/hook/useOnKeydown"
import { useEffect } from "react"

type ToastButtonProps = {
  onClick: () => void
  buttonLabel: string
}

export interface ToastProps {
  id: string
  message: React.ReactNode
  body?: string
  type?: "info" | "error" | "warning" | "success"
  timeout?: number
  to?: string
  testid?: string
  onHide: () => void
  buttonConfig?: ToastButtonProps
}

const DEFAULT_TIMEOUT = 5000

function Toast(props: ToastProps) {
  const {
    message,
    timeout = DEFAULT_TIMEOUT,
    type = "success",
    onHide,
    to,
    body,
    id,
    testid,
    buttonConfig,
  } = props
  const { onClick, buttonLabel } = buttonConfig || {}
  const theme = useTheme()

  const { color, backgroundColor, hoverBackgroundColor, borderColor, groovyColor } =
    getToastTheme()

  const classes = useStyles({ color, backgroundColor, borderColor })

  // Hide the toast after its timeout.
  useEffect(() => {
    const t = setTimeout(() => onHide(), timeout)
    return () => clearTimeout(t)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useOnKeydown(
    (e) => {
      if (KEY_CODES.ESCAPE.has(e.key)) {
        e.preventDefault()
        onHide()
      }
    },
    [onHide]
  )

  return (
    <div id={id} data-testid={testid ?? `Toast.${type}`} className={classes.root}>
      <div className={classes.toast}>
        <DiscoIcon
          icon={type === "warning" ? "warning" : "info"}
          active={true}
          color={color}
          className={classes.leftIcon}
        />

        <DiscoText variant={"body-md-600"} className={classes.title}>
          {message}
        </DiscoText>
        {to && !body ? (
          <div className={classes.buttonsContainer}>
            {renderLinkButton()}
            {renderCloseButton()}
          </div>
        ) : (
          renderCloseButton()
        )}
      </div>

      {body && (
        <div>
          <DiscoText color={groovyColor} variant={"body-sm"}>
            {body}
          </DiscoText>
          {to && <div className={classes.linkButtonContainer}>{renderLinkButton()}</div>}
        </div>
      )}

      {onClick && buttonLabel && (
        <div className={classes.ctaContainer}>
          <DiscoButton
            testid={`${testid}.cta-button`}
            variant={"outlined"}
            color={"grey"}
            onClick={() => {
              onClick()
              onHide()
            }}
          >
            {buttonLabel}
          </DiscoButton>
        </div>
      )}
    </div>
  )

  function getToastTheme() {
    switch (type) {
      case "success":
        return {
          color: theme.palette.groovy.green[600],
          backgroundColor: theme.palette.groovy.green[100],
          hoverBackgroundColor: theme.palette.groovy.green[200],
          borderColor: theme.palette.groovy.green[300],
          groovyColor: "groovy.green.600" as GroovyTextColorKind,
        }
      case "error":
        return {
          color: theme.palette.groovy.red[600],
          backgroundColor: theme.palette.groovy.red[100],
          hoverBackgroundColor: theme.palette.groovy.red[200],
          borderColor: theme.palette.groovy.red[300],
          groovyColor: "groovy.red.600" as GroovyTextColorKind,
        }
      case "info":
        return {
          color: theme.palette.groovy.blue[700],
          backgroundColor: theme.palette.groovy.blue[100],
          hoverBackgroundColor: theme.palette.groovy.blue[200],
          borderColor: theme.palette.groovy.blue[300],
          groovyColor: "groovy.blue.700" as GroovyTextColorKind,
        }
      case "warning":
        return {
          color: theme.palette.groovy.yellow[700],
          backgroundColor: theme.palette.groovy.yellow[100],
          hoverBackgroundColor: theme.palette.groovy.yellow[200],
          borderColor: theme.palette.groovy.yellow[300],
          groovyColor: "groovy.yellow.700" as GroovyTextColorKind,
        }
    }
  }

  function renderCloseButton() {
    return (
      <DiscoIconButton
        testid={`${testid}.close-button`}
        width={"36px"}
        height={"36px"}
        svgStyles={{ width: "20px", height: "20px" }}
        style={{ borderRadius: theme.measure.borderRadius.big }}
        onClick={onHide}
        color={color}
        hoverColor={color}
        tabIndex={0}
        hoverBackgroundColor={hoverBackgroundColor}
      >
        <DiscoIcon icon={"close"} />
      </DiscoIconButton>
    )
  }

  function renderLinkButton() {
    return (
      <DiscoIconButton
        to={to}
        color={color}
        hoverColor={color}
        hoverBackgroundColor={hoverBackgroundColor}
        style={{ borderRadius: theme.measure.borderRadius.big }}
        width={"36px"}
        height={"36px"}
        svgStyles={{ width: "24px", height: "24px" }}
      >
        <DiscoIcon icon={"add"} />
      </DiscoIconButton>
    )
  }
}

export default Toast

type StyleProps = {
  color: string
  backgroundColor: string
  borderColor: string
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ color, backgroundColor, borderColor }: StyleProps) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    gap: theme.spacing(1),
    minWidth: "285px",
    maxWidth: "400px",
    minHeight: "55px",
    padding: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.large,

    backgroundColor,
    color,
    border: `0.5px solid ${borderColor}`,
    boxShadow: "0px 6px 18px rgba(63, 63, 70, 0.08), 0px 2px 4px rgba(63, 63, 70, 0.04)",
  }),
  title: ({ color }: StyleProps) => ({
    color,
    width: "100%",
  }),
  buttonsContainer: {
    gap: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  toast: {
    display: "flex",
    gap: theme.spacing(1.5),
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftIcon: {
    minWidth: "24px",
    minHeight: "24px",
  },
  linkButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ctaContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))
