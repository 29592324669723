import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { switchToOrganizationDomain } from "@/core/route/util/routeUtils"
import { RemoveCommunityMemberButtonFragment$key } from "@/product/member/admin/list/card/more-actions/remove-community-member/__generated__/RemoveCommunityMemberButtonFragment.graphql"
import {
  DeleteOrganizationMembershipInput,
  RemoveCommunityMemberButtonMutation,
} from "@/product/member/admin/list/card/more-actions/remove-community-member/__generated__/RemoveCommunityMemberButtonMutation.graphql"
import Relay from "@/relay/relayUtils"
import { displayToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoConfirmationModal from "@disco-ui/modal/DiscoConfirmationModal"
import useDisclosure from "@utils/hook/useDisclosure"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { useHistory } from "react-router-dom"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

interface RemoveCommunityMemberButtonProps extends TestIDProps {
  children: OverridableDiscoButtonChildren
  organizationMembershipKey: RemoveCommunityMemberButtonFragment$key
}

/** Can be used by the user to leave a community OR an admin to remove a member from the community */
function RemoveCommunityMemberButton({
  children,
  organizationMembershipKey,
  testid = "RemoveCommunityMemberButton",
}: RemoveCommunityMemberButtonProps) {
  const activeOrganization = useActiveOrganization()!
  const { onOpen, onClose, isOpen } = useDisclosure()
  const { authUser } = useAuthUser({ required: true })
  const memberLabel = useLabel("admin_member")
  const history = useHistory()

  const organizationMembership = useFragment<RemoveCommunityMemberButtonFragment$key>(
    graphql`
      fragment RemoveCommunityMemberButtonFragment on OrganizationMembership {
        id
        member {
          id
          # Fetching 2 organization memberships ensures that we will have at
          # least one organization membership to redirect to if needed to
          organizationMemberships(first: 2) {
            edges {
              node {
                id
                organization {
                  slug
                  primaryDomain
                }
              }
            }
          }
        }
        organization {
          id
          slug
        }
      }
    `,
    organizationMembershipKey
  )

  const form = useFormStore<
    RemoveCommunityMemberButtonMutation,
    DeleteOrganizationMembershipInput
  >(
    graphql`
      mutation RemoveCommunityMemberButtonMutation(
        $input: DeleteOrganizationMembershipInput!
      ) {
        response: deleteOrganizationMembership(input: $input) {
          node {
            id
            organization {
              membersCount
              adminsCount
              viewerMembership {
                id
                role
              }
            }
          }
          deletedTestMembershipIds
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationMembershipId: organizationMembership.id,
    }
  )

  const isViewerLeavingCommunity = authUser.id === organizationMembership.member.id

  return (
    <>
      <OverridableDiscoButton testid={testid} onClick={onOpen}>
        {children}
      </OverridableDiscoButton>

      <DiscoConfirmationModal
        testid={"RemoveCommunityMemberButtonModal"}
        isOpen={isOpen}
        onClose={onClose}
        modalContentLabel={
          isViewerLeavingCommunity
            ? `Delete Membership`
            : `Remove community ${memberLabel.singular}`
        }
        title={
          isViewerLeavingCommunity
            ? "Delete Membership"
            : `Remove Community ${memberLabel.singular}`
        }
        description={
          isViewerLeavingCommunity
            ? `Are you sure you want to leave the community? This action will restrict you from accessing the community as well as erase all your existing data related to this community.`
            : `Are you sure you want to remove this ${memberLabel.singular} from the community? This action is permanent and all this ${memberLabel.singular}'s data will be removed along with their access to this community.`
        }
        typeToConfirmText={"Delete Membership"}
        confirmButtonProps={{
          onClick: handleRemoveOrganizationMembership,
          shouldDisplaySpinner: form.isSubmitting,
          children: isViewerLeavingCommunity ? "Yes, delete" : "Yes, remove them",
        }}
      />
    </>
  )

  async function handleRemoveOrganizationMembership() {
    const { didSave } = await form.submit(form.state, {
      updater: (store, { response }) => {
        // Don't need to update connection if viewer is just leaving the community
        if (isViewerLeavingCommunity) return

        const organization = store.get(activeOrganization.id)
        if (!organization) return
        if (!response?.node) return

        // Remove the deleted org membership node and any test membership nodes from connections
        //   if they exist
        const membershipIdsToDelete = [response.node.id].concat(
          response?.deletedTestMembershipIds || []
        )

        ConnectionHandler.getConnections(
          organization,
          "CommunityMembersListPage__organizationMemberships"
        ).forEach((connection) => {
          membershipIdsToDelete.forEach((idToDelete) => {
            ConnectionHandler.deleteNode(connection, idToDelete)
          })
        })

        ConnectionHandler.getConnections(
          organization,
          "CommunityPeopleReportTable__organizationMemberships"
        ).forEach((connection) => {
          membershipIdsToDelete.forEach((idToDelete) => {
            ConnectionHandler.deleteNode(connection, idToDelete)
          })
        })
      },
    })
    if (!didSave) return

    if (isViewerLeavingCommunity) {
      handleOnCompleteLeaveCommunity()
    } else {
      handleOnCompleteMemberRemove()
    }
    onClose()
  }

  function handleOnCompleteLeaveCommunity() {
    if (activeOrganization.slug !== organizationMembership.organization.slug) return

    const userOrganizations = Relay.connectionToArray(
      organizationMembership.member.organizationMemberships
    ).map((om) => om.organization)
    const otherOrganization = userOrganizations.find(
      (o) => o.slug !== organizationMembership.organization.slug
    )

    if (otherOrganization) {
      // User is a part of another organization
      switchToOrganizationDomain(
        otherOrganization,
        activeOrganization,
        ROUTE_NAMES.COMMUNITY.HOME.ROOT
      )
    } else {
      // User is not a part of any other organization
      history.replace(ROUTE_NAMES.ONBOARDING.V2.GETTING_STARTED)
    }
  }

  function handleOnCompleteMemberRemove() {
    displayToast({
      message: `${memberLabel.singular} removed from the community`,
      testid,
    })
  }
}

export default observer(RemoveCommunityMemberButton)
