import { AIGenerationStatus } from "@/content/ai/button/__generated__/GenerateWithAIButtonMutation.graphql"
import { GenerationStatus } from "@/content/ai/GenerateContentWithAIProvider"
import AICloseIcon from "@assets/disco/icons/ai/ai-close.svg"
import AIStarsIcon from "@assets/disco/icons/ai/ai-stars.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AICheck from "@components/ai/AICheck"
import AIText from "@components/ai/AIText"
import { DiscoAlert, DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"

type PopupStatus = GenerationStatus | AIGenerationStatus

type Props = {
  statuses: { name?: string; status: PopupStatus; loadingText?: string }[]
  title?: string
  onClose: () => void
  hideCloseButton?: boolean
  footerMessage?: string
}

function GenerateContentWithAIStatusPopup(props: Props) {
  const classes = useStyles()
  const {
    statuses,
    title,
    onClose,
    hideCloseButton = false,
    footerMessage = "Stay for a moment, it might take a few minutes to generate the content.",
  } = props

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <AIStarsIcon width={24} height={24} />
        <AIText variant={"body-md-600"}>{title || "Generating Content"}</AIText>
        {!hideCloseButton && (
          <DiscoContainerButton onClick={onClose} className={classes.closeButton}>
            <AICloseIcon />
          </DiscoContainerButton>
        )}
      </div>
      {statuses.map(
        ({ name, status, loadingText }) =>
          status && (
            <div className={classes.item}>
              {statuses.length > 1 && getGeneratingCheckbox(status)}
              <DiscoText>
                {name && (
                  <DiscoText variant={"body-md-600"} component={"span"}>
                    {`${name}: `}
                  </DiscoText>
                )}
                {getGeneratingText(status, loadingText)}
              </DiscoText>
            </div>
          )
      )}
      <div className={classes.footer}>
        <DiscoAlert
          severity={"default"}
          message={footerMessage}
          classes={{ root: classes.alert }}
        />
      </div>
    </div>
  )

  function getGeneratingText(generationStatus: PopupStatus, loadingText?: string) {
    if (generationStatus === "done" || generationStatus === "completed") {
      return "Done"
    }
    if (generationStatus === "failed") {
      return "Something went wrong, please try again."
    }
    return loadingText || ""
  }
  function getGeneratingCheckbox(generationStatus: PopupStatus) {
    if (generationStatus === "done" || generationStatus === "completed") {
      return <AICheck isChecked />
    }
    if (generationStatus === "failed") {
      return <AICheck hasError />
    }
    return <AICheck />
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: theme.zIndex.raise2,

    width: "100%",
    maxWidth: "400px",
    background: `${
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurpleDark
        : theme.palette.aiGradient.aiLight
    } padding-box, ${theme.palette.aiGradient.bluePurple02} border-box`,
    border: "1px solid transparent",
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    marginLeft: "auto",
    height: 24,
    width: 24,
  },
  item: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  footer: {
    marginTop: theme.spacing(1),
  },
  alert: {
    background: "transparent",
    padding: 0,
    color: theme.palette.text.secondary,
  },
}))

export default GenerateContentWithAIStatusPopup
